<!-- [ngClass]="closeSideNav ? 'cb-sidebar d-none' : 'cb-sidebar'"
[ngClass]="sidnavClass" -->
<div
  class="cb-sidebar"
  [ngClass]="{ 'cb-sidebar': true, 'd-none': closeSideNav }"
  *ngIf="fullPath !== 'select-citations' && fullPath !== 'organization-assessment' && fullPath !== 'generate-documents'"
>
  <i class="fas fa-times close-sidenav text-white d-block d-lg-none" (click)="closeSidenav()"></i>
  <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <li mat-menu-item>
      <a routerLink="/organisation/dashboard" routerLinkActive="active" [class.disabled]="!orgSelect">
        <img src="../../../../assets/images/core/dashboard.svg" class="sidebar-icon" />
        Dashboard
      </a>
    </li>
    <li mat-menu-item *ngIf="showDocumentComponent">
      <a
        routerLink="/organisation/documents"
        routerLinkActive="active"
        [class.disabled]="!orgSelect"
        style="height: 45px;"
      >
        <img
          src="../../../../assets/images/documents/document-filled.png"
          class="sidebar-icon"
          style="margin-top: 10px;"
        />
        Document Management
      </a>
    </li>

    <li mat-menu-item>
      <a routerLink="/organisation/events" routerLinkActive="active" [class.disabled]="!orgSelect">
        <img src="../../../../assets/images/core/events.svg" class="sidebar-icon" />
        Events
      </a>
    </li>
    <li mat-menu-item *ngIf="false">
      <a routerLink="/home" routerLinkActive="active" [class.disabled]="!orgSelect">
        <img src="../../../../assets/images/core/on-boarding.png" class="sidebar-icon" />
        Onboard
      </a>
    </li>
    <li mat-menu-item>
      <a routerLink="/organisation/settings" routerLinkActive="active" [class.disabled]="!orgSelect">
        <img src="../../../../assets/images/core/settings.svg" class="sidebar-icon" />
        Settings
      </a>
    </li> -->
  <!-- <li *ngIf="showDocumentComponent">
      <a
        routerLink="/organisation/incident"
        routerLinkActive="active"
        [class.disabled]="!orgSelect"
        style="height: 45px;"
      >
        <img
          src="../../../../assets/images/documents/incident-management.png"
          class="sidebar-icon"
          style="margin-top: 10px;"
        />
        Incident Management
      </a>
    </li>  -->
  <!-- <li mat-menu-item *ngIf="userType === 'system_admin_role'">
      <a routerLink="/organisation/leads" routerLinkActive="active" [class.disabled]="!orgSelect">
        <img src="../../../../assets/images/core/lead.png" class="sidebar-icon" />
        Leads
      </a>
    </li>
  </mat-menu> -->
  <div class="dntShow">
    <ul class="mt-4 mt-lg-0">
      <li *ngIf="showDashboardComponent">
        <a
          routerLink="/organisation/dashboard"
          routerLinkActive="active"
          [class.disabled]="!orgSelect"
          style="height: 45px;"
        >
          <img src="../../../../assets/images/core/dashboard.svg" class="sidebar-icon" />
          Dashboard
        </a>
      </li>
      <li *ngIf="showDocumentComponent">
        <a
          routerLink="/organisation/documents"
          routerLinkActive="active"
          [class.disabled]="!orgSelect"
          style="height: 45px;"
        >
          <img src="../../../../assets/images/core/policy.svg" class="sidebar-icon" />
          Policy Management
        </a>
      </li>
      <li *ngIf="showDocumentComponent">
        <a
          routerLink="/organisation/training"
          routerLinkActive="active"
          [class.disabled]="!orgSelect"
          style="height: 41px;"
        >
          <img src="../../../../assets/images/core/training.svg" class="sidebar-icon" />
          Training Management
        </a>
      </li>

      <li *ngIf="showTasksComponent">
        <a
          routerLink="/organisation/events"
          routerLinkActive="active"
          [class.disabled]="!orgSelect"
          style="height: 45px;"
        >
          <img src="../../../../assets/images/core/tasks.svg" class="sidebar-icon" />
          Tasks
        </a>
      </li>
      <!-- <li *ngIf="!setUserDetails.user.is_organization_onboarded">
        <a routerLink="/home" routerLinkActive="active" [class.disabled]="!orgSelect">
          <img src="../../../../assets/images/core/on-boarding.png" class="sidebar-icon" />
          Onboard
        </a>
      </li> -->
      <li *ngIf="showIncidentComponent">
        <a
          routerLink="/organisation/incident"
          routerLinkActive="active"
          [class.disabled]="!orgSelect"
          style="height: 45px;"
        >
          <img src="../../../../assets/images/core/incident.svg" class="sidebar-icon" />
          Incident Management
        </a>
      </li>
      <li *ngIf="showFormComponent">
        <a
          routerLink="/organisation/form"
          routerLinkActive="active"
          [class.disabled]="!orgSelect"
          style="height: 45px;"
        >
          <img src="../../../../assets/images/core/form-one.png" class="sidebar-icon" />
          Vendor Management
        </a>
      </li>
      <!-- <li>
        <a href="https://docs.compliancebot.io/" target="_blank" [class.disabled]="!orgSelect" style="height: 45px;">
          <img src="../../../../assets/images/core/ProductDocumentation.svg" class="sidebar-icon" />
          Product Documentation
        </a>
      </li> -->
      <li *ngIf="setUserDetails.user.role.includes('organization_admin_role')">
        <a
          routerLink="/organisation/cloud"
          routerLinkActive="active"
          [class.disabled]="!orgSelect"
          style="height: 45px;"
        >
          <img src="../../../../assets/images/core/cloud.svg" class="sidebar-icon" />
          Cloud
        </a>
      </li>
      <li>
        <a href="https://docs.compliancebot.io/" target="_blank" [class.disabled]="!orgSelect" style="height: 45px;">
          <img src="../../../../assets/images/core/documentation.svg" class="sidebar-icon" />
          Documentation
        </a>
      </li>
      <!-- <li>
        <a routerLink="/organisation/select-citations" routerLinkActive="active">
          <img src="../../../../assets/images/core/settings.svg" class="sidebar-icon" />
          Cb
        </a>
      </li> -->
      <li *ngIf="userType === 'system_admin_role'" style="height: 45px;">
        <a routerLink="/organisation/leads" routerLinkActive="active">
          <img src="../../../../assets/images/core/lead.png" class="sidebar-icon" />
          Leads
        </a>
      </li>
      <li *ngIf="userType === 'system_admin_role'">
        <a routerLink="/organisation/GRC" routerLinkActive="active " style="height: 45px;">
          <img src="../../../../assets/images/core/lead.png" class="sidebar-icon" />
          GRC
        </a>
      </li>
      <li>
        <a
          routerLink="/organisation/settings"
          routerLinkActive="active"
          [class.disabled]="!orgSelect"
          style="height: 45px;"
        >
          <img src="../../../../assets/images/core/settings.svg" class="sidebar-icon" />
          Settings
        </a>
      </li>
    </ul>
  </div>
</div>
