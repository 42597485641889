import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ToastInfo {
  message: string;
  type: 'success' | 'error';
}

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  private toastSubject = new Subject<ToastInfo>();
  public toast$ = this.toastSubject.asObservable();

  showSuccess(message: string): void {
    this.toastSubject.next({ message, type: 'success' });
  }

  showError(message: string): void {
    this.toastSubject.next({ message, type: 'error' });
  }

  handleApiResponse(response: any): void {
    if (response.success) {
      this.showSuccess(response.message || 'Operation successful');
    } else {
      this.showError(response.message || 'An error occurred');
    }
  }
}
