import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OrganisationAdminRoutingModule } from './organisation-admin-routing.module';
import { EventsComponent } from '@app/Organisation-admin/Events/events.component';
import { EventCardComponent } from '@app/Organisation-admin/Events/event-card/event-card.component';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { EventScheduleDialogComponent } from '@app/Organisation-admin/EventsSchedule/eventsSchedule.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EventDetailsComponent } from '../Organisation-admin/Events/event-details/event-details.component';
import { ConfirmationDialogComponent } from '../Organisation-admin/Events/confirmation-dialog/confirmation-dialog.component';
import { SharedModule } from '@app/shared';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { NgbdSortableHeader, OrgDashboardComponent } from '@app/Organisation-admin/orgDashboard/orgDashboard.component';
import { OrgDashboardHistoryComponent } from '@app/Organisation-admin/orgDashboardHistory/orgDashboardHistory.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DocumentsComponent } from './documents/documents.component';
import { AttentionRequiredComponent } from './documents/attention-required/attention-required.component';
import { CreateDocumentComponent } from './documents/create-document/create-document.component';
import { TrainingComponent } from './documents/training/training.component';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { VendorManagementComponent } from './documents/vendor-management/vendor-management.component';
import { ComplianceManagementComponent } from './documents/compliance-management/compliance-management.component';
import { RiskManagementComponent } from './documents/risk-management/risk-management.component';
import { ItManagementComponent } from './documents/it-management/it-management.component';
import { TemplatesComponent } from './documents/templates/templates.component';
import { FormsComponent } from './documents/forms/forms.component';
import { NotificationsComponent } from './documents/notifications/notifications.component';
import { RetiredDocsComponent } from './documents/retired-docs/retired-docs.component';
import { CreateTemplateComponent } from './documents/create-template/create-template.component';
import { CreateFormComponent } from './documents/create-form/create-form.component';
// import { NgxDropzoneModule } from 'ngx-dropzone';
import { DropzoneModule, DropzoneConfigInterface, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { NotificationCardComponent } from './documents/notifications/notification-card/notification-card.component';
import { CreateFolderComponent } from './documents/create-folder/create-folder.component';
import { CreateSubFolderComponent } from './documents/create-sub-folder/create-sub-folder.component';
import { MatIconModule } from '@angular/material/icon';
import { PublishedDocumentComponent } from './documents/published-document/published-document.component';
import { DocumentDetailPageComponent } from './documents/document-detail-page/document-detail-page.component';
import { FolderDocumentViewComponent } from './documents/folder-document-view/folder-document-view.component';
import { CreateTrainingCampaignComponent } from './documents/create-training-campaign/create-training-campaign.component';
import { TrainingReportPageComponent } from './documents/training-report-page/training-report-page.component';
import { TrainingReportsComponent } from './documents/training-reports/training-reports.component';
import { DemoMaterialModule } from '../materials.module';
import { TrainingCardComponent } from './documents/training/training-card/training-card.component';
import { CreateTrainingGroupComponent } from './documents/create-training-group/create-training-group.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { InfiniteScrollerComponent } from './infinite-scroller/infinite-scroller.component';
import { EditTrainingCampaignComponent } from './documents/edit-training-campaign/edit-training-campaign.component';
import { IncidentComponent } from './incident/incident.component';
import { IncidentManagementComponent } from './incident/incident-management/incident-management.component';
import { CreateIncidentComponent } from './incident/create-incident/create-incident.component';
import { IncidentDetailsComponent } from './incident/incident-details/incident-details.component';
import { ImportDocumentComponent } from './documents/import-document/import-document.component';
import { EditDocumentsComponent } from './documents/edit-documents/edit-documents.component';
import { ImportTemplateComponent } from './documents/import-template/import-template.component';
import { FormManagementComponent } from './form-management/form-management.component';
import { ApprovePendingFormComponent } from './form-management/approve-pending-form/approve-pending-form.component';
import { CreateFormManagementComponent } from './form-management/create-form-management/create-form-management.component';
import { FormDetailsPageComponent } from './form-management/form-details-page/form-details-page.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ManageControlsComponent } from './orgDashboard/manage-controls/manage-controls.component';
import { ControlSwitchDialogComponent } from './orgDashboard/control-switch-dialog/control-switch-dialog.component';
import { ArchiveDocumentComponent } from './documents/archive-document/archive-document.component';
import { CloudServiceProviderComponent } from './cloud-service-provider/cloud-service-provider.component';
import { TestCloudServiceProviderComponent } from './cloud-service-provider/test-cloud-service-provider/test-cloud-service-provider.component';
import { ResourcesComponent } from './cloud-service-provider/resources/resources.component';
import { FoldersComponent } from './documents/folders/folders.component';
import { ImportDocumentAndTemplateComponent } from './documents/import-document-and-template/import-document-and-template.component';
import { CloudDetailsComponent } from './cloud-service-provider/cloud-details/cloud-details.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  acceptedFiles: 'image/*, application/pdf',
  createImageThumbnails: true
};

@NgModule({
  imports: [
    CommonModule,

    NgSelectModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    TranslateModule,
    OrganisationAdminRoutingModule,
    DemoMaterialModule,
    NgxMaterialTimepickerModule,
    FormsModule,
    SharedModule,
    NgbModule,
    DropzoneModule,
    MatIconModule,
    NgMultiSelectDropDownModule.forRoot(),
    DemoMaterialModule,
    CKEditorModule,
    InfiniteScrollModule
  ],
  declarations: [
    EventsComponent,
    EventCardComponent,
    EventScheduleDialogComponent,
    EventDetailsComponent,
    ConfirmationDialogComponent,
    OrgDashboardComponent,
    OrgDashboardHistoryComponent,
    NgbdSortableHeader,
    DocumentsComponent,
    AttentionRequiredComponent,
    CreateDocumentComponent,
    TrainingComponent,
    VendorManagementComponent,
    ComplianceManagementComponent,
    RiskManagementComponent,
    ItManagementComponent,
    TemplatesComponent,
    FormsComponent,
    NotificationsComponent,
    RetiredDocsComponent,
    CreateTemplateComponent,
    CreateFormComponent,
    NotificationCardComponent,
    ImportDocumentComponent,
    TrainingCardComponent,
    CreateFolderComponent,
    CreateSubFolderComponent,
    PublishedDocumentComponent,
    DocumentDetailPageComponent,
    FolderDocumentViewComponent,
    CreateTrainingCampaignComponent,
    FolderDocumentViewComponent,
    TrainingReportPageComponent,
    TrainingReportsComponent,
    // InfiniteScrollerComponent
    CreateTrainingGroupComponent,
    EditTrainingCampaignComponent,
    IncidentComponent,
    IncidentManagementComponent,
    CreateIncidentComponent,
    IncidentDetailsComponent,
    CreateIncidentComponent,
    EditDocumentsComponent,
    ImportTemplateComponent,
    FormManagementComponent,
    ApprovePendingFormComponent,
    CreateFormManagementComponent,
    FormDetailsPageComponent,
    ManageControlsComponent,
    ControlSwitchDialogComponent,
    ArchiveDocumentComponent,
    CloudServiceProviderComponent,
    TestCloudServiceProviderComponent,
    ResourcesComponent,
    FoldersComponent,
    ImportDocumentAndTemplateComponent,
    CloudDetailsComponent
  ],
  providers: [
    OrganisationAdminService,
    OrganisationDashboardService,
    { provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG }
  ],
  entryComponents: [EventScheduleDialogComponent, ConfirmationDialogComponent],
  exports: [EventsComponent, EventCardComponent, EventScheduleDialogComponent, EventDetailsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OrganisationAdminModule {}
