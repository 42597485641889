import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService } from '@app/core';
import { PasswordResetService } from './password-reset.service';

const log = new Logger('PasswordReset');

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  version: string = environment.version;
  passwordResetForm: FormGroup;
  isLoading = false;
  uid: string = null;
  token: string = null;
  submitted = false;
  hide = true;
  hideNewPassword = true;
  set_pwd: any = null;
  error = {
    new_password1: '',
    new_password2: ''
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private i18nService: I18nService,
    private passwordResetService: PasswordResetService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('uid');
    this.token = this.route.snapshot.paramMap.get('token');
    this.route.queryParamMap.subscribe(params => {
      this.set_pwd = params.get('type');
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.passwordResetForm.controls;
  }

  passwordReset() {
    this.isLoading = true;
    this.submitted = true;
    if (this.passwordResetForm.invalid) {
      return;
    }
    this.passwordResetService
      .passwordReset(this.passwordResetForm.value, this.uid, this.token)
      .pipe(
        finalize(() => {
          this.passwordResetForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.error = {
            new_password1: '',
            new_password2: ''
          };
          this.submitted = false;
          // TODO: Change navigation after password reset success
          console.log(data);
          this.router.navigate(['/login'], { queryParams: { state: 'password-updated' }, replaceUrl: true });
        },
        error => {
          log.debug(`Invalid Token Error: ${error}`);
          this.error = error.error;
          console.log('error:', this.error);
        }
      );
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    this.passwordResetForm = this.formBuilder.group(
      {
        new_password1: ['', [Validators.validPasswordCheck()]],
        new_password2: ['']
      },
      {
        validator: [Validators.MustMatch('new_password1', 'new_password2')]
      }
    );
  }
}
