import { Component, OnInit } from '@angular/core';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { OrganisationService } from '@app/core/organisation.service';
import { Subscription } from 'rxjs';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-program-creation',
  templateUrl: './program-creation.component.html',
  styleUrls: ['./program-creation.component.scss']
})
export class ProgramCreationComponent implements OnInit {
  constructor(
    private orgDashboardService: OrganisationDashboardService,
    private orgService: OrganisationService,
    private toaster: ToasterService,
    private sanitizer: DomSanitizer
  ) {}
  searchText = '';
  panelOpenState = false;
  guidSubscription: Subscription;
  organisationID = '';
  loading = false;
  filteredData = {};
  total = 0;
  filterTotal = 0;
  payload: any = {
    true_applicable_units: [],
    false_applicable_units: []
  };
  selectedCategories: string[] = [];
  tableData: any = [];
  updatedCitation: boolean = false;
  disabledStepper: boolean = false;
  disable_citations: any[];
  enable_citations: any[];
  ngOnInit(): void {
    this.selectedCategories = this.tableData.map((item: any) => item.title);
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(org_guid => {
      this.organisationID = org_guid;
      if (this.organisationID) {
        this.getProgramUnitLite();
      }
    });
    if (localStorage.getItem('select_applicable_ctrl_completed') === 'true') {
      this.disabledStepper = false;
    } else {
      this.disabledStepper = true;
    }
  }
  toggleExpansionPanel(expansionPanel: MatExpansionPanel): void {
    expansionPanel.toggle();
  }
  getProgramUnitLite() {
    this.loading = true;
    this.orgDashboardService.getProgramUnitLiteOnBoard(this.organisationID).subscribe(
      res => {
        this.loading = false;
        this.tableData = {};
        this.total = res.results.length;
        this.disable_citations = res.disable_citations;
        this.enable_citations = res.enable_citations;
        let prepardData = res.results.map((item: any) => {
          return {
            applicable: item.applicable,
            controls: item.details.controls,
            category: item.details.categories,
            guid: item.guid,
            ...this.isAsmtApplicable(item)
          };
        });
        prepardData.forEach((ctrl: any) => {
          if (ctrl.hasOwnProperty('isAssessmentApplicable') && ctrl.isAssessmentApplicable !== null) {
            ctrl.applicable = ctrl.isAssessmentApplicable;
          }
        });
        let newData = {};
        for (let item of prepardData) {
          if (
            localStorage.getItem('select_applicable_ctrl_completed') !== 'true' &&
            item.hasOwnProperty('isAssessmentApplicable') &&
            item.isAssessmentApplicable == null
          ) {
            item.applicable = true;
          }
          if (!newData[item.category]) {
            newData[item.category] = [];
          }
        }
        for (let key in newData) {
          newData[key] = { data: prepardData.filter((item: any) => item.category.toString() == key) };
        }
        for (let key of Object.keys(newData)) {
          newData[key].applicable = newData[key].data.every((item: any) => item.applicable);
          newData[key].isAssessmentApplicable = newData[key].data.every((item: any) => item?.isAssessmentApplicable);
        }

        this.tableData = JSON.parse(JSON.stringify(newData));
        this.filteredData = this.tableData;
        this.loading = false;
      },
      error => {
        this.loading = false;
        console.log(error);
      }
    );
  }
  //
  updateApplicableControls() {
    this.loading = true;
    let keys = Object.keys(this.tableData);
    for (let i = 0; i < keys.length; i++) {
      for (let j = 0; j < this.tableData[keys[i]].data.length; j++) {
        if (!this.tableData[keys[i]].data[j].applicable) {
          this.payload['false_applicable_units'].push(this.tableData[keys[i]].data[j].guid);
        } else {
          this.payload['true_applicable_units'].push(this.tableData[keys[i]].data[j].guid);
        }
      }
    }
    let payload = { ...this.payload };
    this.orgDashboardService.updateApplicabilityOnBoard(this.organisationID, payload).subscribe(
      res => {
        this.payload['true_applicable_units'] = [];
        this.payload['false_applicable_units'] = [];
        localStorage.setItem('select_applicable_ctrl_completed', 'true');
        this.disabledStepper = false;
        this.toaster.showSuccess('Applicable controls updated.');
        this.getProgramUnitLite();
        this.updatedCitation = true;
      },
      error => {
        this.loading = false;
      }
    );
  }
  highlightText(text: string, searchText: string): SafeHtml {
    if (!searchText || !text) {
      return text;
    }
    const regExp = new RegExp(searchText, 'gi');
    const highlightedText = text.replace(regExp, '<span  style="font-size:14px;font-weight: 900;">$&</span>');
    return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
  }
  selectOverallControl(event: any, key: string) {
    this.tableData[key].data.forEach((item: any) => {
      if (!item.hasOwnProperty('isAssessmentApplicable') || item?.isAssessmentApplicable == null) {
        item.applicable = event.applicable;
      }
    });
    this.filteredData[key].data.forEach((item: any) => {
      if (!item.hasOwnProperty('isAssessmentApplicable') || item?.isAssessmentApplicable == null) {
        item.applicable = event.applicable;
      }
    });
  }
  selectControl(event: any, key: any) {
    let isApplicable = this.tableData[key].data.some((item: any) => !item.applicable);
    this.tableData[key].applicable = !isApplicable;
    this.filteredData[key].applicable = this.tableData[key].applicable;
    for (let i = 0; i < this.filteredData[key].data.length; i++) {
      if (event.controls[0].code == this.filteredData[key].data[i].controls[0].code) {
        this.filteredData[key].data[i].applicable = event.applicable;
        break;
      }
    }
  }
  get checkIsControlNotSelected() {
    return Object.values(this.filteredData).some((dataItem: any) => dataItem.data.some((item: any) => item.applicable));
  }

  propsToInclude = ['title', 'code', 'regulatory_text'];
  searchAndReturnObjects() {
    const result: any = {};
    let data = this.filteredData;
    for (const category in data) {
      if (data.hasOwnProperty(category)) {
        const categoryData = data[category];
        if (categoryData.data) {
          const matchedObjects = categoryData.data.reduce((acc: any[], obj: any) => {
            const matchedControls = obj.controls.filter(
              (control: any) =>
                this.propsToInclude.some(
                  prop => control[prop] && control[prop].toLowerCase().includes(this.searchText.toLowerCase())
                ) ||
                (control.master_control &&
                  this.propsToInclude.some(
                    prop =>
                      control.master_control[prop] &&
                      control.master_control[prop].toLowerCase().includes(this.searchText.toLowerCase())
                  ))
            );

            if (matchedControls.length > 0) {
              const includedObj: any = {
                applicable: obj.applicable,
                isAssessmentApplicable: obj.hasOwnProperty('isAssessmentApplicable')
                  ? obj.isAssessmentApplicable
                  : null,
                tooltip: obj.hasOwnProperty('tooltip') ? obj.tooltip : null,
                controls: matchedControls.map((control: any) => {
                  const includedControl: any = {};
                  this.propsToInclude.forEach(prop => {
                    if (control[prop]) {
                      includedControl[prop] = control[prop];
                    }
                  });

                  if (control.master_control) {
                    includedControl.master_control = {};
                    this.propsToInclude.forEach(prop => {
                      if (control.master_control[prop]) {
                        includedControl.master_control[prop] = control.master_control[prop];
                      }
                    });
                  }
                  return includedControl;
                }),
                category: obj.category,
                guid: obj.guid
              };
              acc.push(includedObj);
            }
            return acc;
          }, []);

          if (matchedObjects.length > 0) {
            result[category] = {
              data: matchedObjects,
              applicable: categoryData.applicable,
              isAssessmentApplicable: categoryData.hasOwnProperty('isAssessmentApplicable')
                ? categoryData.isAssessmentApplicable
                : null
            };
          }
        }
      }
    }
    this.tableData = result;
    this.filterTotal = 0;
    for (const category in this.tableData) {
      if (this.tableData.hasOwnProperty(category)) {
        this.filterTotal += this.tableData[category].data.length;
      }
    }
  }

  getTooltipMessage(questionText: string): string {
    return `Control selection disabled. This control's applicability is determined by your response to the question: "${questionText}". To update control applicability, please revisit and modify your response to this question.`;
  }

  isAsmtApplicable(item: any) {
    const filteredEnabledCitations = this.enable_citations.filter((ctrl: any) => {
      return ctrl.citations.some((code: string) => code === item.details.controls[0]?.code);
    });
    const filteredDisabledCitations = this.disable_citations.filter((ctrl: any) => {
      return ctrl.citations.some((code: string) => code === item.details.controls[0]?.code);
    });
    if (filteredEnabledCitations?.length > 0) {
      return { isAssessmentApplicable: true, tooltip: this.getTooltipMessage(filteredEnabledCitations[0].question) };
    } else if (filteredDisabledCitations?.length > 0) {
      return { isAssessmentApplicable: false, tooltip: this.getTooltipMessage(filteredDisabledCitations[0].question) };
    }

    return { isAssessmentApplicable: null, tooltip: null };
  }

  get calculateTotalSelectedControls() {
    let totalSelectedControls = 0;
    for (const category in this.filteredData) {
      if (this.filteredData.hasOwnProperty(category)) {
        const categoryData = this.filteredData[category];
        if (categoryData.data) {
          categoryData.data.forEach((obj: any) => {
            if (obj.applicable) {
              totalSelectedControls += obj.controls.length;
            }
          });
        }
      }
    }
    return totalSelectedControls;
  }
}
