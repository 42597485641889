import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToasterService, ToastInfo } from './toastr.service';

@Component({
  selector: 'app-toaster',
  template: `
    <div class="toast-container">
      <ngb-toast
        *ngFor="let toast of toasts; let i = index"
        [class]="toast.type === 'success' ? 'bg-success text-light' : 'bg-danger text-light'"
        [autohide]="true"
        [delay]="5000"
        (hidden)="removeToast(i)"
      >
        <div class="d-flex align-items-center">
          <div
            class="icon-circle mr-2"
            [ngClass]="toast.type === 'success' ? 'bg-white text-success' : 'bg-white text-danger'"
          >
            <i class="fas" [ngClass]="toast.type === 'success' ? 'fa-check' : 'fa-times'"></i>
          </div>
          <div>{{ toast.message }}</div>
        </div>
      </ngb-toast>
    </div>
  `,
  styles: [
    `
      .toast-container {
        position: fixed;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1050;
        width: 350px;
        max-width: 90%;
      }
      .toast-body {
        width: 100%;
      }
      .icon-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
        min-height: 24px;
        border-radius: 50%;
      }
      ::ng-deep ngb-toast {
        width: 100%;
        margin-bottom: 0.5rem;
      }
      ::ng-deep .toast {
        width: 100%;
      }
    `
  ]
})
export class ToasterComponent implements OnInit, OnDestroy {
  toasts: ToastInfo[] = [];
  private subscription: Subscription;

  constructor(private toasterService: ToasterService) {}

  ngOnInit() {
    this.subscription = this.toasterService.toast$.subscribe(toast => {
      if (toast) {
        if (toast.type === 'success') {
          // For success, replace any existing success toast
          this.toasts = this.toasts.filter(t => t.type !== 'success');
        }
        this.toasts.push(toast);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  removeToast(index: number) {
    this.toasts.splice(index, 1);
  }
}
