import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileConfigAuthGuard implements CanActivate {
  isProgramConfigCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(public authenticationService: AuthenticationService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('isProgramConfigCompleted') === 'true') {
      this.router.navigateByUrl('/organization-assessment');
    } else {
      return true;
    }
  }
}
