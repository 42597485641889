<div class="cb-container">
  <div class="container-fluid" [ngClass]="{ 'blur-background': wizardService.isConfirmedFinishedHippaProgram | async }">
    <div class="row">
      <div class="col d-flex justify-content-between align-items-center header-padding">
        <div>
          <h2 class="fs-20">Kickstart Your Compliance Program</h2>
        </div>
        <div class="icon-wrapper" *ngIf="selectedButton != 'generate-documents'">
          <!-- this will go in next release :Important  -->

          <!-- <span class="invite-button" (click)="inviteMembersModal()">
            <mat-icon class="mat-icons">person_add</mat-icon> Invite Collaborator</span
          > -->
          <div class="ml-auto  d-flex">
            <button
              class="btn-back"
              *ngIf="selectedButton === 'select-citations'"
              (click)="navigateTo('organization-assessment')"
            >
              Back
            </button>
            <button
              class="btn ml-custom"
              (click)="saveAndContinue()"
              [disabled]="disabledSaveContinue"
              [ngStyle]="{
                'background-color': disabledSaveContinue ? 'rgba(0,0,0,.26)' : '#2a647c'
              }"
            >
              Save and Continue
            </button>
          </div>
        </div>

        <div class="icon-wrapper" *ngIf="selectedButton == 'generate-documents'">
          <!-- this will go in next release :Important -->

          <!-- <span class="invite-button" (click)="inviteMembersModal()">
            <mat-icon class="mat-icons">person_add</mat-icon> Invite Collaborator</span
          > -->
          <div class="ml-auto d-flex">
            <button class="btn-back" (click)="backToControls()">Back</button>
            <button
              class="btn-finish ml-custom"
              (click)="documentSuccessModal()"
              [disabled]="disabledFinish"
              [ngStyle]="{
                'background-color': disabledFinish ? 'rgba(0,0,0,.26)' : '#2a647c'
              }"
            >
              Finish
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="stepper">
      <div class="wrapper">
        <div class="arrow-steps clearfix">
          <div
            class="step"
            [class.selected]="selectedButton === 'organization-assessment'"
            (click)="navigateTo('organization-assessment')"
          >
            <mat-icon class="tick-mark" *ngIf="isOrgAsmtCompleted()">done</mat-icon>
            <a>Assess Your Organization</a>
          </div>
          <div
            class="step current"
            [class.selected]="selectedButton === 'select-citations'"
            (click)="disabledSaveContinue || disabledStepper || navigateTo('select-citations')"
          >
            <mat-icon class="tick-mark" *ngIf="isCtrlCompleted()">done</mat-icon>
            <a>Select Applicable Controls</a>
          </div>

          <div
            class="step"
            [class.selected]="selectedButton === 'generate-documents'"
            (click)="disabledSaveContinue || disabledStepper || navigateTo('generate-documents')"
          >
            <a>Generate Policy & Procedure Documents</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
