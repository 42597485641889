import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganisationDashboardService {
  constructor(private http: HttpClient) {}

  /** Data call for program list **/
  organisationalProgram(org_uuid: string): Observable<any> {
    return this.http.get('/api/organizations/' + org_uuid + '/organizationprograms/', {
      withCredentials: true
    });
  }

  /** Data call for program list **/
  organisationalDrillDownData(org_uuid: string, prog_uuid: string, frequency: string, slug: string): Observable<any> {
    return this.http.get(
      '/api/organizations/' +
        org_uuid +
        '/dashboard/events/?program=' +
        prog_uuid +
        '&frequency=' +
        frequency +
        '&slug=' +
        slug,
      {
        withCredentials: true
      }
    );
  }

  organisationalDrillDownHistoryData(org_uuid: string, event_uuid: string, page: string = '1'): Observable<any> {
    return this.http.get('/api/organizations/' + org_uuid + '/events/' + event_uuid + '/history/?page=' + page, {
      withCredentials: true
    });
  }

  organisationalDashboardData(org_uuid: string, prog_uuid: string): Observable<any> {
    return this.http.get('/api/organizations/' + org_uuid + '/dashboard/?program=' + prog_uuid, {
      withCredentials: true
    });
  }
  getProgramUnitLite(org_uuid: string, prog_uuid: string, orgType = ''): Observable<any> {
    return this.http.get(
      '/api/organizations/' + org_uuid + '/compliance_program/' + prog_uuid + '/program_unit_lite/?org_type=' + orgType,
      {
        withCredentials: true
      }
    );
  }
  updateApplicabilityOnBoard(org_uuid: string, payload: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/onboarding/program_unit_lite/update_applicability/`, payload, {
      withCredentials: true
    });
  }

  updateApplicability(org_uuid: string, control_guid: string, payload: any): Observable<any> {
    return this.http.put(
      `/api/organizations/${org_uuid}/compliance_program/${control_guid}/program_unit/update_applicability/`,
      payload,
      {
        withCredentials: true
      }
    );
  }

  getProgramUnitLiteOnBoard(org_uuid: string, org_type: string = '', isApplicable = true): Observable<any> {
    // ?org_typ' + org_type
    return this.http.get('/api/organizations/' + org_uuid + '/onboarding/program_unit_lite/', {
      withCredentials: true
    });
  }
  getOrganizationType(org_uuid: string): Observable<any> {
    return this.http.get('/api/organizations/' + org_uuid + '/organization_types', {
      withCredentials: true
    });
  }
  getProfileConfiguration(org_guid: string): Observable<any> {
    return this.http.get('/api/organizations/' + org_guid + '/profile-configuration', {
      withCredentials: true
    });
  }
  saveProfileConfiguration(org_guid: string, payload = {}): Observable<any> {
    return this.http.post(`/api/organizations/${org_guid}/profile-configuration/`, payload, {
      withCredentials: true
    });
  }

  getControlsData(orgId: string, programGuid: string, params: any = {}, page = 1) {
    if (!params.category || params.category.length === 0) {
      delete params.category;
    }
    if (params.title === undefined || params.title === null || params.title.trim() === '') {
      delete params.title;
    } else {
      params.title = params.title.trim();
    }
    if (!params.status || params.status.length === 0) {
      delete params.status;
    }
    const queryParams = Object.keys(params)
      .filter(key => params[key] !== undefined && params[key] !== null && params[key] !== '')
      .map(key => `${key}=${encodeURIComponent(JSON.stringify(params[key]))}`)
      .join('&');
    const url = `/api/organizations/${orgId}/compliance_program/${programGuid}/program_unit?page=${page}${
      queryParams ? '&' + queryParams : ''
    }`;
    return this.http.get(url, {
      withCredentials: true
    });
  }

  gethistoricalData(orgId: string, programGuid: string, date: any) {
    return this.http.get(
      `/api/organizations/${orgId}/compliance_program/${programGuid}/program_unit_snapshot/?date=${date}`,
      {
        withCredentials: true
      }
    );
  }
  getFiltersData(orgId: string) {
    return this.http.get(`/api/organizations/${orgId}/program_configs/`, {
      withCredentials: true
    });
  }
  getOrgDocumentStatusData(orgId: string) {
    return this.http.get(`/api/organizations/${orgId}/doc-upload-status/`, {
      withCredentials: true
    });
  }

  getPolicyList(org_uuid: string, control_guid: string): Observable<any> {
    return this.http.get('/api/organizations/' + org_uuid + '/programunit/' + control_guid + '/policy', {
      withCredentials: true
    });
  }
  getProcedureList(org_uuid: string, control_guid: string): Observable<any> {
    return this.http.get('/api/organizations/' + org_uuid + '/programunit/' + control_guid + '/procedure', {
      withCredentials: true
    });
  }
  getSearchedPolicyList(org_uuid: string, control_guid: string, search_title: string): Observable<any> {
    return this.http.get(
      '/api/organizations/' + org_uuid + '/programunit/' + control_guid + '/policy/' + search_title,
      {
        withCredentials: true
      }
    );
  }

  getSearchedProcedureList(org_uuid: string, control_guid: string, search_title: string): Observable<any> {
    return this.http.get(
      '/api/organizations/' + org_uuid + '/programunit/' + control_guid + '/procedure/' + search_title,
      {
        withCredentials: true
      }
    );
  }

  addPolicy(org_uuid: string, control_guid: string, payload: any): Observable<any> {
    return this.http.post('/api/organizations/' + org_uuid + '/programunit/' + control_guid + '/policy/', payload, {
      withCredentials: true
    });
  }

  updateNotes(org_uuid: string, org_prgoram_id: any, control_guid: string, payload: any): Observable<any> {
    return this.http.post(
      '/api/organizations/' +
        org_uuid +
        '/compliance_program/' +
        org_prgoram_id +
        '/program_unit/' +
        control_guid +
        '/update_notes/',
      payload,
      {
        withCredentials: true
      }
    );
  }

  removePolicy(org_uuid: string, control_guid: string, payload: any): Observable<any> {
    return this.http.post(
      '/api/organizations/' + org_uuid + '/programunit/' + control_guid + '/remove_policy/',
      payload,
      {
        withCredentials: true
      }
    );
  }

  addProcedure(org_uuid: string, control_guid: string, payload: any): Observable<any> {
    return this.http.post('/api/organizations/' + org_uuid + '/programunit/' + control_guid + '/procedure/', payload, {
      withCredentials: true
    });
  }

  updateProcedure(org_uuid: string, control_guid: string, policyGuid: string, payload: any): Observable<any> {
    return this.http.post(
      '/api/organizations/' +
        org_uuid +
        '/programunit/' +
        control_guid +
        '/procedure/' +
        policyGuid +
        '/update_procedure_names/',
      payload,
      {
        withCredentials: true
      }
    );
  }
  updatePolicy(org_uuid: string, control_guid: string, policyGuid: string, payload: any): Observable<any> {
    return this.http.post(
      '/api/organizations/' +
        org_uuid +
        '/programunit/' +
        control_guid +
        '/policy/' +
        policyGuid +
        '/update_policy_names/',
      payload,
      {
        withCredentials: true
      }
    );
  }
  removeProcedure(org_uuid: string, control_guid: string, payload: any): Observable<any> {
    return this.http.post(
      '/api/organizations/' + org_uuid + '/programunit/' + control_guid + '/remove_procedure/',
      payload,
      {
        withCredentials: true
      }
    );
  }

  getEvidenceDetails(org_uuid: string, control_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/evidence/${control_guid}/`, {
      withCredentials: true
      ///api/organizations/' + org_uuid + '/control/' + control_guid + '/evidence
    });
  }
  getHistoryDetails(org_uuid: string, control_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/programunit/${control_guid}/history/`, {
      withCredentials: true
      ///api/organizations/' + org_uuid + '/control/' + control_guid + '/evidence
    });
  }
  getEvidenceList(org_uuid: string, control_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/evidence/?org_program_unit_guid=${control_guid}`, {
      withCredentials: true
      ///api/organizations/' + org_uuid + '/control/' + control_guid + '/evidence
    });
  }
  getTestList(org_uuid: string, control_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/evidence/${control_guid}/get_tests/`, {
      withCredentials: true
      ///api/organizations/' + org_uuid + '/control/' + control_guid + '/evidence
    });
  }
  addEvidences(org_uuid: string, control_guid: string, payload: any): Observable<any> {
    return this.http.post('/api/organizations/' + org_uuid + '/evidence/' + control_guid + '/add_evidence/', payload, {
      withCredentials: true
      ///api/organizations/' + org_uuid + '/control/' + control_guid + '/evidence
    });
  }
  removeEvidences(org_uuid: string, control_guid: string, payload: any): Observable<any> {
    return this.http.post(
      '/api/organizations/' + org_uuid + '/evidence/' + control_guid + '/remove_evidence/',
      payload,
      {
        withCredentials: true
        ///api/organizations/' + org_uuid + '/control/' + control_guid + '/evidence
      }
    );
  }
  getAddRemoveEvidenceList(
    org_uuid: string,
    page: number,
    org_program_unit_guid: any,
    search_text?: any
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/meetingstatus/?page=${page}&org_program_unit_guid=${org_program_unit_guid}&search_text=${search_text}`,
      {
        withCredentials: true
        //api/organizations/${org_uuid}/meetingstatus/?search_text=${search_title}
      }
    );
  }
  searchEvidenceList(org_uuid: string, page: number, org_program_unit_guid: any, search_text: any): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/meetingstatus/?page=${page}&org_program_unit_guid=${org_program_unit_guid}&search_text=${search_text}`,
      {
        withCredentials: true
        //api/organizations/${org_uuid}/meetingstatus/?search_text=${search_title}
      }
    );
  }

  getAllDocumentVersionsPagination(
    org_uuid: string,
    page: number,
    org_program_unit_guid: any,
    tab_name: any,
    search_text: any
  ): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(
        `/api/organizations/${org_uuid}/document/published_unpublished_docs/?page=${page}&org_program_unit_guid=${org_program_unit_guid}&tab_name=${tab_name}&title=${search_text}`,
        {
          withCredentials: true
        }
      );
    }
  }
  isGrcEnabled(org_uuid: string) {
    return this.http.get(`/api/organizations/${org_uuid}/org_feature/`, {
      withCredentials: true
    });
  }
}
