<div class="container">
  <app-spinner *ngIf="loading"></app-spinner>
  <div class=" py-0 config-container">
    <div class="d-flex justify-content-between align-items-center w-100p mb-4 ">
      <div class="title">
        <!-- Configure Your Organization Profile -->
        Welcome {{ userName }} !
      </div>
      <!-- <div class="d-flex">
        <div>
          <button
            (click)="confirmationRequiredmModal()"
            [ngStyle]="{ 'background-color': programConfigform.invalid || !checkChipsSelected ? '#478199' : '#2a647c' }"
            [disabled]="programConfigform.invalid || !checkChipsSelected"
            class="mx-2 submit-button"
          >
            Save and Continue
          </button>
        </div>
      </div> -->
    </div>
    <span style="font-size: 24px; color: #09325E;"> Let’s set up your organization’s profile</span>
    <div>
      <mat-error *ngIf="error?.length"> *{{ error[0]?.toUpperCase() + error?.toLowerCase()?.slice(1) }} </mat-error>
    </div>
    <form (ngSubmit)="(saveForm)" [formGroup]="programConfigform" novalidate class="mt-2 p-2">
      <div class="row mt-3">
        <div class="mx-1 input-wrapper">
          <label for="status" class="label"> ORGANIZATION NAME</label>
          <mat-form-field class="example-full-width" appearance="outline" color="secondary">
            <input matInput placeholder="Type organization name here" formControlName="org_name" />
          </mat-form-field>
          <div style="height: 21px !important;">
            <ng-container
              *ngIf="
                programConfigform.controls.org_name &&
                (programConfigform.controls.org_name.touched || programConfigform.controls.org_name.dirty)
              "
            >
              <mat-error *ngIf="programConfigform.controls.org_name.hasError('required')">
                *Organization name is required
              </mat-error>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="mx-1 input-wrapper">
          <label for="status" class="label">SELECT COMPLIANCE PROGRAM</label>
          <div class="chips-container">
            <ng-container *ngFor="let chip of chips">
              <div
                [class.selected]="chip.selected"
                class=" chip d-flex justify-content-center align-items-center"
                (click)="onChipClick($event, chip)"
              >
                <div class="chiptext">
                  {{ chip.name }}
                </div>
                <div *ngIf="chip.name?.toLowerCase() !== 'HIPAA'?.toLowerCase()">
                  <mat-icon *ngIf="chip.selected" class="chip-x">close</mat-icon>
                </div>
              </div>
            </ng-container>
          </div>
          <mat-error *ngIf="!checkChipsSelected && !loading">
            *Please select atleast one program
          </mat-error>
        </div>
      </div>
    </form>
    <div class="d-flex">
      <div class="w-100p mt-4">
        <button
          (click)="saveForm()"
          [ngStyle]="{
            'background-color': programConfigform.invalid || !checkChipsSelected ? 'rgba(0,0,0,.26)' : '#2a647c'
          }"
          [disabled]="programConfigform.invalid || !checkChipsSelected"
          class="submit-button w-100p"
        >
          Setup Profile
        </button>
      </div>
    </div>
  </div>
</div>
