<app-spinner *ngIf="loading"></app-spinner>
<div id="sidenav" [class.backdrop]="opened"></div>
<ng-container *ngIf="!loading && !isGrcEnabled">
  <div class="cb-container cb-page-bg-color">
    <div class="container-fluid">
      <div class="row">
        <div class="col d-flex justify-content-between header-padding">
          <div>
            <h2 class="text-black-1 font-weight-bold" translate>organisation_dashboard.Dashboard</h2>
          </div>
          <div class="dashboard-options">
            <div class="d-inline-block">
              <div class="d-inline-block mx-2 ">
                <div style="display: grid;font-size: 10px;">
                  <label class="mb-1" translate>organisation_dashboard.Select Program</label>
                  <mat-form-field appearance="outline">
                    <mat-select
                      floatLabel="never"
                      (selectionChange)="programChange($event?.value)"
                      [value]="selectedProgram"
                    >
                      <mat-option
                        class="checkmark"
                        *ngFor="let program of listOfCompliance"
                        [value]="program['guid']"
                        [ngClass]="{ checkmark: program['guid'] == selectedProgram }"
                        >{{ program['name'] }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!--          TODO: Need to implemented-->
            <!--          <div class="d-inline-block ml-4">-->
            <!--            <label class="mb-1" translate>organisation_dashboard.Duration</label><br />-->
            <!--            <select class="cb-input w-160">-->
            <!--              <option translate>organisation_dashboard.Current Compliance Year</option>-->
            <!--            </select>-->
            <!--          </div>-->
          </div>
        </div>
      </div>
      <div class="row dashboard-charts" *ngIf="overallDashboardData">
        <div class="col-lg-4 single-chart">
          <div class="row left-row border-right-gray h-500">
            <div class="col">
              <div class="col d-flex justify-content-between">
                <h5 class="font-weight-normal text-black-1 pt-4 pb-3 d-inline-block" translate>
                  organisation_dashboard.Overall Compliance Status
                </h5>
                <button class="btn-acc-toggle mt-3 d-lg-none d-inline-block">
                  <i class="fas fa-chevron-up text-black-2"></i>
                </button>
              </div>
              <div class="col">
                <div class="mb-4" *ngFor="let stats of overallDashboardData">
                  <div *ngIf="DataCheck(stats.event_details)">
                    <img class="empty-data-img" src="../../../assets/images/dashboard/not_sufficient_data.svg" />
                    <span class="empty-data-text" translate>organisation_dashboard.Not sufficient data</span>
                  </div>
                  <app-graph-component
                    *ngIf="!DataCheck(stats.event_details)"
                    [graphData]="stats"
                    [graphDataEventType]="'overall'"
                    (graphDataDrillDown)="drillDownData($event)"
                  ></app-graph-component>
                </div>
                <div class="devider-legend mb-4"></div>
                <h5 class="font-weight-bold text-black-1" translate>organisation_dashboard.Legend<span>:</span></h5>
                <div class="row">
                  <div class="col d-flex align-items-center mb-2">
                    <span class="green-signal d-inline-block"></span>
                    <h5 class="font-weight-normal text-black-1 d-inline-block m-0 pl-2" translate>
                      organisation_dashboard.Good Standing
                    </h5>
                  </div>
                  <div class="col d-flex align-items-center mb-2">
                    <span class="red-signal d-inline-block"></span>
                    <h5 class="font-weight-normal text-black-1 d-inline-block m-0 pl-2" translate>
                      organisation_dashboard.No Participation
                    </h5>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col d-flex align-items-center mb-2">
                    <span class="grey-signal d-inline-block"></span>
                    <h5 class="font-weight-normal text-black-1 d-inline-block m-0 pl-2" translate>
                      organisation_dashboard.Inactive
                    </h5>
                  </div>
                  <div class="col d-flex align-items-center mb-2">
                    <span class="yellow-signal d-inline-block"></span>
                    <h5 class="font-weight-normal text-black-1 d-inline-block m-0 pl-2" translate>
                      organisation_dashboard.Pending
                    </h5>
                  </div>
                  <div class="col d-flex align-items-center mb-2">
                    <span class="maroon-signal d-inline-block"></span>
                    <h5 class="font-weight-normal text-black-1 d-inline-block m-0 pl-2" translate>
                      organisation_dashboard.Disregard
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-8 scrollable-view">
          <div class="row">
            <div class="col-lg-6 single-chart" *ngFor="let stats of dashboardProgramData; let i = index">
              <!-- [attr.data-index]="i" -->
              <div
                [ngClass]="
                  !accArray.includes(i)
                    ? 'row border-right-gray border-bottom-gray h-250'
                    : 'row border-right-gray border-bottom-gray'
                "
              >
                <div class="col">
                  <div class="col d-flex justify-content-between">
                    <h5 class="font-weight-normal text-black-1 pt-4 pb-3 d-inline-block" translate>
                      organisation_dashboard.Compliance Status
                      <span class="sub-heading">
                        -
                        <span class="sub-heading" translate
                          >organisation_dashboard.event_type.{{ stats.event_type }}</span
                        >
                      </span>
                      <span></span>
                    </h5>
                    <button class="btn-acc-toggle mt-3 d-lg-none d-inline-block cursor-pointer" (click)="toggle(i)">
                      <i
                        [ngClass]="
                          !accArray.includes(i) ? 'fas fa-chevron-down text-black-2' : 'fas fa-chevron-up text-black-2'
                        "
                      ></i>
                    </button>
                  </div>
                  <div class="col" *ngIf="!accArray.includes(i)">
                    <div class="mb-4">
                      <div *ngIf="DataCheck(stats.event_details)">
                        <img class="empty-data-img" src="../../../assets/images/dashboard/not_sufficient_data.svg" />
                        <span class="empty-data-text" translate>organisation_dashboard.Not sufficient data</span>
                      </div>
                      <app-graph-component
                        *ngIf="!DataCheck(stats.event_details)"
                        [graphData]="stats"
                        [graphDataEventType]="stats.event_type"
                        (graphDataDrillDown)="drillDownData($event)"
                      ></app-graph-component>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- latest dashboard -->

<ng-container *ngIf="!loading && isGrcEnabled">
  <div class="dashboard-view">
    <div class="bg-white">
      <div class="py-3 px-2">
        <div class="dashboard-title">
          Dashboard
        </div>
      </div>
      <hr class="m-0" />
      <div class="mt-3 d-flex justify-content-between px-2">
        <div>
          <div class="d-inline-block ml-2 ">
            <div style="display: grid;font-size: 10px;">
              <mat-form-field appearance="outline">
                <mat-select
                  floatLabel="never"
                  (selectionChange)="programChangeGrc($event?.value)"
                  [value]="selectedProgram"
                >
                  <mat-option
                    class="checkmark"
                    *ngFor="let program of listOfCompliance"
                    [value]="program['guid']"
                    [ngClass]="{ checkmark: program['guid'] == selectedProgram }"
                    >{{ program['name'] }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div style="font-size: 10px; " class="d-inline-block mx-2">
            <mat-form-field appearance="outline">
              <mat-select
                [disabled]="historyEnabled"
                [(ngModel)]="params.category"
                multiple
                placeholder="All Categories"
                (selectionChange)="applyFilters($event, 'category', true)"
              >
                <mat-option *ngFor="let item of categories" [value]="item.id">{{ item.title }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="d-inline-block position-relative ">
            <div style="display: grid; font-size: 10px;">
              <mat-form-field appearance="outline">
                <mat-select class="calendar-class" [(ngModel)]="selectedDate" (selectionChange)="onDateSelect($event)">
                  <mat-option [value]="today">Today {{ today }}</mat-option>
                  <mat-option [value]="lastMonth">Last Month {{ lastMonth }} </mat-option>
                  <mat-option [value]="lastQuarter">Last Quarter {{ lastQuarter }}</mat-option>
                  <mat-option [value]="lastYear">Last Year {{ lastYear }}</mat-option>
                  <!-- <mat-option value="" (click)="d.toggle()">Custom Date {{ customDate }} </mat-option> -->
                </mat-select>
                <mat-icon matSuffix style="transform: scale(0.8);font-size: 190%; ">calendar_today</mat-icon>
              </mat-form-field>
              <input
                style="position: absolute;right: 8%; background: transparent;pointer-events: none;
                  color: transparent;"
                (dateSelect)="onCustomDateSelect($event)"
                name="start_date"
                ngbDatepicker
                #d="ngbDatepicker"
                (click)="d.toggle()"
              />
            </div>
          </div>
        </div>
        <div class="mx-3" *ngIf="!this.loginOnboardFlag">
          <button
            style="width: 140px;height: 36px;"
            [disabled]="isSignatureLoading || isDownloading"
            class="cb-btn-secondary"
            (click)="generatePdfWithOnlineImages()"
          >
            <div
              *ngIf="!isDownloading && !isSignatureLoading"
              class="lite-signup-btn-text d-flex justify-content-center align-items-center h-100"
            >
              <div>
                HIPAA Certificate
              </div>
              <mat-icon style="font-size: 14px;height: 14px;">file_download</mat-icon>
            </div>
            <span>
              <i
                *ngIf="isDownloading || isSignatureLoading"
                class="fa"
                [ngClass]="{ 'fa-spin fa-spinner': isDownloading || isSignatureLoading }"
                style="font-size:large;"
              ></i>
              &nbsp;
            </span>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-between ">
          <mat-sidenav
            #sidenav
            class="side-nav-right "
            [class.open]="opened"
            position="start"
            role="directory"
            mode="over"
            id="mySidenav"
            [opened]="opened"
          >
            <ng-container *ngIf="actionType == 'controlOverview'">
              <div class="side-nav-content">
                <div class="control-header-div">
                  <h3 class="control-header" translate>
                    Control Overview
                  </h3>
                </div>
                <i
                  class="fa fa-times text-black-1"
                  style="width: 25px;
                     height: 25px;
                    right: 1em;
                     cursor: pointer;
                    position: absolute;
                      top: 1.3em;"
                  (click)="toggleSideBar()"
                  ngbAutoFocus
                  translate
                ></i>

                <div class="row">
                  <div class="column control-label" translate>
                    <label class="label-control font-weight-bold ">Title</label>
                    <h5>{{ selectedRowData?.details?.program_unit?.title }}</h5>
                  </div>

                  <div class="column status-label" translate>
                    <label class="label-control font-weight-bold">Status</label>
                    <div
                      style="width: 100px;font-size: 14px;"
                      class="rounded-pill p-1 px-2 text-center"
                      [ngStyle]="getStyle(selectedRowData?.status.overall_status)"
                    >
                      {{ statusMapper[selectedRowData?.status.overall_status] }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="column control-label" translate>
                    <label class="label-control font-weight-bold">Control</label>
                    <h5>{{ selectedRowData?.details.program_unit.code }}</h5>
                  </div>

                  <div class="column status-label " translate>
                    <label class="label-control font-weight-bold">Category</label>
                    <h5>{{ categoriesData }}</h5>
                  </div>
                </div>

                <div class="column" style=" margin-left: 7px;">
                  <label class="label-control font-weight-bold">
                    Note:
                    <ng-container *ngIf="!isEditing" class="btn-edit-container">
                      <i class="fas fa-pencil-alt btn-edit-container d-inline-block pl-1" (click)="startEditing()"></i>
                    </ng-container>
                  </label>
                  <div class="w-100p">
                    <!-- CKEditor -->
                    <ckeditor
                      *ngIf="isEditing"
                      [data]="selectedRowData?.details.notes"
                      [editor]="Editor"
                      [config]="config"
                      (change)="onChangeCkeditor($event)"
                    ></ckeditor>
                    <div class="ckeditor-error">
                      <label *ngIf="isEditing && editedCkeditorData.length >= 4096" class="text-danger mb-0"
                        >* You have reached the limit of 4096 characters</label
                      >
                    </div>
                    <div class="mt-1">
                      <span [innerHTML]="selectedRowData?.details.notes" *ngIf="!isEditing">{{
                        selectedRowData?.details.notes
                      }}</span>
                    </div>
                  </div>

                  <div *ngIf="isEditing" class="button-edit row">
                    <button type="button" class="btn btn-cancel" (click)="finishEditing(false)" ngbAutoFocus translate>
                      Cancel
                    </button>
                    <button
                      [disabled]="editedCkeditorData.length >= 4096"
                      type="button"
                      class="btn save-btn"
                      (click)="finishEditing(true, selectedRowData, selectedRowData?.details.notes)"
                      ngbAutoFocus
                      translate
                    >
                      Save
                    </button>
                  </div>
                </div>

                <mat-tab-group
                  (selectedTabChange)="onTabChanged($event, selectedRowData)"
                  #tabGroup
                  class="vertical "
                  style="margin-left: -20px;"
                  [selectedIndex]="selectedIndex"
                  *ngIf="opened"
                >
                  <mat-tab style="font-weight: 600; width: 30%;" label="Policies">
                    <div class="tab-content ">
                      <ng-container *ngFor="let item of policyData; let i = index">
                        <div class="policy-item">
                          <div style="font-size: 14px;  width: 50%" class="text-black-2 ">
                            <abbr title="{{ item.policy_doc?.name }}">
                              <h5
                                style="width: 101% !important;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap"
                              >
                                {{ item.policy_doc?.name }}
                              </h5>
                            </abbr>
                            <div *ngIf="countNonEmptyNames(item.policy_names) > 0">
                              <span
                                style="display: flex; color: gray; font-size: 13px;"
                                *ngIf="
                                  !showPolicyAndProcedureDetails(i, true) ||
                                  (!item.policy_names?.length && hasNonEmptyNames(item.policy_names))
                                "
                              >
                                {{ countNonEmptyNames(item.policy_names) }}
                                {{
                                  countNonEmptyNames(item.policy_names) === 1 ? 'relevant section' : 'relevant sections'
                                }}
                              </span>
                            </div>
                          </div>
                          <div class="text-left w-140 ml-4">
                            <h4 class="text-black-1 dm-text-1">
                              <span *ngIf="item.policy_doc?.status === 'Published'" class="published-dot"></span>
                              <span
                                *ngIf="item.policy_doc?.status === 'Not Published'"
                                class="not-published-dot"
                              ></span>
                              {{ item.policy_doc?.status }}
                            </h4>
                          </div>
                          <ng-container *ngIf="countNonEmptyNames(item.policy_names) > 0; else nodata">
                            <i
                              *ngIf="showPolicyAndProcedureDetails(i, true) || !item.policy_names?.length"
                              class="fas fa-chevron-up text-black-2 ng-star-inserted"
                              aria-hidden="true"
                              (click)="togglePolicyAndProcedure(i, true)"
                            ></i>
                            <i
                              *ngIf="!showPolicyAndProcedureDetails(i, true) || !item.policy_names?.length"
                              class="fas fa-chevron-down text-black-2 ng-star-inserted"
                              aria-hidden="true"
                              (click)="togglePolicyAndProcedure(i, true)"
                            ></i>
                          </ng-container>
                          <ng-template #nodata>
                            <div style="margin-left: 12px;"></div>
                          </ng-template>
                        </div>

                        <div *ngIf="policyAndProcedurePolicies[i]" class="additional-data-row">
                          <div *ngFor="let policyName of item?.policy_names; let first = first">
                            <h5
                              [style.margin-top.px]="first ? -10 : 0"
                              class="font-weight-normal text-black-2 additional-info"
                              translate
                            >
                              {{ policyName.name }}
                            </h5>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </mat-tab>

                  <mat-tab style="font-weight: 600; width: 30%;" label="Procedures">
                    <div class="tab-content">
                      <ng-container *ngFor="let item of policyData; let i = index">
                        <div class="policy-item">
                          <div style="font-size: 14px; width: 50%" class="text-black-2 ">
                            <abbr title="{{ item.procedure_doc?.name }}">
                              <h5
                                style="width: 101% !important;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap"
                              >
                                {{ item.procedure_doc?.name }}
                              </h5>
                            </abbr>
                            <div *ngIf="countNonEmptyNames(item.procedure_names) > 0">
                              <span
                                style="display: flex; color: gray; font-size: 13px; width: 119%"
                                *ngIf="
                                  !showPolicyAndProcedureDetails(i, false) ||
                                  (!item.procedure_names?.length && hasNonEmptyNames(item.procedure_names))
                                "
                              >
                                {{ countNonEmptyNames(item.procedure_names) }}
                                {{
                                  countNonEmptyNames(item.procedure_names) === 1
                                    ? 'relevant section'
                                    : 'relevant sections'
                                }}
                              </span>
                            </div>
                          </div>
                          <div class="text-left w-140 ml-4">
                            <h4 class="text-black-1 dm-text-1 ">
                              <span *ngIf="item.procedure_doc?.status === 'Published'" class="published-dot"></span>
                              <span
                                *ngIf="item.procedure_doc?.status === 'Not Published'"
                                class="not-published-dot"
                              ></span>
                              {{ item.procedure_doc?.status }}
                            </h4>
                          </div>
                          <ng-container *ngIf="countNonEmptyNames(item.procedure_names) > 0; else nodata">
                            <i
                              *ngIf="showPolicyAndProcedureDetails(i, false) || !item.procedure_names?.length"
                              class="fas fa-chevron-up text-black-2 ng-star-inserted  cursor-pointer"
                              aria-hidden="true"
                              (click)="togglePolicyAndProcedure(i, false)"
                            ></i>
                            <i
                              *ngIf="!showPolicyAndProcedureDetails(i, false) || !item.procedure_names?.length"
                              class="fas fa-chevron-down text-black-2 ng-star-inserted cursor-pointer"
                              aria-hidden="true"
                              (click)="togglePolicyAndProcedure(i, false)"
                            ></i>
                          </ng-container>
                          <ng-template #nodata>
                            <div style="margin-left: 12px;"></div>
                          </ng-template>
                        </div>

                        <div *ngIf="policyAndProcedureProcedures[i]" class="additional-data-row">
                          <div *ngFor="let procedureName of item?.procedure_names; let first = first">
                            <h5
                              [style.margin-top.px]="first ? -10 : 0"
                              class="font-weight-normal text-black-2 additional-info"
                              translate
                            >
                              {{ procedureName.name }}
                            </h5>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </mat-tab>
                  <mat-tab label="Evidence">
                    <div class="d-flex px-4 pt-4">
                      <button
                        class="documents-btn"
                        [ngClass]="{
                          'documents-btn': true,
                          'documents-btn-active': selectedTab === 'documents'
                        }"
                        (click)="selectTab('documents')"
                      >
                        <img src="../../../assets/images/core/document-icon.svg" /> Documents ({{
                          EvidenceList?.length
                        }})
                      </button>
                      <button
                        class="test-btn"
                        [ngClass]="{
                          'test-btn': true,
                          'test-btn-active': selectedTab === 'tests'
                        }"
                        (click)="selectTab('tests')"
                      >
                        <img src="../../../assets/images/core/cc-cloud.svg" /> Tests ({{ EvidenceTestList?.length }})
                      </button>
                    </div>

                    <div class="evidence">
                      <table class="table table-hover evidence-table">
                        <thead class="bg-customs">
                          <tr class="t-head" *ngIf="selectedTab === 'documents'">
                            <th scope="col" class="t-head-data">NAME</th>
                            <th scope="col" class="t-head-data">FREQUENCY</th>
                            <th scope="col" class="t-head-data">MEETING MINUTES</th>
                            <th scope="col" class="t-head-data"></th>
                          </tr>
                          <tr class="t-head" *ngIf="selectedTab === 'tests'">
                            <th scope="col" class="t-head-data">NAME</th>
                            <th scope="col" class="t-head-data">CONNECTOR</th>
                            <th scope="col" class="t-head-data">SERVICES</th>
                            <th scope="col" class="t-head-data">STATUS</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="!isDataLoading">
                          <ng-container
                            *ngIf="selectedTab === 'documents' && EvidenceList?.length > 0; else NoEvidence"
                          >
                            <tr class="tr-custom" *ngFor="let item of EvidenceList">
                              <td class="truncated td-title">
                                <abbr title="{{ item.cb_task.name }}">{{ item.cb_task.name }}</abbr>
                              </td>
                              <td class="td-title">{{ item.cb_task.frequency }}</td>
                              <td>
                                <img
                                  class="mr-2 hw"
                                  src="../../../assets/images/core/evidence-icon.svg"
                                  (click)="changeAction('evidenceDetails', tabType); onEvidenceDetailsClick(item.guid)"
                                />
                              </td>
                              <td>
                                <img
                                  class="mr-2 hw"
                                  src="../../../assets/images/core/evidence-delete.png"
                                  (click)="removesingleEvidence(item)"
                                />
                              </td>
                            </tr>
                          </ng-container>
                          <ng-template #NoEvidence>
                            <tr *ngIf="EvidenceList?.length == 0 && selectedTab === 'documents'">
                              <td colspan="4" style="text-align: center; padding: 20px;">
                                <div>
                                  <img
                                    style="height:100px"
                                    class="mr-2"
                                    src="../../../assets/images/core/noEvidences.svg"
                                    alt="No Evidence"
                                  />
                                </div>
                                <span style="font-size: 16px; font-weight: 400; color: #002244;">
                                  No documents to display
                                </span>
                              </td>
                            </tr>
                          </ng-template>
                          <ng-container *ngIf="selectedTab === 'tests'">
                            <tr class="tr-custom" *ngFor="let item of EvidenceTestList">
                              <td class="truncated td-title">
                                <abbr title="{{ item.test_name }}">{{ item.test_name }}</abbr>
                              </td>
                              <td>
                                <img
                                  *ngIf="item.connector === 'GCP'"
                                  class="mr-2 "
                                  src="../../../assets/images/core/gcp.svg"
                                /><img
                                  *ngIf="item.connector === 'AWS'"
                                  class="mr-2 "
                                  src="../../../assets/images/core/aws.svg"
                                /><img
                                  *ngIf="item.connector === 'AZURE'"
                                  class="mr-2 "
                                  src="../../../assets/images/core/azure.svg"
                                />
                              </td>
                              <td class="td-title">
                                {{ item.service.charAt(0).toUpperCase() + item.service.slice(1) }}
                              </td>
                              <td class="td-title">
                                <img
                                  *ngIf="formatStatus(item.status) === 'Not Started'"
                                  class="mr-2 "
                                  src="../../../assets/images/core/notStartEllipse.svg"
                                /><img
                                  *ngIf="formatStatus(item.status) === 'Danger'"
                                  class="mr-2"
                                  src="../../../assets/images/core/ellipse-danger.svg"
                                /><img
                                  *ngIf="formatStatus(item.status) === 'Compliant'"
                                  class="mr-2 "
                                  src="../../../assets/images/core/ellipse-commplaint.svg"
                                /><img
                                  *ngIf="formatStatus(item.status) === 'Warning'"
                                  class="mr-2 "
                                  src="../../../assets/images/core/warningEllipse.svg"
                                />{{ formatStatus(item.status) }}
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </mat-tab>

                  <mat-tab label="History">
                    <div style="height: 500px;overflow-x: scroll;padding-top: 19px;width: 550px;">
                      <div *ngFor="let user of historyLog; let i = index">
                        <ng-container *ngIf="user.action == 'ADD_PROCEDURE' || user.action == 'UPDATE_PROCEDURE'">
                          <span
                            style="margin-left: 20px !important; margin-top: 30px; color: white;"
                            class="i-circle mr-2 font-weight-bold"
                            >{{ getInitials(user.action_taken_by) }}</span
                          >
                          <span class="font-weight-bold" style="color:  #415366; font-size: 14px;">{{
                            user.action_taken_by
                          }}</span>
                          <span style="margin-left: 5px;" class="history-tags">attached Procedure </span>
                          <span style="float: right;">{{ user.created_timestamp | date: 'dd:mm a, dd/MM/yyyy' }}</span>
                          <div class="history-CSS ">
                            <abbr title="{{ user.action_log.procedure.title }}">
                              <h6
                                style="width: 170%!important;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;"
                                class="history-tags"
                              >
                                {{ user.action_log.procedure.title }}
                              </h6>
                            </abbr>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="user.action == 'REMOVE_PROCEDURE'">
                          <span
                            style="margin-left: 20px !important; margin-top: 30px; color: white;"
                            class="i-circle  mr-2 font-weight-bold"
                            >{{ getInitials(user.action_taken_by) }}</span
                          >
                          <span class="font-weight-bold" style="color: #415366; font-size: 14px;">{{
                            user.action_taken_by
                          }}</span>
                          <span style="margin-left: 5px;" class="history-tags">removed Procedure</span>
                          <span style="float: right;">{{ user.created_timestamp | date: 'dd:mm a, dd/MM/yyyy' }}</span>
                          <div class="history-CSS ">
                            <abbr title="{{ user.action_log.procedure.title }}">
                              <h6
                                style="width: 170%!important;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;"
                                class="history-tags"
                              >
                                {{ user.action_log.procedure.title }}
                              </h6>
                            </abbr>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="user.action == 'PUBLISH_DOCUMENT' || user.action == 'UPDATE_DOCUMENT'">
                          <span
                            style="margin-left: 20px !important; margin-top: 30px; color: white;"
                            class="i-circle  mr-2 font-weight-bold"
                            >{{ getInitials(user.action_taken_by) }}</span
                          >
                          <span class="font-weight-bold" style="color: #415366; font-size: 14px;">{{
                            user.action_taken_by
                          }}</span>
                          <span style="margin-left: 5px;" class="history-tags"
                            >{{ user.action == 'PUBLISH_DOCUMENT' ? 'published' : 'created new version of ' }}
                            {{ user.action_log.policy ? 'Policy document' : 'Procedure document' }}</span
                          >
                          <span style="float: right;">{{ user.created_timestamp | date: 'dd:mm a, dd/MM/yyyy' }}</span>
                          <div class="history-CSS ">
                            <abbr
                              title="{{
                                user.action_log.policy ? user.action_log.policy.title : user.action_log.procedure.title
                              }}"
                            >
                              <h6
                                style="width: 170%!important;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;"
                                class="history-tags"
                              >
                                {{
                                  user.action_log.policy
                                    ? user.action_log.policy.title
                                    : user.action_log.procedure.title
                                }}
                              </h6>
                            </abbr>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="user.action == 'ADD_POLICY' || user.action == 'UPDATE_POLICY'">
                          <span
                            style="margin-left: 20px !important; margin-top: 30px; color: white;"
                            class="i-circle mr-2 font-weight-bold"
                            >{{ getInitials(user.action_taken_by) }}</span
                          >
                          <span class="font-weight-bold" style="color: #415366; font-size: 14px;">{{
                            user.action_taken_by
                          }}</span>
                          <span style="margin-left: 5px;" class="history-tags">attached Policy</span>
                          <span style="float: right;">{{ user.created_timestamp | date: 'dd:mm a, dd/MM/yyyy' }}</span>
                          <div class="history-CSS ">
                            <abbr title="{{ user.action_log.policy.title }}">
                              <h6
                                style="width: 170%!important;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;"
                                class="history-tags"
                              >
                                {{ user.action_log.policy.title }}
                              </h6>
                            </abbr>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="user.action == 'REMOVE_POLICY'">
                          <span
                            style="margin-left: 20px !important; margin-top: 30px; color: white;"
                            class="i-circle  mr-2 font-weight-bold"
                            >{{ getInitials(user.action_taken_by) }}</span
                          >
                          <span class="font-weight-bold" style="color: #415366; font-size: 14px;">{{
                            user.action_taken_by
                          }}</span>
                          <span style="margin-left: 5px;" class="history-tags">removed Policy</span>
                          <span style="float: right;">{{ user.created_timestamp | date: 'dd:mm a, dd/MM/yyyy' }}</span>
                          <div class="history-CSS ">
                            <abbr title="{{ user.action_log.policy.title }}">
                              <h6
                                style="width: 170%!important;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;"
                                class="history-tags"
                              >
                                {{ user.action_log.policy.title }}
                              </h6>
                            </abbr>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="user.action == 'ADD_EVIDENCE'">
                          <div *ngFor="let item of user.action_log.evidence; let i = index">
                            <span
                              style="margin-left: 20px !important; margin-top: 30px; color: white;"
                              class="i-circle mr-2 font-weight-bold"
                              >{{ getInitials(user.action_taken_by) }}</span
                            >
                            <span class="font-weight-bold" style="color: #415366; font-size: 14px;">{{
                              user.action_taken_by
                            }}</span>
                            <span style="margin-left: 5px;" class="history-tags">attached Evidence</span>
                            <span class="font-weight-bold" style="margin-left: 5px; color: #415366;"></span>
                            <span style="float: right;">{{
                              user.created_timestamp | date: 'dd:mm a, dd/MM/yyyy'
                            }}</span>
                            <div class="history-CSS ">
                              <abbr title="{{ item.cb_task_name }}">
                                <h6
                                  style="width: 170%!important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;"
                                  class="history-tags"
                                >
                                  {{ item.cb_task_name }}
                                </h6>
                              </abbr>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="user.action == 'REMOVE_EVIDENCE'">
                          <div *ngFor="let item of user.action_log.evidence; let i = index">
                            <span
                              style="margin-left: 20px !important; margin-top: 30px; color: white;"
                              class="i-circle mr-2 font-weight-bold"
                              >{{ getInitials(user.action_taken_by) }}</span
                            >
                            <span class="font-weight-bold" style="color: #415366; font-size: 14px;">{{
                              user.action_taken_by
                            }}</span>
                            <span style="margin-left: 5px;" class="history-tags">removed Evidence</span>
                            <span style="float: right;">{{
                              user.created_timestamp | date: 'dd:mm a, dd/MM/yyyy'
                            }}</span>
                            <div class="history-CSS">
                              <abbr title="{{ item.cb_task_name }}">
                                <h6
                                  style="width: 170%!important;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;"
                                  class="history-tags"
                                >
                                  {{ item.cb_task_name }}
                                </h6>
                              </abbr>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="user.action == 'UPDATE_STATUS'">
                          <div
                            style=" align-items: center; margin-left: 29px;
                        "
                          >
                            <span class="i-circlelogo text-black-1 mr-2 font-weight-bold">
                              <img
                                src="../../../../assets/images/core/cb_logo.png"
                                class="cb-logo-top"
                                style="height: 18px; width: 16px;"
                              />
                            </span>
                            <span class="font-weight-bold" style="font-size: 14px; color: #415366; font-weight: 600;">
                              ComplainceBot
                            </span>
                            <span style=" margin-left: 2px; margin-right: 3px;" class="history-tags">
                              changed Status
                            </span>
                            <span style="float: right;">{{
                              user.created_timestamp | date: 'hh:mm a, dd/MM/yyyy'
                            }}</span>
                          </div>
                          <div
                            style="margin-left: 65px;
                          padding-top: 5px;
                          padding-bottom: 25px;
                      "
                          >
                            <span
                              class="rounded-pill p-1 px-2 text-center history-tags"
                              [ngStyle]="
                                getStyle(
                                  user.action_log.old_status.overall_status
                                    ? user.action_log.old_status.overall_status
                                    : user.action_log.old_status
                                )
                              "
                            >
                              {{
                                user.action_log.old_status.overall_status
                                  ? user.action_log.old_status.overall_status
                                  : (user.action_log.old_status | titlecase)
                              }}</span
                            >
                            <mat-icon
                              style="position: relative;
                            top: 7px;
                            margin-left: 10px;
                            margin-right: 10px;
                        "
                              >arrow_right_alt</mat-icon
                            >
                            <span
                              class="rounded-pill p-1 px-2 text-center history-tags"
                              [ngStyle]="
                                getStyle(
                                  user.action_log.new_status.overall_status
                                    ? user.action_log.new_status.overall_status
                                    : user.action_log.new_status
                                )
                              "
                            >
                              {{
                                user.action_log.new_status.overall_status
                                  ? user.action_log.new_status.overall_status
                                  : (user.action_log.new_status | titlecase)
                              }}</span
                            >
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>

                <div
                  style="position: fixed;
                bottom: 0;
                width: 560px;
                z-index: 2;
             background: white;
              display: flex;
       justify-content: flex-end;
"
                  *ngIf="tabName != 'History'"
                  class="container"
                >
                  <div class="button" *ngIf="selectedTab != 'tests'">
                    <button
                      type="button"
                      class="btn-remove ml-4"
                      (click)="
                        removeitem(tabName, selectedRowData);
                        tabName == 'Evidence'
                          ? changeAction('removeEvidence', tabName)
                          : changeAction('remove', tabName)
                      "
                      ngbAutoFocus
                      translate
                      style="min-width: 151px;"
                      *ngIf="tabName != 'Evidence'"
                    >
                      Remove {{ tabName }}
                    </button>

                    <button
                      type="button"
                      class="btn save-btn ml-4"
                      (click)="attachItem(tabName); changeAction(tabName, tabName)"
                      ngbAutoFocus
                      translate
                      style="min-width: 165px;"
                    >
                      Attach {{ tabName }}
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="actionType == 'Policies' || actionType == 'Procedures'">
              <div class="side-nav-content">
                <div style="display: flex; align-items: center;">
                  <div class="control-header-div">
                    <i
                      class="fas fa-arrow-left"
                      style="height: 32px;width: 32px;"
                      (click)="changeAction('controlOverview', tabName)"
                    ></i>

                    <h3
                      class="control-header"
                      translate
                      style="    margin-left: 25px;
                    margin-top: -35px;"
                    >
                      {{ tabType === 'Policies' ? 'Attach Policies' : 'Attach Procedures' }}
                    </h3>
                  </div>
                </div>

                <div class="search-people position-relative grc-search">
                  <div style="position: relative; width: 528px; height: 48px;" class="input-group w-330">
                    <input
                      id="search"
                      [(ngModel)]="searchText"
                      class="form-control cb-input"
                      placeholder="{{
                        tabType === 'Policies' ? ' Search policies by name' : 'Search procedures by name'
                      }}"
                      style="padding-bottom:10px"
                      name="search"
                      type="text"
                      (keyup)="updateSearchText($event)"
                      (keydown.enter)="search(searchText, tabType)"
                      #titleInput
                    />
                    <i
                      *ngIf="searchText"
                      style="color: #827b7b;"
                      (click)="clearSearch()"
                      class="fa fa-times search-icon grc-icon"
                    ></i>
                    <i
                      style="color: #827b7b;"
                      *ngIf="!searchText"
                      (click)="search(titleInput.value)"
                      class="fa fa-search search-icon grc-icon"
                    ></i>
                  </div>
                </div>

                <h2 *ngIf="isDataLoading" class="font-weight-bold scroll-center">
                  <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': isDataLoading }" style="font-size:large;"></i>
                  &nbsp;
                </h2>
                <table class="w-100p bg-white dm-table table table-responsive custom-table">
                  <thead
                    style="width: 528px;
                  height: 49px;
                  border: 0px 0px 1px 0px;
                  padding: 16px 0px 16px 0px;
                  gap: 16px;
                  border-bottom: 1px solid #CFCFCF;"
                  >
                    <tr>
                      <th style="font-weight:600;font-size: 14px; color: #101010;">
                        {{ tabType === 'Policies' ? 'Policy Name' : 'Procedure Name' }}
                      </th>
                      <th
                        style="font-weight:600;font-size: 14px; color: #101010;
                        padding-left: 123px;"
                      >
                        Status
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <div
                    class="tab-content-side-nav search-results-policy"
                    infiniteScroll
                    [scrollWindow]="false"
                    (scrolled)="onScrollPolicy()"
                    [infiniteScrollDistance]="modalScrollDistance"
                    [infiniteScrollThrottle]="modalScrollThrottle"
                    (scrolledUp)="onScrollUp()"
                  >
                    <tbody>
                      <ng-container *ngFor="let policy of addPolicyData">
                        <tr>
                          <td class="policy-title">
                            <abbr title="{{ policy?.title }}">
                              <h4 class="text-black-3 dm-text-1 mt-3 ">
                                {{ policy?.title }}
                              </h4>
                            </abbr>
                          </td>
                          <td>
                            <h4 class="text-black-1 dm-text-1 mt-3 ">
                              <span *ngIf="policy?.status === 'Published'" class="published-dot"></span>
                              <span *ngIf="policy?.status === 'Not Published'"></span>
                              {{ policy?.status }}
                            </h4>
                          </td>
                          <td style="width: 20%;">
                            <span
                              class="select-label text-black-1 dm-text-1 mt-3"
                              style="font-size: 14px; margin-left: 20px;"
                              (click)="
                                confirmPolicy(policy?.title, policy?.doc_version_guid, policy?.status);
                                changeAction('confirm', tabType)
                              "
                            >
                              Select
                              <i>&gt;</i>
                            </span>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                    <h2 *ngIf="scrollLoader" class="font-weight-bold">
                      <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i>
                      &nbsp;
                    </h2>
                  </div>
                </table>
              </div>
            </ng-container>
            <ng-container *ngIf="actionType == 'remove'">
              <div class="side-nav-content">
                <div class="control-header-div">
                  <i
                    class="fas fa-arrow-left"
                    style="height: 32px;width: 32px;"
                    (click)="changeAction('controlOverview', tabType)"
                  ></i>
                  <h3
                    style="    margin-left: 25px;
                  margin-top: -35px;"
                    class="control-header"
                    translate
                  >
                    {{ tabType === 'Policies' ? 'Remove Policies' : 'Remove Procedures' }}
                  </h3>
                </div>

                <hr style="width:100%;text-align:left;margin-top:0" />
                <div class="tab-content-side-nav-remove" style="overflow-x: auto; ">
                  <table>
                    <tbody>
                      <ng-container *ngIf="tabType === 'Policies'; else procedureContainer">
                        <ng-container *ngFor="let item of policyData; let j = index">
                          <tr>
                            <td>
                              <input
                                style="vertical-align: bottom;"
                                type="checkbox"
                                (change)="onPolicyProcedureSelect($event, item?.guid, tabType)"
                              />
                            </td>
                            <td class="doc-name">
                              <abbr title="{{ item.policy_doc?.name }}">
                                <h4 class="text-black-3 dm-text-1 mt-3 ">{{ item.policy_doc?.name }}</h4>
                              </abbr>
                            </td>
                            <td></td>
                            <td>
                              <h4
                                style="
                                color: #101010;
                                  margin-top: 19px;
                                  font-size: 14px !important;
                                  cursor: default;"
                              >
                                <span *ngIf="item.policy_doc?.status == 'Published'" class="published-dot"></span>
                                <span
                                  class="not-published-dot"
                                  *ngIf="item.policy_doc?.status === 'Not Published'"
                                ></span
                                >{{ item.policy_doc?.status }}
                              </h4>
                            </td>
                            <td *ngIf="countNonEmptyNames(item.policy_names) > 0" class="cursor-pointer">
                              <ng-container
                                *ngIf="showPolicyAndProcedureDetails(j, true) || !item.policy_names?.length"
                              >
                                <i
                                  class="fas fa-chevron-down text-black-2 ng-star-inserted"
                                  style="margin-top: 12px;"
                                  aria-hidden="true"
                                  (click)="togglePolicyAndProcedure(j, true)"
                                ></i>
                              </ng-container>
                              <ng-container
                                *ngIf="!showPolicyAndProcedureDetails(j, true) || !item.policy_names?.length"
                              >
                                <i
                                  class="fas fa-chevron-up text-black-2 ng-star-inserted"
                                  style="margin-top: 12px;"
                                  aria-hidden="true"
                                  (click)="togglePolicyAndProcedure(j, true)"
                                ></i>
                              </ng-container>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td *ngIf="countNonEmptyNames(item.policy_names) > 0">
                              <span
                                style="display: flex; color: gray; font-size: 13px;"
                                *ngIf="
                                  !showPolicyAndProcedureDetails(j, true) ||
                                  (!item.policy_names?.length && hasNonEmptyNames(item.policy_names))
                                "
                              >
                                {{ countNonEmptyNames(item.policy_names)
                                }}{{
                                  countNonEmptyNames(item.policy_names) === 1 ? 'relevant section' : 'relevant sections'
                                }}
                              </span>
                            </td>
                          </tr>
                          <tr class="additional-data-row" *ngIf="policyAndProcedurePolicies[j]">
                            <td></td>
                            <td>
                              <div class="data-row" *ngFor="let policyName of item?.policy_names">
                                <input
                                  *ngIf="countNonEmptyNames(item.policy_names) > 0"
                                  type="checkbox"
                                  (change)="onPolicyProcedureSelect($event, item?.guid, tabType, policyName.guid)"
                                />
                                <abbr title="{{ policyName?.name }}">
                                  <span style=" margin-left: 9px; " class="additional-info">{{
                                    policyName?.name
                                  }}</span>
                                </abbr>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <ng-template #procedureContainer>
                        <ng-container *ngFor="let item of policyData; let i = index">
                          <tr>
                            <td>
                              <input
                                style="vertical-align: bottom;"
                                type="checkbox"
                                (change)="onPolicyProcedureSelect($event, item?.guid, tabType)"
                              />
                            </td>
                            <td class="doc-name">
                              <abbr title="{{ item.procedure_doc?.name }}">
                                <h4 class="text-black-3 dm-text-1 mt-3 ">{{ item.procedure_doc?.name }}</h4>
                              </abbr>
                            </td>
                            <td></td>
                            <td>
                              <h4
                                style="color: #101010; margin-top: 19px;  font-size: 14px !important;cursor: default;"
                              >
                                <span *ngIf="item.procedure_doc?.status == 'Published'" class="published-dot"></span>
                                <span
                                  *ngIf="item.procedure_doc?.status === 'Not Published'"
                                  class="not-published-dot"
                                ></span
                                >{{ item.procedure_doc?.status }}
                              </h4>
                            </td>
                            <td *ngIf="countNonEmptyNames(item.procedure_names) > 0" class="cursor-pointer">
                              <ng-container
                                *ngIf="showPolicyAndProcedureDetails(i, true) || !item.procedure_names?.length"
                              >
                                <i
                                  class="fas fa-chevron-down text-black-2 ng-star-inserted"
                                  style="margin-top: 12px;"
                                  aria-hidden="true"
                                  (click)="togglePolicyAndProcedure(i, true)"
                                ></i>
                              </ng-container>
                              <ng-container
                                *ngIf="!showPolicyAndProcedureDetails(i, true) || !item.procedure_names?.length"
                              >
                                <i
                                  class="fas fa-chevron-up text-black-2 ng-star-inserted"
                                  style="margin-top: 12px;"
                                  aria-hidden="true"
                                  (click)="togglePolicyAndProcedure(i, true)"
                                ></i>
                              </ng-container>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td *ngIf="countNonEmptyNames(item.procedure_names) > 0">
                              <span
                                style="display: flex; color: gray; font-size: 13px;"
                                *ngIf="
                                  !showPolicyAndProcedureDetails(i, true) ||
                                  (!item.procedure_names?.length && hasNonEmptyNames(item.procedure_names))
                                "
                              >
                                {{ countNonEmptyNames(item.procedure_names) }}
                                {{
                                  countNonEmptyNames(item.procedure_names) === 1
                                    ? 'relevant section'
                                    : 'relevant sections'
                                }}
                              </span>
                            </td>
                            <!-- <td *ngIf="countNonEmptyNames(item.procedure_names) == 0">
                              <span style="display: flex; color: gray; font-size: 13px;">
                                No Applicable Procedure
                              </span>
                            </td> -->
                          </tr>
                          <tr class="additional-data-row" *ngIf="policyAndProcedurePolicies[i]">
                            <td></td>
                            <td>
                              <div class="data-row" *ngFor="let policyName of item?.procedure_names">
                                <input
                                  *ngIf="countNonEmptyNames(item.procedure_names) > 0"
                                  type="checkbox"
                                  (change)="onPolicyProcedureSelect($event, item?.guid, tabType, policyName.guid)"
                                />
                                <abbr title="{{ policyName?.name }}">
                                  <span style=" margin-left: 9px; " class="additional-info">{{
                                    policyName?.name
                                  }}</span>
                                </abbr>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </ng-template>
                    </tbody>
                  </table>
                </div>

                <div
                  style="position: fixed;
                bottom: 0;
                width: 560px;
                 z-index: 2;
    background: white;"
                  class="container"
                >
                  <div class="selected-checkbox">
                    <span> {{ selectedPolicyAndProcedureGuids.length }} Selected </span>
                  </div>
                  <div class="button">
                    <button
                      type="button"
                      class="btn btn-cancel ml-4"
                      (click)="changeAction('controlOverview', tabType)"
                      ngbAutoFocus
                      translate
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn save-btn ml-4"
                      (click)="deletePolicyAndProcedure(tabType, selectedRowData)"
                      [disabled]="selectedPolicyAndProcedureGuids.length == 0"
                      [ngStyle]="{
                        'background-color': selectedPolicyAndProcedureGuids.length === 0 ? 'rgba(0,0,0,.26)' : '#2a647c'
                      }"
                      ngbAutoFocus
                      translate
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="actionType == 'Evidence'">
              <div class="side-nav-content">
                <div class="control-header-div">
                  <i
                    class="fas fa-arrow-left"
                    style="height: 32px;width: 32px;"
                    (click)="changeAction('controlOverview', tabName)"
                  ></i>
                  <h3
                    class="control-header"
                    translate
                    style="    margin-left: 25px;
                  margin-top: -35px;"
                    translate
                  >
                    Attach Evidence
                  </h3>
                </div>

                <div class="search-people position-relative  grc-search">
                  <div style="position: relative; width: 528px; height: 48px;" class="input-group w-330">
                    <input
                      id="search"
                      [(ngModel)]="searchText"
                      class="form-control cb-input"
                      placeholder="Search task by name"
                      name="search"
                      type="text"
                      (keyup)="updateSearchText($event)"
                      (keyDown.enter)="search(searchText, tabType)"
                      #titleInput
                    />
                    <i (click)="search(titleInput.value)" class="fa fa-search search-icon grc-icon"></i>
                  </div>
                </div>
                <div
                  class="tab-content-side-nav-evidence search-results"
                  infiniteScroll
                  [scrollWindow]="false"
                  (scrolled)="onScroll()"
                  [infiniteScrollDistance]="modalScrollDistance"
                  [infiniteScrollThrottle]="modalScrollThrottle"
                  (scrolledUp)="onScrollUp()"
                >
                  <h2 *ngIf="isDataLoading" class="font-weight-bold scroll-center">
                    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': isDataLoading }" style="font-size:large;"></i>
                    &nbsp;
                  </h2>
                  <table>
                    <thead>
                      <tr style="border-bottom: 1px solid #ccc;  padding: 16px, 0px, 16px, 24px;">
                        <th></th>
                        <th
                          style="width: 328px; height: 17px; font:'Inter'; font-size:14px;color: #101010 ;padding-bottom: 10px;"
                        >
                          Task name
                        </th>
                        <th></th>
                        <th
                          style="width: 328px; height: 17px; font:'Inter'; font-size:14px;color:#101010;padding-bottom: 10px;"
                        >
                          Frequency
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let item of addEvidenceData; let i = index">
                        <tr>
                          <td>
                            <input
                              style="vertical-align: bottom;"
                              type="checkbox"
                              (change)="onCheckboxChange($event, item.review_event_guid)"
                            />
                          </td>
                          <td class="task-meeting-name">
                            <abbr title="{{ item.meeting_name }}">
                              <h4 class="text-black-4 dm-text-1 mt-3 " style="font-size: 14px; color: #101010;">
                                {{ item.meeting_name }}
                              </h4>
                            </abbr>
                          </td>
                          <td></td>
                          <td style="font-size: 14px; color: #101010;">{{ item.schedule?.event_schedule }}</td>
                          <td *ngIf="item.committee_name?.length > 0" class="cursor-pointer">
                            <ng-container *ngIf="shouldShowCommiteeDetails(i)">
                              <i
                                class="fas fa-chevron-up text-black-2 ng-star-inserted"
                                aria-hidden="true"
                                (click)="toggleEvidence(i)"
                              ></i>
                            </ng-container>
                            <ng-container *ngIf="!shouldShowCommiteeDetails(i)">
                              <i
                                class="fas fa-chevron-down text-black-2 ng-star-inserted"
                                aria-hidden="true"
                                (click)="toggleEvidence(i)"
                              ></i>
                            </ng-container>
                          </td>
                        </tr>
                        <tr class="additional-data-row" *ngIf="shouldShowCommiteeDetails(i)">
                          <td></td>
                          <td class="committee-details">
                            <abbr title="{{ committeeName[i] }}">
                              <span>Committee:</span>
                              <div
                                style="font-size: 10px;  overflow: hidden;white-space: nowrap;
                              text-overflow: ellipsis;"
                              >
                                {{ committeeName[i] }}
                              </div>
                            </abbr>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  &nbsp;
                  <h2 *ngIf="scrollLoader" class="font-weight-bold">
                    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i>
                    &nbsp;
                  </h2>
                </div>

                <div
                  style="position: fixed;
                bottom: 0;
                width: 560px;
                z-index: 2;
    background: white;"
                  class="container"
                >
                  <div class="selected-checkbox">
                    <span> {{ this.selectedEvidenceValues.length }} Selected </span>
                  </div>

                  <div class="button">
                    <button
                      type="button"
                      style="width: 111px !important"
                      class="btn-cancel ml-4 float-right"
                      (click)="changeAction('controlOverview', tabType)"
                      ngbAutoFocus
                      translate
                    >
                      Cancel
                    </button>
                    <button
                      *ngIf="!isLoadingButton"
                      type="button"
                      class="btn save-btn ml-4 float-right"
                      (click)="addEvidences(selectedRowData)"
                      [disabled]="selectedEvidenceValues.length === 0"
                      [ngStyle]="{
                        'background-color': selectedEvidenceValues.length === 0 ? 'rgba(0,0,0,.26)' : '#2a647c'
                      }"
                      ngbAutoFocus
                      translate
                    >
                      Save
                    </button>
                    <button class="btn save-btn ml-4 float-right" disabled *ngIf="isLoadingButton">
                      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="actionType == 'removeEvidence'">
              <div class="side-nav-content">
                <div class="control-header-div">
                  <i
                    class="fas fa-arrow-left"
                    style="height: 32px;width: 32px;"
                    (click)="changeAction('controlOverview', tabName)"
                  ></i>
                  <h3
                    class="control-header"
                    translate
                    style="    margin-left: 25px;
                  margin-top: -35px;"
                    translate
                  >
                    Remove Evidence
                  </h3>
                </div>
                <div class="tab-content-side-nav-evidence" style="padding: 12px 16px 12px 16px !important;">
                  <table>
                    <tbody>
                      <ng-container *ngFor="let item of EvidenceList; let i = index">
                        <tr>
                          <td style="width: 5%;">
                            <input
                              style="vertical-align: bottom; "
                              type="checkbox"
                              (change)="onCheckboxChange($event, item.cb_task.review_event_guid)"
                            />
                          </td>
                          <td class="task-meeting-name">
                            <abbr title="{{ item.cb_task.name }}">
                              <h4 class="text-black-4 dm-text-1 mt-3 ">{{ item.cb_task.name }}</h4>
                            </abbr>
                          </td>
                          <td></td>
                          <td>
                            <h4 class="text-black-4 dm-text-1 mt-3 ">{{ item.cb_task.frequency }}</h4>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>

                <div
                  style="position: fixed;
                bottom: 0;
                width: 560px;
              z-index: 2;
    background: white;"
                  class="container"
                >
                  <div class="selected-checkbox">
                    <span> {{ this.selectedEvidenceValues.length }} Selected </span>
                  </div>
                  <div class="button">
                    <button
                      type="button"
                      style="width: 111px !important"
                      class="btn-cancel ml-4 float-right"
                      (click)="changeAction('controlOverview', tabType)"
                      ngbAutoFocus
                      translate
                    >
                      Cancel
                    </button>
                    <button
                      *ngIf="!isLoadingButton"
                      type="button"
                      class="btn save-btn ml-4 float-right"
                      (click)="removeEvidence(selectedRowData)"
                      [disabled]="selectedEvidenceValues.length === 0"
                      [ngStyle]="{
                        'background-color': selectedEvidenceValues.length === 0 ? 'rgba(0,0,0,.26)' : '#2a647c'
                      }"
                      ngbAutoFocus
                      translate
                    >
                      Remove
                    </button>
                    <button class="btn save-btn ml-4 float-right" disabled *ngIf="isLoadingButton">
                      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="actionType == 'evidenceDetails'">
              <div class="side-nav-content">
                <div style="width: 560px;height: 602px; ">
                  <div class="control-header-div">
                    <i
                      class="fas fa-arrow-left"
                      style="height: 32px;width: 32px;"
                      (click)="changeAction('controlOverview', tabName)"
                    ></i>
                    <h3
                      style="    margin-left: 25px;
                  margin-top: -35px;"
                      class="control-header"
                      translate
                    >
                      Evidence Details <span>( Today, {{ currentDate | date: 'd MMM yyyy' }})</span>
                    </h3>
                  </div>

                  <div style="width: 560px;height: 203px; " class="tab-content">
                    <div
                      style="display: flex; margin-left: -30px;
                  margin-top: -25px;"
                    >
                      <div class="column control-label">
                        <label class="label-control ">
                          Evidence Name
                        </label>
                        <h5
                          style="width: 23%!important;
                        overflow: hidden;
                        text-overflow: ellipsis; white-space: nowrap;"
                        >
                          {{ evidenceDetailsData?.evidence_name }}
                        </h5>
                      </div>
                      <div class="column control-label">
                        <label class="label-control ">
                          Frequency
                        </label>
                        <h5>{{ evidenceDetailsData?.frequency }}</h5>
                      </div>
                    </div>
                    <div class="status">
                      <label class="label-control">
                        Status
                      </label>
                      <h5 *ngIf="evidenceDetailsData?.status; else noStatus">{{ evidenceDetailsData.status }}</h5>
                      <ng-template #noStatus>
                        <p>No status available</p>
                      </ng-template>
                    </div>
                    <div class="committee">
                      <label class="label-control">
                        Committee
                      </label>
                      <h5>
                        <ng-container
                          *ngIf="
                            evidenceDetailsData?.committee_members && evidenceDetailsData?.committee_members.length > 0;
                            else noMembers
                          "
                        >
                          {{ evidenceDetailsData.committee_members.join(', ') }}
                        </ng-container>
                        <ng-template #noMembers>
                          No committee members available.
                        </ng-template>
                      </h5>
                    </div>
                  </div>
                  <div *ngIf="evidenceDetailsData?.evidence_history.length > 0" class="meeting-label">
                    <label class="meeting-min" translate>
                      Meeting Minutes
                    </label>
                  </div>
                  <div style="width: 560px;height: 500px !important;overflow: scroll;padding: 16px;gap:8px">
                    <h2 *ngIf="isDataLoading" class="font-weight-bold scroll-center">
                      <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': isDataLoading }" style="font-size:large;"></i>
                      &nbsp;
                    </h2>
                    <ng-container *ngFor="let event of evidenceDetailsData?.evidence_history; let last = last">
                      <div style="display: flex; align-items: center;">
                        <span class="i-circlelogo text-black-1 mr-2 font-weight-bold">
                          <img
                            src="../../../../assets/images/core/cb_logo.png"
                            class="cb-logo-top"
                            style="height: 18px; width: 16px;"
                          />
                        </span>
                        <span class="font-weight-bold" style="font-size: 12px; color: #101010; font-weight: 600;">
                          ComplainceBot
                        </span>
                        <span style="font-size: 12px; color: black; margin-left: 2px;">
                          updated
                        </span>
                        <abbr title="{{ event.review_event }}">
                          <label
                            class="font-weight-bold"
                            style="
                              font-size: 12px;
                              color: #101010;
                              font-weight: 600;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              margin-top: 14px;
                           margin-left: 2px;
                              max-width: 200px !important
                            ;
                          "
                          >
                            {{ event.review_event }}
                          </label>
                        </abbr>
                        <span style="font-size: 10px; margin-left: auto; color: #101010;">
                          {{ event.startTime | date: 'hh:mm a, dd/MM/yy' }}
                        </span>
                      </div>
                      <!-- </div> -->
                      <span style="font-size: 14px; display: block; margin-top: 12px; color: #101010">
                        Task Status:
                        <span *ngIf="evidenceDetailsData?.status; else noStatus">{{
                          evidenceDetailsData?.status
                        }}</span>
                        <ng-template #noStatus>
                          <span>No status available</span>
                        </ng-template>
                      </span>
                      <div
                        *ngIf="event.mom_link"
                        style="border: 1px solid #ccc; padding: 12px; margin-top: 20px; width: 528px;
                      height: 81px;
                      border-radius: 8px;"
                      >
                        <div style="display: flex; align-items: center; ">
                          <img
                            src="../../../../assets/images/core/PDFfileicon.png"
                            style="width: 39.1px;
                            height: 48px;"
                          />
                          <div style="padding-left:10px">
                            <span style="font-size: 14px; font-weight: bold;"
                              >Meeting Minutes_{{ event.startTime | date: 'dd MMM yy' }}</span
                            >
                            <span style=" display: block; margin-top: 0px; color: #666666; font-size: 10px;">
                              <span
                                style="font-weight: 600;
                             font-size: 10px;
                             color: #666666;"
                                >Download</span
                              >
                              and Open to View the Document
                            </span>
                          </div>
                          <button
                            style="margin-left:6.5em; margin-top: 0px;"
                            type="button"
                            class="btn btn-select"
                            (click)="downloadpdf(event.mom_link, event.startTime)"
                            ngbAutoFocus
                            translate
                          >
                            <i
                              class="fas fa-arrow-down download-icon cursor-pointer"
                              style="height: 11.33px; width: 9.33px; color: #FFFFFF; position: relative; left: -5px;"
                            ></i>
                            Download
                          </button>
                        </div>
                      </div>
                      <br *ngIf="!last" />
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="actionType == 'confirm'">
              <div class="side-nav-content">
                <div class="control-header-div">
                  <i
                    class="fas fa-arrow-left"
                    style="height: 32px;width: 32px;"
                    (click)="changeAction(tabName, tabName)"
                  ></i>
                  <h3
                    style="margin-top: 5px;margin-left: 17px;"
                    class="control-header"
                    translate
                    style="    margin-left: 25px;
                    margin-top: -35px;"
                  >
                    Confirm {{ tabType === 'Policies' ? 'Policy' : 'Procedure' }}
                  </h3>
                </div>

                <div style="width: 560px;height: 69px;margin-left: -12px;gap: 20px;" class="row">
                  <div class="column control-label" style="width: 50%;" translate>
                    <label class="label-control">{{ tabType === 'Policies' ? 'Policy Name' : 'Procedure Name' }}</label>
                    <abbr title="{{ confirmPolicyData['title'] }}">
                      <h5
                        style="width: 100%!important;
                    overflow: hidden;
                    text-overflow: ellipsis; white-space: nowrap;"
                      >
                        {{ confirmPolicyData['title'] }}
                      </h5>
                    </abbr>
                  </div>

                  <div class="column status-label" translate>
                    <label class="label-control">Status</label>
                    <h5>{{ confirmPolicyData['status'] }}</h5>
                  </div>
                </div>

                <div style="width: 560px;height: 371px; margin-top:45px">
                  <div style="width: 528px;height: 19px;margin-top: 10px;margin-bottom: 10px;">
                    <h5 class="confirm-tab " translate>
                      Relevant Section Name(optional)
                    </h5>
                  </div>
                  <div style="height: 650px; overflow-x: scroll;">
                    <div class="search-people position-relative">
                      <ng-container *ngFor="let inputValue of inputValues; let i = index">
                        <div class="group-input w-330">
                          <input
                            id="section-confirm"
                            [(ngModel)]="inputValue.value"
                            class="cb-input input-confirm"
                            placeholder="Enter relevant section name"
                            type="text"
                            maxlength="256"
                            (ngModelChange)="checkInputValues($event)"
                          />
                          <i
                            class="fas fa-times cross-icon col-1"
                            style="font-size: 15px;"
                            (click)="removeInputBox(i)"
                          ></i>
                        </div>
                        <div class="col-12 error-mssg" *ngIf="inputValue.value.length >= 256">
                          <label class="text-danger">* You have reached the limit of 256 characters</label>
                        </div>
                      </ng-container>
                    </div>
                    <div
                      style="font-size: 12px;
                    margin-left: 27px;"
                      *ngIf="isDuplicateText"
                      class="text-danger"
                    >
                      *Please enter a unique name.
                    </div>

                    <button
                      style="width:90px ;"
                      type="button"
                      class="btn-add add-policy"
                      [disabled]="(isButtonDisabled() || isDuplicateText) && inputValues.length !== 0"
                      [ngStyle]="{
                        color: (isButtonDisabled() || isDuplicateText) && inputValues.length !== 0 ? 'grey' : '#146be1',
                        cursor:
                          (isButtonDisabled() || isDuplicateText) && inputValues.length !== 0
                            ? 'not-allowed'
                            : 'pointer',
                        border:
                          (isButtonDisabled() || isDuplicateText) && inputValues.length !== 0
                            ? '1px solid gray'
                            : '1px solid #146be1'
                      }"
                      ngbAutoFocus
                      translate
                      ngbAutoFocus
                      translate
                      (click)="addInputBox()"
                    >
                      + Add
                    </button>
                  </div>
                </div>

                <div
                  style="position: fixed;
                bottom: 0;
                width: 560px;
                z-index: 2;
                 background: white; justify-content: flex-end;"
                  *ngIf="tabName != 'History'"
                  class="container"
                >
                  <div class="button">
                    <button
                      type="button"
                      class="btn-cancel ml-4 float-right"
                      (click)="changeAction(tabName, tabName)"
                      ngbAutoFocus
                      translate
                      style="min-width: 100px;"
                    >
                      Cancel
                    </button>
                    <button
                      *ngIf="!isLoadingButton"
                      type="button"
                      class="btn save-btn ml-4 float-right"
                      (click)="confirmPolicyProcedure(tabName, selectedRowData)"
                      ngbAutoFocus
                      [disabled]="isDuplicateText"
                      [ngStyle]="{
                        'background-color': isDuplicateText ? 'rgba(0,0,0,.26)' : '#2a647c'
                      }"
                      translate
                      style="min-width: 100px;"
                    >
                      Save
                    </button>
                    <button class="btn save-btn ml-4 float-right" disabled *ngIf="isLoadingButton">
                      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </mat-sidenav>
          <div class="dashboard-options"></div>
        </div>
      </div>
      <div class="update-text mx-3 my-2">
        Last updated:
        {{ (selectedDate | date: 'short') ? (selectedDate | date: 'short') : (currentDate | date: 'short') }}
      </div>
      <div class="mt-3 mx-3">
        <!-- program unit control status -->
        <div class="d-flex justify-content-between align-items-center">
          <div class=" d-inline-block control-chart-container ">
            <div class="mt-3 mx-2 control-title">CONTROL STATUS</div>
            <div [hidden]="!checkZero(overallSummary.pgm_unit_status) || loadChart">
              <div class="d-flex">
                <div class="control-chart mt-4 ml-2 position-relative">
                  <canvas id="controlChart"></canvas>
                  <div class="w-100p text-center position-absolute control-summary">
                    <div class="control-chart-subtitle">
                      PUBLISHED
                    </div>
                    <div class="control-chart-total-percentage">
                      {{
                        percentage(
                          overallSummary['pgm_unit_status']?.COMPLETED,
                          overallSummary['pgm_unit_status']?.total
                        )
                      }}
                      %
                    </div>
                  </div>
                </div>
                <div class="mt-4 ml-3 summary-block">
                  <div class="chart-sub">
                    TOTAL CONTROLS
                  </div>
                  <div class="chart-total">
                    {{ overallSummary['pgm_unit_status']?.total }}
                  </div>
                  <div class="mt-3">
                    <div class="legends">
                      <span class="published-dot"></span> Complete
                      <span class="legends-total"> {{ overallSummary['pgm_unit_status']?.COMPLETED }}</span>
                    </div>
                    <div class="legends">
                      <span class="not-published-dot"></span> Incomplete
                      <span class="legends-total">{{ overallSummary['pgm_unit_status']?.INCOMPLETE }}</span>
                    </div>
                    <div class="legends">
                      <span class="pending-dot"></span> Pending
                      <span class="legends-total">{{ overallSummary['pgm_unit_status']?.PENDING }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="no-data-block font-weight-lighter mb-3"
              *ngIf="!checkZero(overallSummary.pgm_unit_status) || loadChart"
            >
              <ng-template *ngIf="!checkZero(overallSummary.pgm_unit_status)" [ngTemplateOutlet]="MsgRef"></ng-template>
              <!-- <div [hidden]="!isdefaultDoc || loadChart" style="height: 160px;" class="row justify-content-center">
                <div class="col-5  mt-2">
                  <div class="donut-container">
                    <div class="donut">
                      <div class="shimmer"></div>
                    </div>
                  </div>
                </div>
                <div class="col-5 col-sm-6  mt-4">
                  <div class="line-container mb-2">
                    <div class="line-shimmer"></div>
                  </div>
                  <div class="line-container mb-2">
                    <div class="line-shimmer"></div>
                  </div>
                  <div class="line-container mb-2">
                    <div class="line-shimmer"></div>
                  </div>
                  <p class="chart-subtitle">
                    Uploading your Documents
                  </p>
                </div>
              </div> -->
            </div>
          </div>
          <div class=" ml-3 d-inline-block policy-chart-container ">
            <div class="mt-3 mx-3 control-title">POLICY STATUS</div>
            <div [hidden]="!checkZero(overallSummary.pgm_unit_status) || loadChart">
              <div class="d-flex ml-2">
                <div class="policy-chart mt-4 ml-2 position-relative">
                  <canvas id="policiesStatus"></canvas>
                  <div class="w-100p text-center position-absolute control-summary">
                    <div class="control-chart-subtitle" style="font-size: 11px;">
                      PUBLISHED
                    </div>
                    <div class="control-chart-total-percentage" style="font-size: 16px;">
                      {{
                        percentage(overallSummary['policy_status']?.published, overallSummary['policy_status']?.total)
                      }}
                      %
                    </div>
                  </div>
                </div>
                <div class="mt-4 ml-3 summary-block">
                  <div class="chart-sub">
                    TOTAL POLICIES
                  </div>
                  <div class="chart-total">
                    {{ overallSummary['policy_status']?.total }}
                  </div>
                  <div class="mt-3">
                    <div class="legends">
                      <span class="published-dot"></span> Published
                      <span class="legends-total"> {{ overallSummary['policy_status']?.published }}</span>
                    </div>
                    <div class="legends">
                      <span class="not-published-dot"></span> Not Published
                      <span class="legends-total">{{ overallSummary['policy_status']?.unpublished }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="no-data-block font-weight-lighter mb-3"
              *ngIf="!checkZero(overallSummary.policy_status) || loadChart"
            >
              <ng-template *ngIf="!checkZero(overallSummary.policy_status)" [ngTemplateOutlet]="MsgRef"></ng-template>
            </div>
          </div>
          <div class=" d-inline-block policy-chart-container rounded-0 border-left-0">
            <div class="mt-3 mx-3 control-title">PROCEDURES STATUS</div>
            <div [hidden]="!checkZero(overallSummary.pgm_unit_status) || loadChart">
              <div class="d-flex ml-2">
                <div class="policy-chart mt-4 ml-2 position-relative">
                  <canvas id="procedureStatus"></canvas>
                  <div class="w-100p text-center position-absolute control-summary">
                    <div class="control-chart-subtitle" style="font-size: 11px;">
                      PUBLISHED
                    </div>
                    <div class="control-chart-total-percentage" style="font-size: 16px;">
                      {{
                        percentage(
                          overallSummary['procedure_status']?.published,
                          overallSummary['procedure_status']?.total
                        )
                      }}
                      %
                    </div>
                  </div>
                </div>
                <div class="mt-4 ml-2 summary-block">
                  <div class="chart-sub">
                    TOTAL PROCEDURES
                  </div>
                  <div class="chart-total">
                    {{ overallSummary['procedure_status']?.total }}
                  </div>
                  <div class="mt-3">
                    <div class="legends">
                      <span class="published-dot"></span> Published
                      <span class="legends-total"> {{ overallSummary['procedure_status']?.published }}</span>
                    </div>
                    <div class="legends">
                      <span class="not-published-dot"></span> Not Published
                      <span class="legends-total">{{ overallSummary['procedure_status']?.unpublished }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="no-data-block font-weight-lighter mb-3"
              *ngIf="!checkZero(overallSummary.procedure_status) || loadChart"
            >
              <ng-template
                *ngIf="!checkZero(overallSummary.procedure_status)"
                [ngTemplateOutlet]="MsgRef"
              ></ng-template>
            </div>
          </div>
          <div class=" d-inline-block evidence-chart-container border-left-0 ">
            <div class="mt-3 mx-2 control-title">EVIDENCE STATUS</div>
            <div [hidden]="!checkZero(overallSummary.pgm_unit_status) || loadChart">
              <div class="d-flex ml-2">
                <div class="policy-chart mt-4 ml-2 position-relative">
                  <canvas id="evidenceStatus"></canvas>
                  <div class="w-100p text-center position-absolute control-summary">
                    <div class="control-chart-subtitle" style="font-size: 11px;">
                      COMPLIANT
                    </div>
                    <div class="control-chart-total-percentage" style="font-size: 16px;">
                      {{
                        percentage(
                          overallSummary['evidence_status']?.good_standing,
                          overallSummary['evidence_status']?.total
                        )
                      }}
                      %
                    </div>
                  </div>
                </div>
                <div class="mt-4 ml-4 summary-block">
                  <div class="chart-sub">
                    TOTAL EVIDENCES
                  </div>
                  <div class="chart-total">
                    {{ overallSummary['evidence_status']?.total }}
                  </div>
                  <div class="mt-2 d-flex justify-content-around">
                    <div class="">
                      <div class="legends">
                        <span class="published-dot"></span> Compliant
                        <span class="legends-total"> {{ overallSummary['evidence_status']?.good_standing }}</span>
                      </div>
                      <div class="legends">
                        <span class="not-started-dot"></span> Not Started
                        <span class="legends-total">{{ overallSummary['evidence_status']?.not_participated }}</span>
                      </div>
                    </div>
                    <div class=" ml-2">
                      <div class="legends">
                        <span class="danger-dot"></span> Danger
                        <span class="legends-total"> {{ overallSummary['evidence_status']?.danger }}</span>
                      </div>
                      <div class="legends">
                        <span class="warning-dot"></span> Warning
                        <span class="legends-total">{{ overallSummary['evidence_status']?.warning }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="no-data-block font-weight-lighter mb-3"
              *ngIf="!checkZero(overallSummary.evidence_status) || loadChart"
            >
              <ng-template *ngIf="!checkZero(overallSummary.evidence_status)" [ngTemplateOutlet]="MsgRef"></ng-template>
            </div>
          </div>
          <ng-template #MsgRef>
            <!-- <div *ngIf="!loadChart">
              No data available
            </div> -->
            <div
              *ngIf="loadChart"
              style="width: 100% !important;"
              class="font-weight-bold text-black-3 w-100 text-center position-absolute"
            >
              <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': loadChart }" style="font-size:large;"></i> &nbsp;
            </div>
          </ng-template>
        </div>
      </div>
      <div class="row justify-content-between align-items-center mx-4 mt-4 mb-2 ">
        <div style="font-size: 10px;" class="row ">
          <div style="width: 220px; display: grid;" class="mx-2">
            <mat-form-field appearance="outline">
              <mat-select
                [disabled]="historyEnabled || isdefaultDoc"
                [(ngModel)]="params.status"
                placeholder="All Status"
                (selectionChange)="applyFilters($event.value, 'status')"
              >
                <mat-option value="">All</mat-option>
                <mat-option *ngFor="let item of statuses" [value]="item.id">{{ item.title }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div style="width: 220px;" class="mx-4 ">
            <div class="row">
              <div class="search-container  ">
                <input
                  autocomplete="off"
                  placeholder="Search by Title"
                  style="border: 1px solid #e9e9e9;"
                  class="form-control py-2 search-input cb-input-focus"
                  type="search"
                  id="example-search-input"
                  [(ngModel)]="value"
                  (keyup.enter)="applyFilters(value, 'title')"
                  [disabled]="historyEnabled"
                />
                <span
                  class="input-group-append"
                  (click)="value = ''; applyFilters(value, 'title')"
                  [disabled]="historyEnabled"
                >
                  <i class="fa fa-search search-icon" *ngIf="!value"></i>
                  <i class="fa fa-times search-icon" *ngIf="value" (click)="clearSearch()"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div style=" display: flex; align-items: center;">
          <section class="example-section mx-2">
            <mat-checkbox
              style="height: 23px;"
              [disabled]="historyEnabled || isdefaultDoc"
              class="example-margin"
              (change)="onApplicableChange($event)"
            >
              <span class="font-weight-lighter" style="font-size: 14px;"
                >Show non-applicable controls</span
              ></mat-checkbox
            >
          </section>
          <div class="mx-2">
            <button
              [disabled]="scrollLoader || isdefaultDoc"
              matTooltip="Edit Applicable Controls"
              (click)="manageControls()"
              style="width: 131px;height:36px;"
              class="cb-btn-secondary"
            >
              Manage Controls
            </button>
          </div>
        </div>
      </div>
      <div class=" w-100p my-2 ">
        <span class=" mx-3 text-secondary">
          Showing <span class="font-weight-bold"> {{ totalCount }}</span> results
        </span>
      </div>
      <div
        class="table-container mx-3 position-relative"
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="onScrollTable()"
        [infiniteScrollDisabled]="scrollLoader"
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
      >
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0  w-100p;">
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>CONTROL</th>
            <td mat-cell *matCellDef="let row" style="font-size: 14px; padding-right: 12px;padding-left: 5px;">
              {{ row.details.program_unit.code }}
            </td>
          </ng-container>

          <ng-container matColumnDef="control">
            <th mat-header-cell *matHeaderCellDef>TITLE</th>
            <td
              mat-cell
              *matCellDef="let row"
              class="text-truncate control-title"
              style="max-width: 180px;font-size: 14px; padding-right: 12px; color: #00A3FF;cursor: pointer;"
              (click)="
                onRowClick(row, 'evidence'); toggleSideBar('Evidence'); changeAction('controlOverview', 'Evidence')
              "
            >
              {{ row.details.program_unit.title }}
            </td>
          </ng-container>
          <!--
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef>Category</th>
            <td mat-cell *matCellDef="let row">
              <ul style="list-style: none; margin: 0;" *ngIf="row.details.categories.length; else noData">
                <li class="d-flex" *ngFor="let item of row.details.categories; let i = index">
                  <ng-container *ngIf="i < 2">
                    <span class="text-truncate" style="max-width: 180px;font-size: 14px;"
                      >{{ categoriesMapper[item] }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="i === 2">
                    <li class="d-flex align-items-center" style="cursor: pointer;font-size: 14px;">
                      +{{ row.details.categories.length - 2 }} more
                    </li>
                  </ng-container>
                </li>
              </ul>
              <ng-template #noData>
                <div style="letter-spacing: 1px; font-weight: 100;font-size: 16px;">
                  - -
                </div>
              </ng-template>
            </td>
          </ng-container> -->

          <ng-container matColumnDef="policies">
            <th mat-header-cell *matHeaderCellDef>
              <div class="mx-2">POLICIES</div>
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              (click)="
                onRowClick(row, 'policy'); toggleSideBar('Policies'); changeAction('controlOverview', 'Policies')
              "
              [ngStyle]="{ 'pointer-events': !row.policies.length || historyEnabled ? 'none' : 'auto' }"
            >
              <ul
                class="hover-column"
                style="list-style: none; margin: 0;cursor: pointer;"
                *ngIf="row.policies.length; else defaultDocPolicy"
              >
                <li class="d-flex " *ngFor="let item of row.policies; let i = index">
                  <ng-container *ngIf="i < 2">
                    <ng-container *ngIf="item?.policy_doc?.status == 'Not Published' && !row?.is_uploaded">
                      <span class="not-published-dot"></span>
                    </ng-container>
                    <ng-container *ngIf="item?.policy_doc?.status == 'Published' || row?.is_uploaded">
                      <span class="published-dot"></span>
                    </ng-container>

                    <span class="text-truncate " style="max-width:180px;font-size: 14px; ">
                      {{ item?.policy_doc?.name }}</span
                    >
                  </ng-container>
                  <ng-container *ngIf="i === 2">
                    <li class="d-flex align-items-center " style=" cursor: pointer;font-size: 14px; ">
                      <span class="option-count">+{{ row.policies.length - 2 }}</span>
                    </li>
                  </ng-container>
                </li>
              </ul>
              <ng-template #defaultDocPolicy>
                <ul
                  class="hover-column"
                  style="list-style: none; margin: 0; "
                  *ngIf="!row.policies.length && isdefaultDoc && !showDefaultDocData; else noData"
                >
                  <span
                    *ngIf="row?.policies.length == 0"
                    class="text-truncate "
                    style="max-width: 180px;font-size: 14px;"
                  >
                    <!-- <ng-container *ngIf="!row?.is_uploaded && row.details.categories.length">
                      <span class="not-published-dot"></span>
                    </ng-container> -->
                    <ng-container *ngIf="isdefaultDoc && row.details.categories.length">
                      <span class="published-dot"></span>
                    </ng-container>
                    {{ (row?.details?.categories)[0] }}
                  </span>
                </ul>
              </ng-template>
            </td>
            <!-- <mat-menu #policiesMenu="matMenu" class="p-2 position-absolute" yPosition="above">
              <ul>
                <ng-container *ngFor="let item of selectedRowData?.policies; let i = index">
                  <ng-container *ngIf="i < 2">
                    <li class="d-flex " style="font-size: 14px; cursor: pointer;">
                      <ng-container *ngIf="item?.policy_doc?.status == 'Not Published'">
                        <span class="not-published-dot"></span>
                      </ng-container>
                      <ng-container *ngIf="item?.policy_doc?.status == 'Published'">
                        <span class="published-dot"></span>
                      </ng-container>
                      <span class=" text-truncate" style="padding-right: 25px;font-size: 14px;">
                        {{ item?.policy_doc?.name }}</span
                      >
                    </li>
                  </ng-container>
                  <ng-container *ngIf="i === 2">
                    <li class="d-flex " style="font-size: 14px; cursor: pointer;">
                      <span class="option-count">+{{ selectedRowData?.policies.length - 2 }}</span> more
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
              <div class="d-flex align-items-center  " style="font-size: 12px;">
                <mat-icon style="color: #3080ed;" class="cursor-pointer">fullscreen</mat-icon>
                <span
                  style="color: #3080ed;"
                  class="cursor-pointer"
                  (click)="toggleSideBar('Policies'); changeAction('controlOverview', 'Policies')"
                  >Click to expand</span
                >
              </div>
            </mat-menu> -->
          </ng-container>

          <ng-container matColumnDef="procedures">
            <th mat-header-cell *matHeaderCellDef><div class="mx-2">PROCEDURES</div></th>
            <td
              mat-cell
              *matCellDef="let row"
              (click)="
                onRowClick(row, 'procedure'); toggleSideBar('Procedures'); changeAction('controlOverview', 'Procedures')
              "
              [ngStyle]="{ 'pointer-events': !row.procedures.length || historyEnabled ? 'none' : 'auto' }"
            >
              <ul
                class="hover-column"
                style="list-style: none; margin: 0; "
                *ngIf="row.procedures.length; else defaultDoc"
              >
                <li class="d-flex" *ngFor="let item of row.procedures; let i = index">
                  <ng-container *ngIf="i < 2">
                    <ng-container *ngIf="item?.procedure_doc?.status == 'Not Published'">
                      <span class="not-published-dot"></span>
                    </ng-container>
                    <ng-container *ngIf="item?.procedure_doc?.status == 'Published'">
                      <span class="published-dot "></span>
                    </ng-container>

                    <span class="text-truncate " style="max-width: 180px;font-size: 14px;">
                      {{ item?.procedure_doc?.name }}
                    </span>
                  </ng-container>
                  <ng-container *ngIf="i === 2">
                    <li class="d-flex align-items-center " style="font-size: 14px;cursor: pointer;">
                      <span class="option-count">+{{ row.procedures.length - 2 }}</span>
                    </li>
                  </ng-container>
                </li>
              </ul>
              <ng-template #defaultDoc>
                <ul
                  class="hover-column"
                  style="list-style: none; margin: 0; "
                  *ngIf="isdefaultDoc && !showDefaultDocData; else noData"
                >
                  <span
                    *ngIf="row?.procedures.length == 0"
                    class="text-truncate "
                    style="max-width: 180px;font-size: 14px;"
                  >
                    <!-- <ng-container *ngIf="!row?.is_uploaded && row.details.categories.length">
                      <span class="not-published-dot"></span>
                    </ng-container> -->
                    <ng-container *ngIf="isdefaultDoc && row.details.categories.length">
                      <span class="published-dot "></span>
                    </ng-container>
                    {{ (row?.details?.categories)[0] }}
                  </span>
                </ul>
              </ng-template>
            </td>
            <!-- <mat-menu #procedureMenu="matMenu" class="p-2 position-absolute" yPosition="above">
              <ul>
                <ng-container *ngFor="let item of selectedRowData?.procedures; let i = index">
                  <ng-container *ngIf="i < 2">
                    <li class="d-flex" style="font-size: 14px; cursor: pointer;">
                      <ng-container *ngIf="item?.procedure_doc?.status == 'Not Published'">
                        <span class="not-published-dot"></span>
                      </ng-container>
                      <ng-container *ngIf="item?.procedure_doc?.status == 'Published'">
                        <span class="published-dot "></span>
                      </ng-container>
                      <span class="text-truncate " style="padding-right: 25px;font-size: 14px;">
                        {{ item?.procedure_doc?.name }}</span
                      >
                    </li>
                  </ng-container>
                  <ng-container *ngIf="i === 2">
                    <li class="d-flex " style="font-size: 14px; cursor: pointer;">
                      <span class="option-count">+{{ selectedRowData?.procedures.length - 2 }}</span>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
              <div class="d-flex align-items-center " style="font-size: 14px;">
                <mat-icon style="color: #3080ed;" class="cursor-pointer">fullscreen</mat-icon>
                <span
                  (click)="toggleSideBar('Procedures'); changeAction('controlOverview', 'Procedures')"
                  style="color: #3080ed;"
                  class="cursor-pointer"
                  >Click to expand</span
                >
              </div>
            </mat-menu> -->
          </ng-container>

          <ng-container matColumnDef="evidence">
            <th mat-header-cell *matHeaderCellDef>
              <div class="mx-2">EVIDENCE</div>
            </th>
            <td
              mat-cell
              *matCellDef="let row"
              [ngStyle]="{
                'pointer-events': (!row.evidences.length && !row.tests.length) || historyEnabled ? 'none' : 'auto'
              }"
              (click)="
                onRowClick(row, 'evidence'); toggleSideBar('Evidence'); changeAction('controlOverview', 'Evidence')
              "
            >
              <ul
                class="hover-column"
                style="list-style: none; margin: 0;"
                *ngIf="row.evidences.length || row.tests.length; else noData"
              >
                <ng-container *ngIf="row.evidences.length">
                  <li class="d-flex" *ngFor="let item of row.evidences; let i = index">
                    <ng-container *ngIf="i < 1">
                      <!-- <ng-container *ngIf="item?.cb_task?.status == 'Running'; else normalIcon">
                        <span class="not-published-dot"></span>
                      </ng-container>
                      <ng-template #normalIcon>
                        <span class="published-dot "></span>
                      </ng-template> -->
                      <img
                        style="height: 20px;"
                        *ngIf="item?.cb_task?.status == 'Running'; else otherStatus"
                        class="mr-2 "
                        src="../../../assets/images/core/cc-evidencetask1.png"
                      />
                      <ng-template #otherStatus>
                        <img
                          style="height: 20px;"
                          class="mr-2  "
                          src="../../../assets/images/core/cc-evidence-task-2.png"
                        />
                      </ng-template>
                      <span class="text-truncate " style="max-width: 180px;font-size: 14px;"
                        >{{ item?.cb_task?.name }}
                      </span>
                    </ng-container>
                  </li>
                </ng-container>
                <ng-container *ngIf="row.tests.length">
                  <li class="d-flex" *ngFor="let item of row.tests; let i = index">
                    <ng-container *ngIf="i < 1">
                      <mat-icon
                        *ngIf="formatStatus(item.status) == 'Not Started'"
                        style="color: #8C96A1; font-size: 20px;"
                        >cloud_queue</mat-icon
                      >
                      <mat-icon *ngIf="formatStatus(item.status) == 'Danger'" style="color: #DC3545; font-size: 20px;"
                        >cloud_queue</mat-icon
                      >
                      <mat-icon
                        *ngIf="formatStatus(item.status) == 'Compliant'"
                        style="color: #0D9647; font-size: 20px;"
                        >cloud_queue</mat-icon
                      >
                      <mat-icon *ngIf="formatStatus(item.status) == 'Warning'" style="color: #FBBC05;font-size: 20px;"
                        >cloud_queue</mat-icon
                      >
                      <span class="text-truncate " style="max-width: 180px;font-size: 14px;"
                        >{{ item?.test_name }}
                      </span>
                    </ng-container>
                    <ng-container *ngIf="i === 2">
                      <li class="d-flex " style="font-size: 14px; cursor: pointer;">
                        <span class="option-count">+{{ row?.evidences?.length + row?.tests?.length - 2 }}</span>
                      </li>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
            </td>
            <!-- <mat-menu #evidenceMenu="matMenu" class="p-2 position-absolute" yPosition="above">
              <ul>
                <ng-container *ngFor="let item of selectedRowData?.evidences; let i = index">
                  <ng-container *ngIf="i < 1">
                    <li class="d-flex " style="font-size: 12px; cursor: pointer;">
                      <img
                        style="height: 20px;"
                        *ngIf="item?.cb_task?.status == 'Running'; else otherStatus"
                        class="mr-2 "
                        src="../../../assets/images/core/cc-evidencetask1.png"
                      />
                      <ng-template #otherStatus>
                        <img
                          style="height: 20px;"
                          class="mr-2  "
                          src="../../../assets/images/core/cc-evidence-task-2.png"
                        />
                      </ng-template>
                      <span class="text-truncate " style="max-width: 200px;padding-right: 25px;font-size: 14px;">{{
                        item?.cb_task?.name
                      }}</span>
                    </li>
                  </ng-container>
                </ng-container>
                <ng-container *ngFor="let item of selectedRowData?.tests; let i = index">
                  <ng-container *ngIf="i < 1">
                    <li class="d-flex " style="font-size: 12px; cursor: pointer;">
                      <mat-icon
                        *ngIf="formatStatus(item.status) == 'Not Started'"
                        style="color: #8C96A1; font-size: 20px;"
                        >cloud_queue</mat-icon
                      >
                      <mat-icon *ngIf="formatStatus(item.status) == 'Danger'" style="color: #DC3545; font-size: 20px;"
                        >cloud_queue</mat-icon
                      >
                      <mat-icon
                        *ngIf="formatStatus(item.status) == 'Compliant'"
                        style="color: #0D9647; font-size: 20px;"
                        >cloud_queue</mat-icon
                      >
                      <mat-icon *ngIf="formatStatus(item.status) == 'Warning'" style="color: #FBBC05;font-size: 20px;"
                        >cloud_queue</mat-icon
                      >
                      <span class="text-truncate " style="max-width: 200px;padding-right: 25px;font-size: 14px;">{{
                        item?.test_name
                      }}</span>
                    </li>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="selectedRowData?.evidences.length + selectedRowData?.tests.length > 2">
                  <li class="d-flex " style="font-size: 14px; cursor: pointer;">
                    <span class="option-count"
                      >+{{ selectedRowData?.evidences.length + selectedRowData?.tests.length - 2 }}</span
                    >
                  </li>
                </ng-container>
              </ul>
              <div class="d-flex align-items-center" style="font-size: 12px;">
                <mat-icon style="color: #3080ed;" class="cursor-pointer">fullscreen</mat-icon>
                <span
                  (click)="toggleSideBar('Evidence'); changeAction('controlOverview', 'Evidence')"
                  style="color: #3080ed;"
                  class="cursor-pointer"
                  >Click to expand</span
                >
              </div>
            </mat-menu> -->
          </ng-container>

          <ng-container matColumnDef="status">
            <th class="cursor-pointer" mat-header-cell *matHeaderCellDef (click)="sortData()">
              STATUS <i class="fa fa-fw fa-sort"></i>
            </th>
            <td mat-cell *matCellDef="let row">
              <!-- <ng-container *ngIf="!row?.is_uploaded && isdefaultDoc; else normal">
                <div
                  style="width: 90px;height: 30px;background: #F5F3F5;"
                  class="mt-2 rounded-pill p-1 px-1 line-container mb-2"
                >
                  <div class="line-shimmer"></div>
                </div>
              </ng-container> -->
              <ng-container #normal>
                <div
                  class="rounded-pill p-1 px-1 text-center"
                  style="width: 90px;"
                  [ngStyle]="getStyle(isdefaultDoc && !showDefaultDocData ? 'INCOMPLETE' : row.status.overall_status)"
                >
                  {{ isdefaultDoc && !showDefaultDocData ? 'Incomplete' : statusMapper[row.status.overall_status] }}
                </div>
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="p-0">
              <button
                *ngIf="!historyEnabled"
                mat-icon-button
                [disabled]="isdefaultDoc"
                (click)="
                  onRowClick(element, 'policies');
                  toggleSideBar('Policies');
                  changeAction('controlOverview', 'Policies')
                "
              >
                <ng-container *ngIf="isdefaultDoc; else enabled">
                  <img
                    src="/assets/images/dashboard/threedotsdisabled.png"
                    matTooltip="Actions are disabled while documents are uploading."
                    matTooltipPosition="left"
                    matTooltipClass="left mat_tooltip"
                    height="21px"
                    style="cursor: pointer;"
                  />
                </ng-container>
                <ng-template #enabled>
                  <img src="/assets/images/dashboard/threedots.png" height="24px" style="cursor: pointer;" />
                </ng-template>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" style="min-height: 60px;"></tr>
          <ng-template #noData>
            <div style="letter-spacing: 1px; font-weight: 100;font-size: 16px;">
              - -
            </div>
          </ng-template>
          <tr mat-row *ngIf="scrollLoader">
            <h2 class="font-weight-bold text-black-3 text-center">
              <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp;
            </h2>
          </tr>
          <tr mat-footer-row *matFooterRowDef="['loadMore']; sticky: true" class="text-center"></tr>
          <ng-container matColumnDef="loadMore">
            <td class="text-bold font-weight-bold border-0" mat-footer-cell *matFooterCellDef colspan="7">
              <ng-container *ngIf="!scrollLoader && this.overviewPage < this.totalCount / 8">
                Scroll to view more <i class="fas fa-arrow-down  cursor-pointer"></i
              ></ng-container>
            </td>
          </ng-container>
        </table>

        <div
          *ngIf="scrollLoader"
          style="width: 100% !important;"
          class="font-weight-bold text-black-3 w-100 text-center position-absolute"
        >
          <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp;
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div style="height: 0; overflow: hidden;">
  <div class="d-flex justify-content-center align-items-center ">
    <div class="certificate-container" id="contentToConvert">
      <div>
        <div class="row">
          <div class="w-100p text-center">
            <img src="/assets/images/cb-lite/stamp.png" class="stamp" alt="no-img" />
          </div>
        </div>
        <div class="row mt-3 justify-content-center">
          <div class="cert-title">Certificate of Compliance</div>
        </div>
        <hr />
        <div class="m-3 p-3">
          <div class="row text-left">
            <div class="cert-subtitle">
              This is to certify that <b>{{ certificateData?.org_name }}</b> has successfully completed risk assessment
              and creation of policies and procedures in line with <b>45 CFR § 164.308(a)(1)(i).</b>
            </div>
          </div>
          <div class="row text-left mt-3">
            <div class="cert-subtitle">
              The following list of policy and procedure documents was reviewed and approved by
              <b>{{ certificateData?.org_name }}</b> management on <b>{{ certificateData?.date }}</b>
            </div>
          </div>
          <div class="row mt-3" id="listContainer">
            <div class="col-md-6">
              <ul class="category-style d-flex flex-column" id="firstColumn"></ul>
            </div>
            <div class="col-md-6">
              <ul class="category-style d-flex flex-column" id="secondColumn"></ul>
            </div>
          </div>
          <hr />
          <div class="d-flex justify-content-around align-items-center sign-container">
            <ng-container *ngIf="isSignatureLoading; else show">
              <h2 class="font-weight-bold mt-2 text-black-3 text-center">
                <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': isSignatureLoading }" style="font-size:large;"></i>
                &nbsp;
              </h2>
            </ng-container>
            <ng-template #show>
              <div class="fs-17 cursor-pointer w-100p">
                <img src="/assets/images/logo/logo.png" class="cb-lite-logo mx-1" alt="logo" srcset="" />
                <span class="font-weight-bold fs-17">ComplianceBot</span>
              </div>
              <img
                id="onlineImage"
                class="user-avatar-pic"
                height="70"
                width="130px"
                style="max-height: 100px; max-width: 130px;"
                [src]="UserSignature"
                alt="no-image"
              />
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
