import { Component, Input, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-customsidenav',
  templateUrl: './customsidenav.component.html',
  styleUrls: ['./customsidenav.component.scss']
})
export class CustomsidenavComponent implements OnInit {
  modalType: string;
  title: string;
  DocumentGuid: string;
  DocCreatedBy: string;
  docTitle: string;
  type: string;
  userEmail: any;
  title_tag: string;
  formType: any;
  form_data: any;
  storage_id: any;
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('mySidenav').classList.toggle('open');
    }, 100);
  }
}
