<app-cb-lite-header
  (trigger)="updateApplicableControls()"
  [disabledSaveContinue]="!checkIsControlNotSelected"
  [disabledStepper]="disabledStepper"
  [updatedCitation]="updatedCitation"
></app-cb-lite-header>
<app-spinner *ngIf="loading"></app-spinner>
<div class="cblite-body">
  <div class="best-choice" *ngIf="total > 0">
    <span class="best-choice-text">
      Based on the choices so far and the best practices of the program, we have selected the controls most applicable
      to your organization. Feel free to change if necessary.
    </span>
  </div>
  <div class="search-filter align-items-center">
    <div class="d-flex align-items-center">
      <div class="search">
        <input
          type="text"
          style="min-width: 300px;"
          placeholder="Search by title, control or keyword"
          class="search-input"
          [(ngModel)]="searchText"
          (input)="searchAndReturnObjects()"
        />
        <i *ngIf="!searchText" class="fa fa-search search-icon"></i>
        <i
          *ngIf="searchText"
          (click)="searchText = ''; searchAndReturnObjects()"
          class=" mx-1 fa fa-times times-icon cursor-pointer"
        ></i>
      </div>
    </div>
    <div class="text-black" style="margin-right: 2%;">
      Controls Selected {{ calculateTotalSelectedControls }}/{{ total }} |
      {{ calculateTotalSelectedControls / total | percent }} Choosen
    </div>
  </div>

  <div class="mt-3 d-flex  text-black-50" *ngIf="searchText">
    <div>Showing {{ filterTotal }} out for {{ total }} results for keyword</div>
    <div class="mx-1 search-text w-240 text-truncate">{{ searchText }}</div>
  </div>

  <div style="height: 750px; overflow-y: scroll; margin-top: 20px;">
    <mat-error *ngIf="!checkIsControlNotSelected && !loading" style="font-size: 13px;">
      Please ensure that at least one control is selected before proceeding to the next step.
    </mat-error>
    <ng-container *ngFor="let item of tableData | keyvalue">
      <mat-accordion>
        <mat-expansion-panel class="custom-accordion" [expanded]="true" #expansionPanel>
          <mat-expansion-panel-header>
            <ng-container class="header-content" (click)="toggleExpansionPanel(expansionPanel)">
              <mat-panel-title class="title">
                {{ item.key }}
              </mat-panel-title>
              <mat-checkbox
                [class.ctrl-selection]="tableData[item.key].isAssessmentApplicable"
                *ngIf="!searchText.length"
                [(ngModel)]="tableData[item.key].applicable"
                [disabled]="tableData[item.key].isAssessmentApplicable"
                (change)="selectOverallControl(tableData[item.key], item.key)"
                (click)="$event.stopPropagation()"
                class="checkbox mt-3"
                style="margin-right: 30px;"
              ></mat-checkbox>
            </ng-container>
          </mat-expansion-panel-header>
          <table>
            <thead style="color: black;">
              <tr>
                <th>Controls</th>
                <th>Title</th>
                <th>Control Text</th>
                <th style="text-align: center;">Is Applicable</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of tableData[item.key].data">
                <td style="width: 200px;">
                  <ng-container *ngFor="let control of row.controls">
                    <span style="font-size:14px" [innerHTML]="highlightText(control?.code, searchText)"></span>
                  </ng-container>
                </td>
                <td style="width: 200px;">
                  <ng-container *ngFor="let control of row.controls">
                    <span style="font-size:14px" [innerHTML]="highlightText(control?.title, searchText)"></span>
                  </ng-container>
                </td>
                <td style="width: 750px;">
                  <ng-container *ngFor="let control of row.controls">
                    <span
                      style="font-size:14px"
                      [innerHTML]="highlightText(control.master_control?.regulatory_text, searchText)"
                    ></span>
                  </ng-container>
                </td>
                <td [class.ctrl-selection]="row.isAssessmentApplicable" style="width: 112px; text-align: center">
                  <mat-checkbox
                    [(ngModel)]="row.applicable"
                    [disabled]="row.hasOwnProperty('isAssessmentApplicable') && row?.isAssessmentApplicable !== null"
                    style="font-size: 14px;"
                    (change)="selectControl(row, item.key)"
                    [matTooltip]="row?.tooltip"
                    matTooltipPosition="below"
                    matTooltipClass="cb-tooltip custom-tooltip"
                  >
                  </mat-checkbox>
                  <!-- <i
                    *ngIf="row?.tooltip"
                    style="margin-right: -17px !important;"
                    class="fas fa-info-circle text-truncate cb-tooltip-btn ml-1"
                    [matTooltip]="row?.tooltip"
                    matTooltipPosition="below"
                    matTooltipClass="cb-tooltip custom-tooltip"
                    aria-label="tooltip"
                  ></i> -->
                </td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </div>
</div>
