<app-cb-lite-header
  (triggerAssement)="updateOrgAssesment()"
  [disabledSaveContinue]="validateAnswers(orgAssesment)"
  [disabledStepper]="disabledStepper"
  [updatedAssement]="updatedAssement"
></app-cb-lite-header>
<app-spinner *ngIf="loading"></app-spinner>
<div class="modal-body pb-0">
  <div class="row mt-1 mb-3" *ngIf="backend_error">
    <div class="col-12">
      <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <div class="note-container">
      <div class="best-choice note">
        <span class="best-choice-text">
          We have pre-selected responses based on the best practices of the selected program. Feel free to update them
          to fit your organization.
        </span>
      </div>
    </div>
    <div
      style="width: 100% ;display: flex;justify-content: center;flex-direction: column;align-items: center;padding: 20px;padding-bottom: 0px;"
    >
      <div style="height: 72vh; overflow-y: scroll; width: 60%;">
        <div>
          <div class="custom-accordion">
            <div class="question-css" *ngIf="checkComplianceProgramType">
              <h3 style="padding-right: 10px;font-weight: 600;">
                1.Please select the option that best describes your organization type.
                <mat-icon
                  matTooltip="Please select the type of organization that best describes your entity"
                  matTooltipClass="custom-tooltip"
                  matTooltipPosition="above"
                  style="position: relative;
                top: 4px;
    font-size: 20px;"
                  >help_outline</mat-icon
                >
              </h3>
              <div class="justify-content-between align-items-center mat-radio-org">
                <div *ngFor="let item of organiationTypes" style="border-bottom: 1px solid #cfcfcf;">
                  <mat-radio-button
                    [value]="item.guid"
                    [checked]="getselectedOrgType(item.guid)"
                    (change)="onselectedOrgTypeChange(item.guid)"
                    class="option-items"
                  >
                    <div style="display: flex; justify-content: space-between;">
                      <span style="font-size: 14px;"> {{ item.title }}</span>
                      <mat-icon
                        matTooltip="{{ item.tooltip }}"
                        matTooltipClass="custom-tooltip"
                        matTooltipPosition="above"
                        style="margin-left: 8px;"
                        class="mat-icons"
                        >help_outline</mat-icon
                      >
                    </div>
                  </mat-radio-button>
                </div>
              </div>
            </div>
            <div *ngFor="let group of orgAssesment; let j = index">
              <div class="question-css">
                <h3 style="padding-right: 10px;font-weight: 600;">
                  {{ checkComplianceProgramType ? j + 2 : j + 1 }}. {{ group.question_details.question }}
                </h3>
                <div *ngIf="group.question_details.type === 'multiple_choice'">
                  <ul class="option-list">
                    <li *ngFor="let option of group.question_details.options; let k = index" class="option-item">
                      <div class="d-flex justify-content-between align-items-center">
                        <mat-checkbox
                          [checked]="
                            isOptionSelected(option, group?.answers) ||
                            (option?.opt?.toLowerCase() === 'other' && group.isOtherActive)
                          "
                          (change)="onCheckboxChange($event, option, group)"
                          style="font-size: 14px;"
                        >
                          {{ option.opt }}
                        </mat-checkbox>
                        <!-- <img
                          style="margin-top: -1px !important;"
                          class="ml-2 mr-2"
                          *ngIf="group.question_details.best_response == option"
                          src="./../../../assets/images/core/best-response.svg"
                          height="15px"
                        /> -->
                      </div>
                      <div
                        *ngIf="option?.opt?.toLowerCase() === 'other' && group.isOtherActive"
                        class="other-option-container"
                      >
                        <input
                          type="text"
                          [value]="group?.answers"
                          placeholder="Enter an answer..."
                          [(ngModel)]="group.otherOption"
                          name="otherOption"
                          #otherOption="ngModel"
                          required
                        />
                        <div
                          *ngIf="
                            (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                            otherOption.errors?.required
                          "
                          class="text-danger"
                        >
                          *This field is required.
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div *ngIf="group.question_details.type === 'single_choice'">
                  <ul class="option-list">
                    <li *ngFor="let option of group.question_details.options; let k = index" class="option-item">
                      <div class="d-flex justify-content-between align-items-center">
                        <mat-radio-button
                          [checked]="
                            isOptionSelected(option, group?.answers) ||
                            (option?.opt?.toLowerCase() === 'other' && group.isOtherActive)
                          "
                          [value]="option?.opt"
                          name="{{ group.question_details.question }}"
                          (change)="onRadioChange($event, option, group)"
                          style="font-size: 14px;"
                        >
                          {{ option.opt }}
                        </mat-radio-button>
                        <!-- <img
                          style="margin-top: -1px !important;"
                          class="ml-2 mr-2"
                          *ngIf="group.question_details.best_response == option"
                          src="./../../../assets/images/core/best-response.svg"
                          height="15px"
                        /> -->
                      </div>
                      <div
                        *ngIf="option?.opt?.toLowerCase() === 'other' && group.isOtherActive"
                        class="other-option-container"
                      >
                        <input
                          type="text"
                          [value]="group?.answers"
                          placeholder="Enter an answer..."
                          [(ngModel)]="group.otherOption"
                          name="otherOption"
                          #otherOption="ngModel"
                          required
                        />
                        <div
                          *ngIf="
                            (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                            otherOption.errors?.required
                          "
                          class="text-danger"
                        >
                          *This field is required.
                        </div>
                      </div>
                    </li>
                    <li
                      class="option-item"
                      *ngIf="group.answers && group.answers[0]?.opt === 'Yes' && group.question_details.follow_up"
                    >
                      <ng-container *ngFor="let eachQuestion of group.question_details.follow_up.Yes">
                        <h3 style="padding-right: 10px;font-weight: 600;margin-top: 20px;">
                          {{ eachQuestion.question }}
                        </h3>
                        <div>
                          <input
                            class="ml-0"
                            type="text"
                            [placeholder]="eachQuestion.question"
                            [(ngModel)]="eachQuestion.answers[0]"
                            name_email="name_email"
                            #name_email="ngModel"
                            required
                          />
                          <div
                            *ngIf="(name_email.dirty || name_email.touched) && name_email.errors?.required"
                            class="text-danger mt-1"
                          >
                            *This field is required.
                          </div>
                        </div>
                      </ng-container>
                    </li>
                  </ul>
                </div>
                <div *ngIf="group.question_details.type === 'nested_questions'">
                  <ul class="option-list">
                    <li *ngFor="let option of group.question_details.options; let k = index" class="option-item">
                      <div class="d-flex justify-content-between align-items-center">
                        <mat-radio-button
                          [checked]="
                            isOptionSelected(option, group?.answers) ||
                            (option?.opt?.toLowerCase() === 'other' && group.isOtherActive)
                          "
                          [value]="option?.opt"
                          name="{{ group.question_details.question }}"
                          (change)="onRadioChange($event, option, group)"
                          style="font-size: 14px;"
                        >
                          {{ option?.opt }}
                        </mat-radio-button>
                        <!-- <img
                          style="margin-top: -1px !important;"
                          class="ml-2 mr-2"
                          *ngIf="group.question_details.best_response == option"
                          src="./../../../assets/images/core/best-response.svg"
                          height="15px"
                        /> -->
                      </div>
                      <div
                        *ngIf="option?.opt?.toLowerCase() === 'other' && group.isOtherActive"
                        class="other-option-container"
                      >
                        <input
                          type="text"
                          [value]="group?.answers"
                          placeholder="Enter an answer..."
                          [(ngModel)]="group.otherOption"
                          name="otherOption"
                          #otherOption="ngModel"
                          required
                        />
                        <div
                          *ngIf="
                            (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                            otherOption.errors?.required
                          "
                          class="text-danger"
                        >
                          *This field is required.
                        </div>
                      </div>
                    </li>
                    <!-- nested loop -->
                    <ng-container
                      *ngIf="group.answers && group.answers[0]?.opt === 'Yes' && group.question_details.follow_up"
                    >
                      <div class="ml-4">
                        <ng-container *ngFor="let question of group.question_details.follow_up.Yes; let l = index">
                          <h3 style="padding-right: 10px;font-weight: 600;margin-top: 20px;">
                            {{ getAlphabet(l) }}. {{ question.question }}
                          </h3>
                          <div *ngIf="question.type === 'multiple_choice'">
                            <ul class="option-list">
                              <li *ngFor="let option of question.options; let k = index" class="option-item">
                                <div class="d-flex justify-content-between align-items-center">
                                  <mat-checkbox
                                    [checked]="
                                      isOptionSelected(option, question?.answers) ||
                                      (option?.opt?.toLowerCase() === 'other' && question.isOtherActive)
                                    "
                                    (change)="onCheckboxChange($event, option, question)"
                                    style="font-size: 14px;"
                                  >
                                    {{ option?.opt }}
                                  </mat-checkbox>
                                  <!-- <img
                                    style="margin-top: -1px !important;"
                                    class="ml-2 mr-2"
                                    *ngIf="question.best_response == option"
                                    src="./../../../assets/images/core/best-response.svg"
                                    height="15px"
                                  /> -->
                                </div>
                                <div
                                  *ngIf="option?.opt?.toLowerCase() === 'other' && question.isOtherActive"
                                  class="other-option-container"
                                >
                                  <input
                                    type="text"
                                    [value]="question?.answers"
                                    placeholder="Enter an answer..."
                                    [(ngModel)]="question.otherOption"
                                    name="otherOption"
                                    #otherOption="ngModel"
                                    required
                                  />
                                  <div
                                    *ngIf="
                                      (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                                      otherOption.errors?.required
                                    "
                                    class="text-danger"
                                  >
                                    *This field is required.
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="question?.type === 'single_choice'">
                            <ul class="option-list">
                              <li *ngFor="let option of question.options; let k = index" class="option-item">
                                <div class="d-flex justify-content-between align-items-center">
                                  <mat-radio-button
                                    [checked]="
                                      isOptionSelected(option, question?.answers) ||
                                      (option?.opt?.toLowerCase() === 'other' && question.isOtherActive)
                                    "
                                    [value]="option?.opt"
                                    name="{{ question?.question }}"
                                    (change)="onRadioChange($event, option, question)"
                                    style="font-size: 14px;"
                                  >
                                    {{ option?.opt }}
                                  </mat-radio-button>
                                  <!-- <img
                                    style="margin-top: -1px !important;"
                                    class="ml-2 mr-2"
                                    *ngIf="question?.best_response == option"
                                    src="./../../../assets/images/core/best-response.svg"
                                    height="15px"
                                  /> -->
                                </div>
                                <div
                                  *ngIf="option?.opt?.toLowerCase() === 'other' && question.isOtherActive"
                                  class="other-option-container"
                                >
                                  <input
                                    type="text"
                                    [value]="question?.answers"
                                    placeholder="Enter an answer..."
                                    [(ngModel)]="question?.answers.otherOption"
                                    name="otherOption"
                                    #otherOption="ngModel"
                                    required
                                  />
                                  <div
                                    *ngIf="
                                      (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                                      otherOption.errors?.required
                                    "
                                    class="text-danger"
                                  >
                                    *This field is required.
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
