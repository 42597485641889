import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComplianceGraphComponent } from '@app/shared/complianceGraph/complianceGraph.component';
import { ChartsModule } from 'ng2-charts';
import { NoOrganisationComponent } from '@app/shared/noOrganisation/noOrganisation.component';
import { SpinnerComponent } from '@app/shared/spinner/spinner.component';
import { SpinnerService } from '@app/shared/spinner/spinner.service';
import { ConfirmDialogComponent } from '@app/shared/modal/confirmDialog/confirmDialog.component';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { AddPeopleComponent } from '@app/shared/modal/addPeople/addPeople.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddAdministratorComponent } from '@app/shared/modal/addAdministrator/addAdministrator.component';
import { AddCommitteeComponent } from '@app/shared/modal/addCommittee/addCommittee.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DisableEventComponent } from '@app/shared/modal/disable-event/disable-event.component';
import { ToasterComponent } from '@app/shared/toaster/toaster.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { ToasterContainerComponent } from '@app/shared/toaster/toaster-container.component';
import { AddCommitteeMembersComponent } from '@app/shared/modal/addCommitteeMembers/addCommitteeMembers.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NoMobileComponent } from './no-mobile/no-mobile.component';
import { ConfirmLeadAcceptDialogComponent } from './modal/confirmLeadAcceptDialog/confirmLeadAcceptDialog.component';
import { InviteEditorsComponent } from './modal/invite-editors/invite-editors.component';
import { SelectFolderComponent } from './modal/select-folder/select-folder.component';
import { ReplyComponent } from './modal/reply/reply.component';
import { SelectTemplateComponent } from './modal/select-template/select-template.component';
import { MarkEditDoneComponent } from './modal/mark-edit-done/mark-edit-done.component';
import { ApproveChangesComponent } from './modal/approve-changes/approve-changes.component';
import { RevokeEditorComponent } from './modal/revoke-editor/revoke-editor.component';
import { RevokeApproverComponent } from './modal/revoke-approver/revoke-approver.component';
import { ViewApproversComponent } from './modal/view-approvers/view-approvers.component';
import { ViewEditorsComponent } from './modal/view-editors/view-editors.component';
import { ChangeReqForInviteEditorsComponent } from './modal/change-req-for-invite-editors/change-req-for-invite-editors.component';
import { PublishDocumentComponent } from './modal/publish-document/publish-document.component';
import { CreateNewVersionComponent } from './modal/create-new-version/create-new-version.component';
import { CreateDocumentSuccessComponent } from './modal/create-document-success/create-document-success.component';
import { RestoreEditorComponent } from './modal/restore-editor/restore-editor.component';
import { ReassignApproverComponent } from './modal/reassign-approver/reassign-approver.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChangeSignatureComponent } from './modal/change-signature/change-signature.component';
import { ChangeDueDateFormComponent } from './modal/change-due-date-form/change-due-date-form.component';
import { GenerateSignatureComponent } from './modal/generate-signature/generate-signature.component';
import { DrawSignatureComponent } from './modal/draw-signature/draw-signature.component';
import { MultiSelectScrollDocumentComponent } from './modal/multi-select-scroll-document/multi-select-scroll-document.component';
import { MultiselectRecordComponent } from './modal/multiselect-record/multiselect-record.component';
import { ViewTraineesComponent } from './modal/view-trainees/view-trainees.component';
import { ViewDocumentsComponent } from './modal/view-documents/view-documents.component';
import { ApproveTrainingDocumentComponent } from './modal/approve-training-document/approve-training-document.component';
import { StartTrainingComponent } from './modal/start-training/start-training.component';
import { CreateTrainingSuccessComponent } from './modal/create-training-success/create-training-success.component';
import { FolderSelectionComponent } from './modal/folder-selection/folder-selection.component';
import { DemoMaterialModule } from '../materials.module';
import { AddGroupUserComponent } from './modal/add-group-user/add-group-user.component';
import { DelGroupUserComponent } from './modal/del-group-user/del-group-user.component';
import { CreateTrainingGroupComponent } from './modal/create-training-group/create-training-group.component';
import { ContinueCurrentVersionComponent } from './modal/continue-current-version/continue-current-version.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CreateJiraTicketComponent } from './modal/create-jira-ticket/create-jira-ticket.component';
// import { IncidentApproverComponent } from './modal/incident-approver/incident-approver.component';
import { CreateIncidentSuccessComponent } from './modal/create-incident-success/create-incident-success.component';
import { CreateCategoryComponent } from './modal/create-category/create-category.component';
import { DeleteCategoryComponent } from './modal/delete-category/delete-category.component';
import { EditIncidentCategoryComponent } from './modal/edit-incident-category/edit-incident-category.component';
import { PickIncidentComponent } from './modal/pick-incident/pick-incident.component';
import { BlockIncidentComponent } from './modal/block-incident/block-incident.component';
import { ResolveIncidentComponent } from './modal/resolve-incident/resolve-incident.component';
import { ReopenIncidentComponent } from './modal/reopen-incident/reopen-incident.component';
import { CloseIncidentComponent } from './modal/close-incident/close-incident.component';
import { LinkAnIssueComponent } from './modal/link-an-issue/link-an-issue.component';
import { UnblockIncidentComponent } from './modal/unblock-incident/unblock-incident.component';
import { ApproveIncidentComponent } from './modal/approve-incident/approve-incident.component';
import { ChangeRequestForIncidentComponent } from './modal/change-request-for-incident/change-request-for-incident.component';
import { UploadDocumentComponent } from './modal/upload-document/upload-document.component';
import { EditDocumentSuccessComponent } from './modal/edit-document-success/edit-document-success.component';
import { AddReadersComponent } from '../Organisation-admin/documents/add-readers/add-readers.component';
import { RevokeIncidentReviewerComponent } from './modal/revoke-incident-reviewer/revoke-incident-reviewer.component';
import { PublishDocSuccessComponent } from './modal/publish-doc-success/publish-doc-success.component';
import { RenameDocumentTitleComponent } from './modal/rename-document-title/rename-document-title.component';
import { IncidentReviewerApproverComponent } from './modal/incident-reviewer-approver/incident-reviewer-approver.component';
import { ManageDocumentGroupComponent } from './modal/manage-document-group/manage-document-group.component';
import { AddInactiveUserComponent } from './modal/add-inactive-user/add-inactive-user.component';
import { ManageResponderApproverComponent } from './modal/manage-responder-approver/manage-responder-approver.component';
import { InviteMembersComponent } from './modal/invite-members/invite-members.component';
import { SaveCBDocuementsComponent } from './modal/save-cb-docuements/save-cb-docuements.component';
import { ResizableDirective } from './directive/resizable.directive';
import { DiscardPnpChangesComponent } from './modal/discard-pnp-changes/discard-pnp-changes.component';
import { SaveResponsesComponent } from './modal/save-responses/save-responses.component';
import { DisconnectIntegrationComponent } from './modal/disconnect-integration/disconnect-integration.component';
import { CommonPaginationComponent } from './common-pagination/common-pagination.component';
import { CustomsidenavComponent } from './modal/customsidenav/customsidenav.component';
import { EditEditorApproverComponent } from './modal/edit-editor-approver/edit-editor-approver.component';
import { ReassignEditorApproverComponent } from './modal/reassign-editor-approver/reassign-editor-approver.component';
import { PublishDocumentsComponent } from './modal/publish-documents/publish-documents.component';
import { MarkedEditDoneComponent } from './modal/marked-edit-done/marked-edit-done.component';
import { RemoveApproverEditorComponent } from './modal/remove-approver-editor/remove-approver-editor.component';
import { ApproveDocumentComponent } from './modal/approve-document/approve-document.component';
import { RequestChangeComponent } from './modal/request-change/request-change.component';
import { AddNewReaderComponent } from './modal/add-new-reader/add-new-reader.component';
import { SelectTemplatesComponent } from './modal/select-templates/select-templates.component';
import { PopoverDirective } from './directive/popover.directive';
import { PopoverComponent } from './directive/popover/popover.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ChartsModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2SearchPipeModule,
    TranslateModule,
    NgbModule,
    DemoMaterialModule,
    InfiniteScrollModule,
    NgSelectModule
  ],
  declarations: [
    LoaderComponent,
    NoMobileComponent,
    ComplianceGraphComponent,
    NoOrganisationComponent,
    ConfirmLeadAcceptDialogComponent,
    ConfirmDialogComponent,
    CustomDialogComponent,
    SpinnerComponent,
    AddPeopleComponent,
    AddCommitteeComponent,
    AddAdministratorComponent,
    DisableEventComponent,
    ToasterComponent,
    ToasterContainerComponent,
    AddCommitteeMembersComponent,
    InviteEditorsComponent,
    SelectFolderComponent,
    ReplyComponent,
    SelectTemplateComponent,
    MarkEditDoneComponent,
    ApproveChangesComponent,
    RevokeEditorComponent,
    RevokeApproverComponent,
    ViewApproversComponent,
    ViewEditorsComponent,
    ChangeReqForInviteEditorsComponent,
    PublishDocumentComponent,
    CreateNewVersionComponent,
    CreateDocumentSuccessComponent,
    RestoreEditorComponent,
    ReassignApproverComponent,
    ChangeSignatureComponent,
    ChangeDueDateFormComponent,
    GenerateSignatureComponent,
    DrawSignatureComponent,
    MultiSelectScrollDocumentComponent,
    MultiselectRecordComponent,
    DrawSignatureComponent,
    ViewTraineesComponent,
    ViewDocumentsComponent,
    ApproveTrainingDocumentComponent,
    StartTrainingComponent,
    CreateTrainingSuccessComponent,
    FolderSelectionComponent,
    AddGroupUserComponent,
    DelGroupUserComponent,
    ContinueCurrentVersionComponent,
    CreateTrainingGroupComponent,
    CreateJiraTicketComponent,
    CreateIncidentSuccessComponent,
    CreateCategoryComponent,
    DeleteCategoryComponent,
    EditIncidentCategoryComponent,
    PickIncidentComponent,
    BlockIncidentComponent,
    ResolveIncidentComponent,
    ReopenIncidentComponent,
    CloseIncidentComponent,
    LinkAnIssueComponent,
    UnblockIncidentComponent,
    ApproveIncidentComponent,
    ChangeRequestForIncidentComponent,
    UploadDocumentComponent,
    EditDocumentSuccessComponent,
    AddReadersComponent,
    RevokeIncidentReviewerComponent,
    PublishDocSuccessComponent,
    RenameDocumentTitleComponent,
    IncidentReviewerApproverComponent,
    ManageDocumentGroupComponent,
    AddInactiveUserComponent,
    ManageResponderApproverComponent,
    InviteMembersComponent,
    SaveCBDocuementsComponent,
    ResizableDirective,
    DiscardPnpChangesComponent,
    SaveResponsesComponent,
    DisconnectIntegrationComponent,
    CommonPaginationComponent,
    CustomsidenavComponent,
    EditEditorApproverComponent,
    ReassignEditorApproverComponent,
    CommonPaginationComponent,
    PublishDocumentsComponent,
    MarkedEditDoneComponent,
    RemoveApproverEditorComponent,
    ApproveDocumentComponent,
    RequestChangeComponent,
    AddNewReaderComponent,
    SelectTemplatesComponent,
    PopoverDirective,
    PopoverComponent
  ],
  providers: [SpinnerService, ToasterService],
  exports: [
    LoaderComponent,
    NoMobileComponent,
    ComplianceGraphComponent,
    ConfirmLeadAcceptDialogComponent,
    NoOrganisationComponent,
    ConfirmDialogComponent,
    CustomDialogComponent,
    SpinnerComponent,
    AddPeopleComponent,
    AddCommitteeComponent,
    AddAdministratorComponent,
    DisableEventComponent,
    ToasterComponent,
    ToasterContainerComponent,
    AddCommitteeMembersComponent,
    DemoMaterialModule,
    ReactiveFormsModule,
    ResizableDirective,
    CommonPaginationComponent,
    PublishDocumentsComponent,
    MarkedEditDoneComponent,
    PopoverDirective,
    PopoverComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    CustomDialogComponent,
    AddPeopleComponent,
    ConfirmLeadAcceptDialogComponent,
    AddAdministratorComponent,
    AddCommitteeComponent,
    DisableEventComponent
  ]
})
export class SharedModule {}
