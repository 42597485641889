import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, switchMap, takeWhile } from 'rxjs/operators';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { OrganisationService } from '@app/core/organisation.service';
import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import Chart from 'chart.js';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { BackendErrorService } from '../backenederror.service';
import { MatDialog } from '@angular/material/dialog';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };
export const compare = (v1: any, v2: any) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
import { ManageControlsComponent } from './manage-controls/manage-controls.component';
import { Subscription, timer } from 'rxjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SettingsService } from '@app/settings/settings.service';
import { MixpanelService } from '@app/mixpanel/mixpanel.service';
export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {
  @Input() sortable: string;
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
interface DropdownSettingCategory {
  singleSelection: boolean;
  idField: string;
  textField: string;
  enableCheckAll: boolean;
  allowSearchFilter: boolean;
  itemsShowLimit: number;
}

@Component({
  selector: 'app-org-dashboard',
  templateUrl: './orgDashboard.component.html',
  styleUrls: ['./orgDashboard.component.scss']
})
export class OrgDashboardComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  payload: any = {};
  public Editor = ClassicEditor;
  public config = {
    toolbar: ['bold', 'italic', 'underline', 'strikethrough']
  };
  currentDate = new Date();
  isEditing = false;
  backend_error: any;
  searchText: string = '';
  inputValues: { value: string }[] = [];
  tabMapper = {
    Policies: 0,
    Procedures: 1,
    Evidence: 2,
    History: 3
  };
  categoriesMapper: any = {};
  categoriesData: any;
  overallDashboardData: any;
  actionType = 'controlOverview';
  chartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: true
    },
    cutoutPercentage: 80,
    maintainAspectRatio: false
  };
  scrollLoader = true;
  isScrollable = true;
  removePolicyProcedure = true;
  procedureData: any;
  search_title: any;
  selectedProgram = '';
  hiddenInput = new FormControl();
  isOpen = false;
  isLoading = false;
  isLoadingButton = false;
  loadChart = true;
  isDataLoading = false;
  error: any;
  showDefaultDocData: boolean = false;
  default_org_type: string = '';
  selectedDate: any = this.currentDate
    .toLocaleDateString('en-GB')
    .split('/')
    .join('-');
  removePayload: any = [];
  dashboardProgramData: any = [];
  drillDownEventData: any = [];
  listOfCompliance: any;
  organisationID: string;
  guidSubscription: any;
  drillDownTableData: any;
  drillDownEventTypeSelected: string;
  event_Type: any;
  confirmPolicyData: any = [];
  overviewPage = 1;
  loaderArr: any = [];
  accArray: any[] = [];
  loading = false;
  opened = false;
  evidenceData: boolean[] = [];
  selectedEvidenceValues: string[] = [];
  policyAndProcedurePolicies: boolean[] = [];
  policyAndProcedureProcedures: boolean[] = [];
  tabType: any;
  EvidenceList: any;
  EvidenceTestList: any;
  addEvidenceData: any;
  addPolicyData: any;
  updatedEvidenceList: any;
  page: number;
  committeeName: any;
  modalScrollDistance = 0.2;
  modalScrollThrottle = 30;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  paginatedDataUrl: any;
  controlGuid: any;
  selectedIndex = 0;
  policyData: any;
  evidenceDetailsData: any;
  tabName = 'Policies';
  historyLog: any;
  selectedPolicyAndProcedureGuids: any[] = [];
  isDuplicateText: boolean = false;
  applicableGuid: any[] = [];
  columnsToDisplay = ['name', 'frequency', 'action'];
  private chartSubscription: Subscription = new Subscription();
  params: any = {
    category: [],
    status: [],
    title: '',
    applicable: true,
    status_order: ''
  };
  filters: any = {
    category: [],
    status: [],
    title: '',
    applicable: true,
    status_order: ''
  };
  statusMapper = {
    COMPLETED: 'Completed',
    INCOMPLETE: 'Incomplete',
    PENDING: 'Pending'
  };
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChild(MatSort) sort: MatSort;
  selectedRowData: any;
  editedCkeditorData: string = '';
  dropdownSettingsForCategory: DropdownSettingCategory = {
    singleSelection: false,
    idField: 'textId',
    textField: 'title',
    enableCheckAll: false,
    allowSearchFilter: false,
    itemsShowLimit: 1
  };
  dropdownSettingsForStatus: DropdownSettingCategory = {
    singleSelection: false,
    idField: 'textId',
    textField: 'title',
    enableCheckAll: false,
    allowSearchFilter: false,
    itemsShowLimit: 1
  };

  controlsData: any = [];
  overallSummary: any = {};
  historyEnabled: boolean = false;
  overallSummaryDefault: any = {
    pgm_unit_status: {
      PENDING: 0,
      INCOMPLETE: 0,
      COMPLETED: 0
    },
    policy_status: {
      published: 0,
      unpublished: 0,
      total: 0
    },
    procedure_status: {
      published: 0,
      unpublished: 0,
      total: 0
    },
    evidence_status: {
      good_standing: 0,
      not_participated: 0,
      danger: 0,
      warning: 0,
      total: 0
    }
  };
  chartManager = {};
  dataSource = new MatTableDataSource<any>(this.controlsData);
  toppings = new FormControl('');
  value = '';
  statuses: any = [];
  categories: any = [];
  tabIndex: any = 0;
  totalCount = 0;
  today = this.currentDate
    .toLocaleDateString('en-GB')
    .split('/')
    .join('-');
  lastMonth = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 1, 1)
    .toLocaleDateString('en-GB')
    .split('/')
    .join('-');
  lastQuarter = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth() - 3, 1)
    .toLocaleDateString('en-GB')
    .split('/')
    .join('-');
  lastYear = new Date(this.currentDate.getFullYear() - 1, 0, 1)
    .toLocaleDateString('en-GB')
    .split('/')
    .join('-');
  customDate: any = '';
  columns = [
    {
      columnDef: 'code',
      header: 'Code',
      cell: (element: any) => `${element.code}`
    },
    {
      columnDef: 'control',
      header: 'Control',
      cell: (element: any) => `${element.control}`
    },
    {
      columnDef: 'policies',
      header: 'Policies',
      cell: (element: any) => `${element.policy}`
    },
    {
      columnDef: 'procedures',
      header: 'Procedures',
      cell: (element: any) => `${element.procedure}`
    },
    {
      columnDef: 'evidence',
      header: 'Evidence',
      cell: (element: any) => `${element.evidence}`
    },
    {
      columnDef: 'status',
      header: 'Status',
      cell: (element: any) => `${element.status}`
    }
    // {
    //   columnDef: 'action',
    //   header: '',
    //   cell: (element: any) => `${element}`
    // }
  ];
  displayedColumns: string[] = this.columns.map(c => c.columnDef);
  isAscending: boolean = true;
  isGrcEnabled: boolean = false;
  isdefaultDoc: boolean;
  documentsStatus: any = [];
  conditionMet: boolean = false;
  statusSub: Subscription;
  isSignatureLoading = false;
  isDownloading = false;
  file: any;
  size: any;
  file_type: any;
  file_type_error: boolean = false;
  file_size_error: boolean = false;
  signature: any;
  userName: any;
  fonts = ['italic 32px Pacifico', 'italic 32px Yellowtail', 'italic 32px Damion', 'italic 32px Lobster'];
  UserSignature: any = null;
  certificateData: any = {};
  loginOnboardFlag: boolean = false;
  totalDocumentCount: number = 0;
  selectedTab: 'documents' | 'tests' = 'documents';

  constructor(
    private router: Router,
    private orgDashboardService: OrganisationDashboardService,
    private orgService: OrganisationService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private backendErrorService: BackendErrorService,
    public dialog: MatDialog,
    private settingsService: SettingsService,
    private mixpanel: MixpanelService
  ) {
    this.loginOnboardFlag = JSON.parse(localStorage.getItem('normal_org_onboarded'));
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(org_guid => {
      this.organisationID = org_guid;
      this.loading = true;
      this.orgDashboardService.isGrcEnabled(this.organisationID).subscribe((data: boolean) => {
        this.isGrcEnabled = data['is_grc'];
        this.isdefaultDoc = data['show_default_doc_name'];
        this.loading = false;
        if (this.isGrcEnabled) {
          this.getCertificateDetails();
          this.getUserSignature();
          this.programTypeGrc(this.organisationID);
        } else {
          this.programType(this.organisationID);
        }
      });

      this.getFiltersData();
      this.closeDrillDown();
    });
  }
  getCertificateDetails() {
    this.orgService.getCertificateDetails(this.organisationID).subscribe((data: any) => {
      this.certificateData = data;
      const itemsPerColumn =
        this.certificateData.documents_list.length > 1
          ? Math.ceil(this.certificateData.documents_list.length / 2)
          : this.certificateData.documents_list.length;
      const firstColumn = document.getElementById('firstColumn');
      const secondColumn = document.getElementById('secondColumn');
      this.certificateData.documents_list.forEach((item: any, index: number) => {
        const listItem = document.createElement('li');
        listItem.textContent = item;
        listItem.classList.add('m-1');
        if (index < itemsPerColumn) {
          firstColumn.appendChild(listItem);
        } else {
          secondColumn.appendChild(listItem);
        }
      });
    });
  }
  generatePdf() {
    this.mixpanel.sendCBEvent('download_certificate');
    const data = document.getElementById('contentToConvert');
    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');

        const marginLeft = 2;

        const position = (pageHeight - imgHeight) / 2;

        pdf.addImage(contentDataURL, 'PNG', marginLeft, position, imgWidth - marginLeft, imgHeight);
        pdf.save('compliance-certificate.pdf');
      });
    }
    this.isDownloading = false;
  }
  loadGoogleFonts(user_name: string) {
    this.settingsService.GenerateSignature(user_name, this.fonts);
  }

  getUserSignature() {
    this.isSignatureLoading = true;
    this.settingsService.getUserSignature(this.organisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }
  async addImageToCanvas() {
    const imgElement = document.getElementById('onlineImage') as HTMLImageElement;
    const imgUrl = imgElement.src;
    imgElement.src = await this.convertImageToBase64(imgUrl);
  }
  async convertImageToBase64(url: string): Promise<string> {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }
  async generatePdfWithOnlineImages() {
    this.isDownloading = true;
    await this.addImageToCanvas();
    setTimeout(() => {
      this.generatePdf();
    }, 3000);
  }
  toggleSideBar(lable?: any) {
    this.tabName = lable;
    this.opened = !this.opened;
    if (this.opened && this.tabName === 'Policies') {
      this.selectedIndex = 0;
    }
    switch (this.tabName) {
      case 'Policies':
        this.getPolicieList(this.selectedRowData.guid);
        break;
      case 'Procedures':
        this.getProcedureList(this.selectedRowData.guid);
        break;
      case 'Evidence':
        this.getEvidenceList(this.selectedRowData.guid);
        this.getTestsList(this.selectedRowData.guid);
        break;
      case 'History':
        this.getHistoryDetails(this.selectedRowData.guid);
        break;
    }
  }
  isSidenavOpen = false;

  onSidenavOpen(open: boolean): void {
    this.isSidenavOpen = open;
  }

  closeSidenav(): void {
    this.isSidenavOpen = false;
  }
  startEditing() {
    this.isEditing = true;
  }

  onChangeCkeditor({ editor }: any) {
    const data = editor.getData();
    this.editedCkeditorData = data;
  }

  finishEditing(save: boolean, controls_data: any, data: any) {
    if (save) {
      const payload = {
        notes: this.editedCkeditorData
      };
      this.orgDashboardService
        .updateNotes(this.organisationID, this.selectedProgram, controls_data.guid, payload)
        .subscribe(
          data => {
            this.selectedRowData.details = data.details;
            this.programTypeGrc(this.organisationID);
          },
          error => {
            this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
              this.translate.get(this.backend_error).subscribe(res => {
                this.toaster.showError(res);
              });
            });
          }
        );
    } else {
      this.editedCkeditorData = this.selectedRowData?.details.notes;
    }
    this.isEditing = false;
  }

  updateSearchText(x: any) {
    this.searchText = x.target.value;
  }

  search(search_title: string, type: any) {
    this.search_title = search_title;
    switch (type) {
      case 'Policies':
        this.orgDashboardService
          .getAllDocumentVersionsPagination(
            this.organisationID,
            (this.page = 1),
            this.selectedRowData.guid,
            'Policies',
            this.search_title
          )
          .subscribe(res => {
            this.isDataLoading = false;
            this.scrollLoader = false;
            this.addPolicyData = res.results;
            this.paginatedDataUrl = res;
          });
        break;
      case 'Procedures':
        this.orgDashboardService
          .getAllDocumentVersionsPagination(
            this.organisationID,
            (this.page = 1),
            this.selectedRowData.guid,
            'Procedures',
            this.search_title
          )
          .subscribe(res => {
            this.isDataLoading = false;
            this.scrollLoader = false;
            this.addPolicyData = res.results;
            this.paginatedDataUrl = res;
          });
        break;
      case 'Evidence':
        this.orgDashboardService
          .searchEvidenceList(this.organisationID, (this.page = 1), this.selectedRowData.guid, this.search_title)
          .subscribe(
            data => {
              this.addEvidenceData = data.results;
            },
            error => {
              this.error = error.error.message;
            }
          );
        break;
    }
  }

  clearSearch() {
    this.searchText = ''; // Set searchText to an empty string to clear the input
  }

  deletePolicyAndProcedure(type: string, controls_data: any) {
    if (type === 'Policies') {
      let payload = this.selectedPolicyAndProcedureGuids.map(({ policyGuid, applicableGuid }: any) => {
        const basePayload: any = {
          policy: policyGuid,
          remove_policy: true
        };

        if (applicableGuid) {
          basePayload.remove_policy = false;
          basePayload.policies = applicableGuid;
        }

        return basePayload;
      });

      this.orgDashboardService.removePolicy(this.organisationID, controls_data.guid, payload).subscribe(
        res => {
          this.updateTableData(this.controlsData, controls_data.guid, res, 'policies', 'status', res['summary']);
          this.policyData = res.results;
          this.selectedPolicyAndProcedureGuids = [];
          this.applicableGuid = [];
          this.translate.get('Policy removed.').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        error => {
          this.error = error.error[0];
          this.translate.get(this.error).subscribe(res => {
            this.toaster.showError(res);
          });
        }
      );
    } else if (type === 'Procedures') {
      let payload = this.selectedPolicyAndProcedureGuids.map(({ policyGuid, applicableGuid }: any) => {
        const basePayload: any = {
          procedure: policyGuid,
          remove_procedure: true
        };

        if (applicableGuid) {
          basePayload.remove_procedure = false;
          basePayload.procedure_names = applicableGuid;
        }

        return basePayload;
      });
      this.orgDashboardService.removeProcedure(this.organisationID, controls_data.guid, payload).subscribe(
        res => {
          this.updateTableData(this.controlsData, controls_data.guid, res, 'procedures', 'status', res['summary']);
          this.policyData = res.results;
          this.selectedPolicyAndProcedureGuids = [];
          this.applicableGuid = [];
          this.translate.get('Procedure removed.').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        error => {
          this.error = error.error[0];
          this.translate.get(this.error).subscribe(res => {
            this.toaster.showError(res);
          });
        }
      );
    }
  }

  removeitem(type: string, controls_data: any) {
    if (type === 'Policies') {
      this.getPolicieList(controls_data.guid);
    } else if (type === 'Procedures') {
      this.getProcedureList(controls_data.guid);
    }
  }
  checkInputValues(event: any): void {
    const values = this.inputValues.map(inputValue => inputValue.value);
    const hasDuplicates = values.some((value, index) => values.indexOf(value) !== index);
    if (hasDuplicates) {
      this.isDuplicateText = true;
    } else {
      this.isDuplicateText = false;
    }
  }
  updateTableData(
    controlsData: any,
    controlsDataGuid: any,
    tableData: any,
    tabName: any,
    status: any,
    summary: any = {}
  ) {
    this.loadChart = true;
    for (let i = 0; i < controlsData.length; i++) {
      if (controlsData[i]['guid'] == controlsDataGuid) {
        controlsData[i][tabName] = tableData['results'];
        controlsData[i][status] = tableData['status'];
        break;
      }
    }

    this.chartSubscription = this.orgDashboardService
      .getControlsData(this.organisationID, this.selectedProgram, this.filters, 1)
      .subscribe(
        (res: any) => {
          let { pgm_unit_status, evidence_status, policy_status, procedure_status } = res['summary'];
          this.overallSummary = res['summary'];
          this.overallSummary['pgm_unit_status']['total'] = this.checkZero(res['summary']['pgm_unit_status']);
          const controlChartData = this.getChartData(
            ['Completed', 'Incomplete', 'Pending'],
            [pgm_unit_status['COMPLETED'], pgm_unit_status['INCOMPLETE'], pgm_unit_status['PENDING']],
            ['#0D9647', '#FA7011', '#E1E6EB']
          );
          this.createDoughnutChart(
            'controlChart',
            controlChartData.labels,
            controlChartData.data,
            controlChartData.colors,
            'Control Status'
          );

          const policiesChartData = this.getChartData(
            ['Published', 'Not Published'],
            [policy_status['published'], policy_status['unpublished']],
            ['#0D9647', '#FA7011']
          );
          this.createDoughnutChart(
            'policiesStatus',
            policiesChartData.labels,
            policiesChartData.data,
            policiesChartData.colors,
            'Policies Status'
          );

          const procedureChartData = this.getChartData(
            ['Published', 'Not Published'],
            [procedure_status['published'], procedure_status['unpublished']],
            ['#0D9647', '#FA7011']
          );
          this.createDoughnutChart(
            'procedureStatus',
            procedureChartData.labels,
            procedureChartData.data,
            procedureChartData.colors,
            'Procedure Status'
          );

          const evidenceChartData = this.getChartData(
            ['Good Standing', 'Not Participated', 'Danger', 'Warning'],
            [
              evidence_status['good_standing'],
              evidence_status['not_participated'],
              evidence_status['danger'],
              evidence_status['warning']
            ],
            ['#0D9647', '#8C96A1', '#DC3545', '#FBBC05']
          );
          this.createDoughnutChart(
            'evidenceStatus',
            evidenceChartData.labels,
            evidenceChartData.data,
            evidenceChartData.colors,
            'Evidence Status'
          );
          this.loadChart = false;
          this.isScrollable = false;
          this.scrollLoader = false;
        },
        error => {
          this.loadChart = false;
          this.isScrollable = false;
          this.scrollLoader = false;
        }
      );
  }
  confirmPolicyProcedure(type: string, controls_data: any) {
    this.isLoadingButton = true;
    if (type === 'Policies') {
      let payload;
      payload = {
        policy_doc: this.confirmPolicyData['guid'],
        policy_names: this.inputValues.map(inputValue => inputValue.value)
      };
      const transformedPayload = [payload];
      this.orgDashboardService.addPolicy(this.organisationID, controls_data.guid, transformedPayload).subscribe(
        data => {
          this.isLoadingButton = false;
          this.updateTableData(this.controlsData, controls_data.guid, data, 'policies', 'status', data['summary']);
          this.changeAction('Policies', 'Policies');
          this.attachItem('Policies');
          this.policyData = data.results;
          this.translate.get('Policy attached.').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        error => {
          this.isLoadingButton = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
            this.translate.get(this.backend_error).subscribe(res => {
              this.toaster.showError(res);
            });
          });
        }
      );
    } else if (type === 'Procedures') {
      let payload;
      payload = {
        procedure_doc: this.confirmPolicyData['guid'],
        procedure_names: this.inputValues.map(inputValue => inputValue.value)
      };
      const transformedPayload = [payload];
      this.orgDashboardService.addProcedure(this.organisationID, controls_data.guid, transformedPayload).subscribe(
        data => {
          this.isLoadingButton = false;
          this.updateTableData(this.controlsData, controls_data.guid, data, 'procedures', 'status', data['summary']);
          this.changeAction('Procedures', 'Procedures');
          this.attachItem('Procedures');
          this.policyData = data.results;
          this.translate.get('Procedure attached.').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        error => {
          this.isLoadingButton = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
            this.translate.get(this.backend_error).subscribe(res => {
              this.toaster.showError(res);
            });
          });
        }
      );
    }
  }
  selectTab(tab: 'documents' | 'tests') {
    this.selectedTab = tab;
  }
  onEvidenceDetailsClick(evidenceGuid: any) {
    this.isDataLoading = true;
    this.orgDashboardService.getEvidenceDetails(this.organisationID, evidenceGuid).subscribe(
      data => {
        this.isDataLoading = false;
        this.evidenceDetailsData = data;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  getEvidenceList(program: any) {
    this.isDataLoading = true;
    this.orgDashboardService.getEvidenceList(this.organisationID, program).subscribe(
      data => {
        this.isDataLoading = false;
        this.EvidenceList = data;
        this.selectedEvidenceValues = [];
      },
      error => {
        this.isDataLoading = false;
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  getTestsList(program: any) {
    this.isDataLoading = true;
    this.orgDashboardService.getTestList(this.organisationID, program).subscribe(
      data => {
        this.isDataLoading = false;
        this.EvidenceTestList = data;
        console.log(this.EvidenceTestList);
        this.selectedEvidenceValues = [];
      },
      error => {
        this.isDataLoading = false;
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  getHistoryDetails(program: any) {
    this.isDataLoading = true;
    this.orgDashboardService.getHistoryDetails(this.organisationID, program).subscribe(
      data => {
        this.isDataLoading = false;
        this.historyLog = data;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  getInitials(name: string): string {
    if (!name) {
      return '';
    }
    const nameParts = name.split(' ');
    const initials = nameParts.map(part => part.charAt(0).toUpperCase()).join('');

    return initials;
  }
  //add Evidence
  addEvidences(evidata: any) {
    this.isLoadingButton = true;
    const payload = {
      cb_task: this.selectedEvidenceValues
    };
    this.orgDashboardService.addEvidences(this.organisationID, evidata.guid, payload).subscribe(
      data => {
        this.isLoadingButton = false;
        this.EvidenceList = data.results;
        this.updateTableData(
          this.controlsData,
          this.selectedRowData.guid,
          data,
          'evidences',
          'status',
          data['summary']
        );
        this.attachItem('Evidence');
        this.selectedEvidenceValues = [];

        this.translate.get('Task attached.').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error[0];
        this.translate.get(this.error).subscribe(res => {
          this.toaster.showError(res);
        });
      }
    );
  }
  removesingleEvidence(evidata: any) {
    this.isLoadingButton = true;
    const payload = {
      cb_task: [evidata.cb_task.review_event_guid]
    };
    this.orgDashboardService.removeEvidences(this.organisationID, evidata.org_program_unit, payload).subscribe(
      data => {
        this.isLoadingButton = false;
        this.EvidenceList = data.results;
        this.updateTableData(
          this.controlsData,
          this.selectedRowData.guid,
          data,
          'evidences',
          'status',
          data['summary']
        );
        this.selectedEvidenceValues = [];

        this.translate.get('Task removed.').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error[0];
        this.translate.get(this.error).subscribe(res => {
          this.toaster.showError(res);
        });
      }
    );
  }
  // removeEvidence(evidata: any) {
  //   this.isLoadingButton = true;
  //   const payload = {
  //     // other properties in your payload
  //     cb_task: this.selectedEvidenceValues
  //   };
  //   this.orgDashboardService.removeEvidences(this.organisationID, evidata.guid, payload).subscribe(
  //     data => {
  //       this.isLoadingButton = false;
  //       this.EvidenceList = data.results;
  //       this.updateTableData(
  //         this.controlsData,
  //         this.selectedRowData.guid,
  //         data,
  //         'evidences',
  //         'status',
  //         data['summary']
  //       );
  //       this.selectedEvidenceValues = [];

  //       this.translate.get('Task removed.').subscribe(res => {
  //         this.toaster.showSuccess(res);
  //       });
  //     },
  //     error => {
  //       this.error = error.error[0];
  //       this.translate.get(this.error).subscribe(res => {
  //         this.toaster.showError(res);
  //       });
  //     }
  //   );
  // }
  attachItem(type: string) {
    this.tabType = type;
    this.searchText = '';
    this.isDataLoading = true;
    switch (type) {
      case 'Policies':
        this.page = 1;
        this.orgDashboardService
          .getAllDocumentVersionsPagination(
            this.organisationID,
            this.page,
            this.selectedRowData.guid,
            'Policies',
            this.searchText
          )
          .subscribe(res => {
            this.isDataLoading = false;
            this.addPolicyData = res.results;
            this.paginatedDataUrl = res;
          });
        break;
      case 'Procedures':
        this.page = 1;
        this.orgDashboardService
          .getAllDocumentVersionsPagination(
            this.organisationID,
            this.page,
            this.selectedRowData.guid,
            'Procedures',
            this.searchText
          )
          .subscribe(res => {
            this.isDataLoading = false;
            this.addPolicyData = res.results;
            this.paginatedDataUrl = res;
          });
        break;
      case 'Evidence':
        this.page = 1;
        this.orgDashboardService
          .getAddRemoveEvidenceList(this.organisationID, this.page, this.selectedRowData.guid, this.searchText)
          .subscribe(res => {
            this.isDataLoading = false;
            this.addEvidenceData = res.results;
            this.paginatedDataUrl = res;
            this.committeeName = this.addEvidenceData.map((item: any) =>
              item['committee_name'].map((committee: any) => committee.name).join(', ')
            );
          });
        break;
    }
  }
  onScrollEvidenceData() {
    this.scrollLoader = true;
    this.orgDashboardService
      .getAddRemoveEvidenceList(this.organisationID, (this.page += 1), this.selectedRowData.guid, this.searchText)
      .subscribe(
        res => {
          this.scrollLoader = false;
          this.paginatedDataUrl = res;
          this.addEvidenceData = this.addEvidenceData.concat(res.results);
          this.committeeName = this.committeeName.concat(
            res.results.map((item: any) => item['committee_name'].map((committee: any) => committee.name).join(', '))
          );
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  onScrollPolicyProcedureData() {
    this.scrollLoader = true;
    this.orgDashboardService

      .getAllDocumentVersionsPagination(
        this.organisationID,
        (this.page += 1),
        this.selectedRowData.guid,
        this.tabType,
        this.searchText
      )
      .subscribe(
        res => {
          this.scrollLoader = true;
          this.paginatedDataUrl = res;
          this.addPolicyData = this.addPolicyData.concat(res.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollEvidenceData();
    } else {
      this.scrollLoader = false;
    }
  }
  onScrollPolicy() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollPolicyProcedureData();
    } else {
      this.scrollLoader = false;
    }
  }
  onScrollUp() {
    this.scrollLoader = false;
  }
  isButtonDisabled(): boolean {
    // Return true if any input is empty, otherwise false
    return this.inputValues.some(inputValue => !inputValue.value);
  }
  addInputBox() {
    this.inputValues.push({ value: '' });
  }
  removeInputBox(index: number) {
    this.isDuplicateText = false;
    this.inputValues.splice(index, 1);
  }

  confirmPolicy(title: any, guid: any, status: any) {
    this.confirmPolicyData['title'] = title;
    this.confirmPolicyData['guid'] = guid;
    this.confirmPolicyData['status'] = status;
  }

  toggle(index: any): void {
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
    }
  }

  isExpansionDetailRow(i: number, row: any): boolean {
    return row.hasOwnProperty('expandedDetail');
  }
  resetPolicyAndProcedureToggles(): void {
    this.policyAndProcedurePolicies = Array(this.policyAndProcedurePolicies.length).fill(false);
    this.policyAndProcedureProcedures = Array(this.policyAndProcedureProcedures.length).fill(false);
  }

  onTabChanged(event: MatTabChangeEvent, selectedData: any): void {
    this.tabName = event.tab.textLabel;
    this.resetPolicyAndProcedureToggles();
    this.tabIndex = event.index;
    switch (this.tabIndex) {
      case 0:
        this.getPolicieList(selectedData.guid);
        break;
      case 1:
        this.getProcedureList(selectedData.guid);
        break;
      case 2:
        this.selectedTab = 'documents';
        this.getEvidenceList(selectedData.guid);
        this.getTestsList(selectedData.guid);
        break;
      case 3:
        this.getHistoryDetails(selectedData.guid);
        break;
    }
  }
  formatStatus(status: string): string {
    return status
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
  }

  getPolicieList(program: any) {
    this.isDataLoading = true;
    this.orgDashboardService.getPolicyList(this.organisationID, program).subscribe(
      data => {
        this.policyData = data;
        this.isDataLoading = false;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  getProcedureList(program: any) {
    this.isDataLoading = true;
    this.orgDashboardService.getProcedureList(this.organisationID, program).subscribe(
      data => {
        this.policyData = data;
        this.isDataLoading = false;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  hasNonEmptyNames(policyNames: any[]): boolean {
    return policyNames?.some(policy => policy.name !== '');
  }

  // Count the number of non-empty policy names
  countNonEmptyNames(policyNames: any[]): number {
    return policyNames?.reduce((count, policy) => (policy.name !== '' ? count + 1 : count), 0);
  }
  // Function to clear loader array.
  clearLoader(value: any): void {
    const index = this.loaderArr.indexOf(value);
    if (index > -1) {
      this.loaderArr.splice(index, 1);
    }
  }
  // Running event ends

  drillDownData($event: any) {
    this.drillDownTableData = $event;
    this.drillDownEventTypeSelected = this.drillDownTableData['event_type'].replace('_', ' ');
    // tslint:disable-next-line:max-line-length
    this.router
      .navigate(['/organisation/history'], {
        queryParams: {
          type: this.drillDownTableData['event_type'].toUpperCase(),
          status: this.drillDownTableData['event_desc']['slug']
        }
      })
      .then(r => {
        console.log('worked');
      });
  }

  closeDrillDown() {
    this.drillDownEventData = [];
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting columns
    if (direction === '') {
      // this.drillDownEventData = this.drillDownEventData;
    } else {
      this.drillDownEventData = [...this.drillDownEventData].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    this.statusSub?.unsubscribe();
    this.chartSubscription.unsubscribe();
  }

  DataCheck(data: any = []) {
    const emptyCheck = data.reduce((prev: any, next: any) => prev + next.value, 0);
    if (emptyCheck === 0) {
      return true;
    } else {
      return false;
    }
  }

  programTypeGrc(id: any) {
    this.isLoading = true;
    this.orgDashboardService
      .organisationalProgram(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((data: any = []) => {
        this.loading = false;
        this.listOfCompliance = data;
        this.selectedProgram = this.listOfCompliance[0]['guid'];
        this.getControls(this.selectedProgram);
      });
  }

  programType(id: any) {
    this.isLoading = true;
    this.orgDashboardService
      .organisationalProgram(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any = []) => {
          this.loading = false;
          this.listOfCompliance = data;
          this.selectedProgram = this.listOfCompliance[0]['guid'];
          this.selectedProgramRequest(this.selectedProgram);
        },
        (error: any) => {
          this.error = error;
        }
      );
  }

  selectedProgramRequest(programGuid: any) {
    this.dashboardResults(this.organisationID, programGuid);
  }

  checkZero(data = {}) {
    return Object.values(data).reduce((accumulator: number, currentValue: number) => {
      return accumulator + currentValue;
    }, 0);
  }
  programChangeGrc(event: any) {
    this.selectedProgram = event;
    this.overviewPage = 1;
    this.selectedDate = this.today;
    this.loadChart = true;
    this.getControls(this.selectedProgram, 1, true);
    this.closeDrillDown();
  }
  programChange(event: any) {
    this.selectedProgram = event;
    this.dashboardResults(this.organisationID, this.selectedProgram);
    this.closeDrillDown();
  }
  dashboardResults(order_id: string, program_id: string) {
    this.isLoading = true;
    this.orgDashboardService
      .organisationalDashboardData(order_id, program_id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loading = false;
        })
      )
      .subscribe(
        (data: any = []) => {
          this.dashboardProgramData = data;
          const index = this.dashboardProgramData.findIndex((obj: any) => {
            return obj.event_type === 'overall events';
          });
          if (index > -1) {
            this.overallDashboardData = this.dashboardProgramData.splice(index, 1);
          }
        },
        (error: any) => {
          this.error = error;
        }
      );
  }

  getControls(programGuid: string, page = 1, resetData = false, initialGraphRender = false): void {
    this.scrollLoader = true;
    this.historyEnabled = false;
    this.chartSubscription = this.orgDashboardService
      .getControlsData(this.organisationID, programGuid, this.filters, page)
      .subscribe(
        res => {
          let { pgm_unit_status, evidence_status, policy_status, procedure_status } = res['summary'];
          this.controlsData = resetData
            ? res['results']['results']
            : this.controlsData.concat(res['results']['results']);
          if (!this.loginOnboardFlag && !this.conditionMet && this.isdefaultDoc) {
            this.orgDashboardService.getOrgDocumentStatusData(this.organisationID).subscribe(response => {
              this.totalDocumentCount = response['total_count'];
              // pgm_unit status mock
              pgm_unit_status['COMPLETED'] = 0;
              pgm_unit_status['INCOMPLETE'] = res['results']['count'];
              pgm_unit_status['PENDING'] = 0;
              pgm_unit_status['total'] = res['results']['count'];

              // policy status mock
              policy_status['published'] = this.totalDocumentCount;
              policy_status['unpublished'] = 0;
              policy_status['total'] = this.totalDocumentCount;
              // procedure status mock
              procedure_status['published'] = this.totalDocumentCount;
              procedure_status['unpublished'] = 0;
              procedure_status['total'] = this.totalDocumentCount;
              const controlChartData = this.getChartData(
                ['Completed', 'Incomplete', 'Pending'],
                [pgm_unit_status['COMPLETED'], pgm_unit_status['INCOMPLETE'], pgm_unit_status['PENDING']],
                ['#0D9647', '#FA7011', '#E1E6EB']
              );
              this.createDoughnutChart(
                'controlChart',
                controlChartData.labels,
                controlChartData.data,
                controlChartData.colors,
                'Control Status'
              );

              const policiesChartData = this.getChartData(
                ['Published', 'Not Published'],
                [policy_status['published'], policy_status['unpublished']],
                ['#0D9647', '#FA7011']
              );
              this.createDoughnutChart(
                'policiesStatus',
                policiesChartData.labels,
                policiesChartData.data,
                policiesChartData.colors,
                'Policies Status'
              );

              const procedureChartData = this.getChartData(
                ['Published', 'Not Published'],
                [procedure_status['published'], procedure_status['unpublished']],
                ['#0D9647', '#FA7011']
              );
              this.createDoughnutChart(
                'procedureStatus',
                procedureChartData.labels,
                procedureChartData.data,
                procedureChartData.colors,
                'Procedure Status'
              );

              const evidenceChartData = this.getChartData(
                ['Good Standing', 'Not Participated', 'Danger', 'Warning'],
                [
                  evidence_status['good_standing'],
                  evidence_status['not_participated'],
                  evidence_status['danger'],
                  evidence_status['warning']
                ],
                ['#0D9647', '#8C96A1', '#DC3545', '#FBBC05']
              );
              this.createDoughnutChart(
                'evidenceStatus',
                evidenceChartData.labels,
                evidenceChartData.data,
                evidenceChartData.colors,
                'Evidence Status'
              );
            });

            for (let i = 0; i < this.controlsData.length; i++) {
              this.controlsData[i]['is_uploaded'] = false;
            }
            this.startPeriodicFetch();
          }
          this.dataSource.data = this.controlsData;
          this.default_org_type = res['default_org_type'];
          this.totalCount = res['results']['count'];
          this.isScrollable = true;
          this.scrollLoader = false;
          if (!this.loadChart) return;

          this.overallSummary = res['summary'];
          this.overallSummary['pgm_unit_status']['total'] = this.checkZero(res['summary']['pgm_unit_status']);
          const controlChartData = this.getChartData(
            ['Completed', 'Incomplete', 'Pending'],
            [pgm_unit_status['COMPLETED'], pgm_unit_status['INCOMPLETE'], pgm_unit_status['PENDING']],
            ['#0D9647', '#FA7011', '#E1E6EB']
          );
          this.createDoughnutChart(
            'controlChart',
            controlChartData.labels,
            controlChartData.data,
            controlChartData.colors,
            'Control Status'
          );

          const policiesChartData = this.getChartData(
            ['Published', 'Not Published'],
            [policy_status['published'], policy_status['unpublished']],
            ['#0D9647', '#FA7011']
          );
          this.createDoughnutChart(
            'policiesStatus',
            policiesChartData.labels,
            policiesChartData.data,
            policiesChartData.colors,
            'Policies Status'
          );

          const procedureChartData = this.getChartData(
            ['Published', 'Not Published'],
            [procedure_status['published'], procedure_status['unpublished']],
            ['#0D9647', '#FA7011']
          );
          this.createDoughnutChart(
            'procedureStatus',
            procedureChartData.labels,
            procedureChartData.data,
            procedureChartData.colors,
            'Procedure Status'
          );

          const evidenceChartData = this.getChartData(
            ['Good Standing', 'Not Participated', 'Danger', 'Warning'],
            [
              evidence_status['good_standing'],
              evidence_status['not_participated'],
              evidence_status['danger'],
              evidence_status['warning']
            ],
            ['#0D9647', '#8C96A1', '#DC3545', '#FBBC05']
          );
          this.createDoughnutChart(
            'evidenceStatus',
            evidenceChartData.labels,
            evidenceChartData.data,
            evidenceChartData.colors,
            'Evidence Status'
          );
          if (initialGraphRender) {
            this.isdefaultDoc = false;
          }
          this.loadChart = false;
        },
        error => {
          this.isScrollable = false;
          this.scrollLoader = false;
        }
      );
  }
  getChartData(labels: any, data: any, colors: any) {
    const isEmpty = data.every((value: any) => value === 0);

    if (isEmpty) {
      return {
        labels: ['No Data'],
        data: [1],
        colors: ['#E1E6EB']
      };
    }

    return {
      labels: labels,
      data: data,
      colors: colors
    };
  }

  createDoughnutChart(chartId: string, labels: string[], data: number[], backgroundColor: string[], titleText: string) {
    let options = {
      responsive: true,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      cutoutPercentage: 82,
      maintainAspectRatio: false,
      title: {
        display: false
      }
    };
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    if (this.chartManager[chartId]) {
      this.chartManager[chartId].destroy();
    }
    const ctx = canvas?.getContext('2d');
    var myChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            borderWidth: 1
          }
        ]
      },
      options: options
    });
    this.chartManager[chartId] = myChart;
  }
  startPeriodicFetch() {
    const fetchInterval = 15000; // 15 seconds
    this.statusSub = timer(0, fetchInterval)
      .pipe(
        switchMap(() => this.orgDashboardService.getOrgDocumentStatusData(this.organisationID)),
        takeWhile(() => !this.conditionMet)
      )
      .subscribe(
        res => {
          this.documentsStatus = res['data'].filter((item: any) => item.is_uploaded);
          if (res['data'].every((item: any) => item.is_uploaded)) {
            this.conditionMet = true;
            this.loadChart = true;
            this.getControls(this.selectedProgram, 1, true, true);
            this.statusSub?.unsubscribe();
          }
          for (let i = 0; i < this.documentsStatus.length; i++) {
            for (let j = 0; j < this.controlsData.length; j++) {
              if (
                this.documentsStatus[i].category_name.toLowerCase().trim() ==
                this.controlsData[j].details.categories[0].toLowerCase().trim()
              ) {
                this.controlsData[j]['is_uploaded'] = true;
              }
            }
          }
          this.dataSource.data = this.controlsData;
        },
        error => {
          console.error('Error fetching data:', error);
        }
      );
  }

  getFiltersData() {
    this.orgDashboardService.getFiltersData(this.organisationID).subscribe(res => {
      let statusData = Object.keys(res['status']).map(item => {
        return { title: res['status'][item], id: item };
      });
      this.statuses = statusData;
      let categoryData = Object.keys(res['categories']).map(item => {
        return { title: res['categories'][item]['category'], id: res['categories'][item]['guid'] };
      });
      this.categoriesMapper = res['categories'];
      this.categories = categoryData;
    });
  }

  sortData() {
    this.overviewPage = 1;
    this.isScrollable = true;
    this.filters['status_order'] = this.filters['status_order'] === 'ASC' ? 'DESC' : 'ASC';
    this.getControls(this.selectedProgram, 1, true);
  }

  getStyle(condition: string) {
    let style: any = {};
    switch (condition) {
      case 'INCOMPLETE':
        style['background-color'] = '#ffe9d5';
        style['color'] = '#de6b07';
        break;
      case 'PENDING':
        style['background-color'] = '#dbdbdb';
        style['color'] = '#44444f';
        break;
      case 'COMPLETED':
        style['background-color'] = '#e1ffd3';
        style['color'] = '#0E9648';
        break;
    }
    return style;
  }
  onRowClick(row: any, column: string = '') {
    this.selectedRowData = row;
    this.categoriesData = this.convertToTitleCase(this.selectedRowData['details']['categories']);
  }
  convertToTitleCase(input: string): string {
    if (input == null) {
      return ''; //
    } else {
      const inputString = String(input);
      const words = inputString.split('_');
      const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

      return titleCaseWords.join(' ');
    }
  }

  toggleDatePicker() {
    this.isOpen = !this.isOpen;
  }

  onDatePickerClosed(event: any) {
    this.hiddenInput.setValue(event.date);
  }
  onCustomDateSelect(date: any) {
    this.customDate = `${date.day}-${date.month}-${date.year}`;
    this.getHistoricalData(this.customDate);
  }
  onDateSelect(event: any) {
    if (event.value == this.today) {
      this.overviewPage = 1;
      this.loadChart = true;
      this.getControls(this.selectedProgram, 1, true);
    } else if (event.value && event.value !== this.today) {
      this.getHistoricalData(event.value);
    }
  }

  downloadpdf(momLink: string, startTime: Date): void {
    const link = document.createElement('a');
    link.href = momLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  toggleEvidence(index: number) {
    this.addEvidenceData[index].evidenceData = !this.addEvidenceData[index].evidenceData;
  }
  shouldShowCommiteeDetails(index: number): boolean {
    const item = this.addEvidenceData[index];
    return item && item.evidenceData;
  }
  togglePolicyAndProcedure(index: number, isPolicyTab: boolean): void {
    if (isPolicyTab) {
      this.policyAndProcedurePolicies[index] = !this.policyAndProcedurePolicies[index];
    } else {
      this.policyAndProcedureProcedures[index] = !this.policyAndProcedureProcedures[index];
    }
  }

  showPolicyAndProcedureDetails(index: number, isPolicyTab: boolean): boolean {
    if (isPolicyTab) {
      return this.policyAndProcedurePolicies[index];
    } else {
      return this.policyAndProcedureProcedures[index];
    }
  }

  onPolicyProcedureSelect(event: any, policyGuid: any, type: any, applicableGuid?: any) {
    if (event.target.checked) {
      const existingEntry = this.selectedPolicyAndProcedureGuids.find(entry => entry.policyGuid === policyGuid);

      if (existingEntry) {
        // PolicyGuid already exists, add applicableGuid to the array
        existingEntry.applicableGuid = [...(existingEntry.applicableGuid || []), applicableGuid];
      } else {
        // New policyGuid, create a new entry
        this.selectedPolicyAndProcedureGuids.push({
          policyGuid,
          applicableGuid: applicableGuid ? [applicableGuid] : undefined
        });
      }
    } else {
      const existingEntry = this.selectedPolicyAndProcedureGuids.find(entry => entry.policyGuid === policyGuid);

      if (existingEntry.applicableGuid) {
        // PolicyGuid exists, remove applicableGuid from the array
        existingEntry.applicableGuid = existingEntry.applicableGuid.filter((guid: any) => guid !== applicableGuid);

        // If applicableGuid array becomes empty, remove the entire entry
        if (existingEntry.applicableGuid.length === 0) {
          this.selectedPolicyAndProcedureGuids = this.selectedPolicyAndProcedureGuids.filter(
            entry => entry.policyGuid !== policyGuid
          );
        }
      } else if (existingEntry) {
        // PolicyGuid exists, remove applicableGuid from the array
        this.selectedPolicyAndProcedureGuids = this.selectedPolicyAndProcedureGuids.filter(
          (guid: any) => guid.policyGuid !== policyGuid
        );
      }
    }
    // Update removePolicyProcedure based on whether applicableGuid is present
    this.removePolicyProcedure = !applicableGuid;
  }
  onCheckboxChange(event: any, taskName: string) {
    if (event.target.checked) {
      this.selectedEvidenceValues.push(taskName);
    } else {
      this.selectedEvidenceValues = this.selectedEvidenceValues.filter(value => value !== taskName);
    }
  }
  getHistoricalData(date: any) {
    this.scrollLoader = true;
    this.orgDashboardService.gethistoricalData(this.organisationID, this.selectedProgram, date).subscribe(res => {
      this.dataSource.data = res[0] ? JSON.parse(res[0].snapshot)?.program_units_data : [];
      let { pgm_unit_status, evidence_status, policy_status, procedure_status } = res[0]
        ? JSON.parse(res[0].snapshot)['summary']
        : this.overallSummaryDefault;
      this.overallSummary = res[0] ? JSON.parse(res[0].snapshot)?.summary : this.overallSummaryDefault;
      this.scrollLoader = false;
      this.totalCount = this.dataSource.data.length;
      this.historyEnabled = true;
      const controlChartData = this.getChartData(
        ['Completed', 'Incomplete', 'Pending'],
        [pgm_unit_status['COMPLETED'], pgm_unit_status['INCOMPLETE'], pgm_unit_status['PENDING']],
        ['#0D9647', '#FA7011', '#E1E6EB']
      );
      this.createDoughnutChart(
        'controlChart',
        controlChartData.labels,
        controlChartData.data,
        controlChartData.colors,
        'Control Status'
      );

      const policiesChartData = this.getChartData(
        ['Published', 'Not Published'],
        [policy_status['published'], policy_status['unpublished']],
        ['#0D9647', '#FA7011']
      );
      this.createDoughnutChart(
        'policiesStatus',
        policiesChartData.labels,
        policiesChartData.data,
        policiesChartData.colors,
        'Policies Status'
      );

      const procedureChartData = this.getChartData(
        ['Published', 'Not Published'],
        [procedure_status['published'], procedure_status['unpublished']],
        ['#0D9647', '#FA7011']
      );
      this.createDoughnutChart(
        'procedureStatus',
        procedureChartData.labels,
        procedureChartData.data,
        procedureChartData.colors,
        'Procedure Status'
      );

      const evidenceChartData = this.getChartData(
        ['Good Standing', 'Not Participated', 'Danger', 'Warning'],
        [
          evidence_status['good_standing'],
          evidence_status['not_participated'],
          evidence_status['danger'],
          evidence_status['warning']
        ],
        ['#0D9647', '#8C96A1', '#DC3545', '#FBBC05']
      );
      this.createDoughnutChart(
        'evidenceStatus',
        evidenceChartData.labels,
        evidenceChartData.data,
        evidenceChartData.colors,
        'Evidence Status'
      );
      this.loadChart = false;
    });
  }
  changeAction(actionType = '', label: any): void {
    this.inputValues = [];
    this.selectedEvidenceValues = [];
    this.selectedPolicyAndProcedureGuids = [];
    this.applicableGuid = [];
    this.tabType = label;
    this.actionType = actionType;
    this.selectedIndex = this.tabMapper[label];
  }
  applyFilters(event: any, type: string, loadcharts = false) {
    this.loadChart = loadcharts;
    this.filters[type] = Array.isArray(event.value) ? event.value : event;
    this.dataSource.data = [];
    this.controlsData = [];
    this.overviewPage = 1;
    this.getControls(this.selectedProgram);
  }
  onApplicableChange(event: any) {
    this.showDefaultDocData = event.checked ? true : false;
    this.loadChart = true;
    this.filters.applicable = !event.checked;
    this.overviewPage = 1;
    this.getControls(this.selectedProgram, 1, true);
  }
  onScrollTable() {
    if (Math.ceil(this.totalCount / 8) <= this.overviewPage && !this.scrollLoader) return;
    this.statusSub?.unsubscribe();
    this.getControls(this.selectedProgram, (this.overviewPage += 1));
  }
  percentage(percent: number, total: number) {
    if (percent == 0 || total == 0) return 0;
    return ((percent / total) * 100).toFixed(1);
  }

  manageControls() {
    const dialogRef = this.dialog.open(ManageControlsComponent, {
      height: '680px',
      width: '1100px',
      hasBackdrop: true,
      disableClose: true,
      data: {
        organisationID: this.organisationID,
        programGuid: this.selectedProgram,
        defaultOrganisationType: this.default_org_type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getControls(this.selectedProgram, 1, true);
      }
    });
  }
}
