import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';
import { HomeComponent } from './home.component';
import { Shell } from '@app/shells/shell/shell.service';
import { ProgramConfigurationComponent } from './steps/program-configuration/program-configuration.component';
import { ProgramCreationComponent } from './steps/program-creation/program-creation.component';
import { QuickSurveyComponent } from './steps/quick-survey/quick-survey.component';
import { GenerateDocumentsComponent } from './steps/generate-documents/generate-documents.component';
import { ProfileConfigAuthGuard } from './steps/profile-config-auth.guard';
import { OnbordingRouteGuard } from './steps/onbording-route.guard';
import { DownloadCertificateComponent } from './steps/download-certificate/download-certificate.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
      path: 'program-config',
      component: ProgramConfigurationComponent,
      canActivate: [ProfileConfigAuthGuard],
      data: { title: extract('program configuration') }
    },

    { path: 'home', component: HomeComponent, data: { title: extract('Home') } },
    {
      path: 'select-citations',
      canActivate: [OnbordingRouteGuard],
      component: ProgramCreationComponent,
      data: { title: extract('Select Citations') }
    },
    {
      path: 'organization-assessment',
      canActivate: [OnbordingRouteGuard],
      component: QuickSurveyComponent,
      data: { title: extract('Organization Assessment') }
    },
    {
      path: 'generate-documents',
      canActivate: [OnbordingRouteGuard],
      component: GenerateDocumentsComponent,
      data: { title: extract('Generate Documents') }
    },
    {
      path: 'download-certificate',
      component: DownloadCertificateComponent
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule {}
